import Vue from 'vue'
import Router from 'vue-router'
import axios from 'axios'
// import VueMeta from 'vue-meta'

import Contacts from '~/pages/contacts'
import Free from '~/pages/free'
import FreeGroup from '~/pages/free-group'
import Index from '~/pages/index'
import Lessons from '~/pages/lessons'
import LessonsDetail from '~/pages/lessons/_detail.vue'
import Magazine from '~/pages/magazine'
import MagazineDetail from '~/pages/magazine/_detail.vue'
import Personal from '~/pages/personal'
import PersonalData from '~/pages/personal-data'
import Pricing from '~/pages/pricing'
import Questions from '~/pages/questions'
import Schedule from '~/pages/schedule'
import Trainers from '~/pages/trainers'
import TrainersDetail from '~/pages/trainers/_trainer'
import Workout from '~/pages/workout/index.vue'
import WorkoutDetail from '~/pages/workout/_detail'
import AttendanceCheck from '~/pages/attendance-check.vue'

import AcademyAcademy from '~/pages/academy_academy.vue'
import AcademyLectures from '~/pages/academy_lectures/index'
import AcademyLecturesDetail from '~/pages/academy_lectures/_lecture'
import AcademyOnline from '~/pages/academy_online/index'
import AcademyOnlineDetail from '~/pages/academy_online/_online'
import AcademyTrainers from '~/pages/academy_trainers/index'
import AcademyTrainerDetail from '~/pages/academy_trainers/_trainer'
import AcademyAbout from '~/pages/academy_about.vue'
import AcademyInHouse from '~/pages/academy_in-house.vue'
import AcademyContact from '~/pages/academy_contact.vue'
import AcademyMagazine from '~/pages/academy_magazine/index'
import AcademyMagazineDetail from '~/pages/academy_magazine/_article'
import AcademyEvents from '~/pages/academy_events.vue'
import AcademyPersonalData from '~/pages/academy_personal-data'
import AcademyBusinessConditions from '~/pages/academy_business-conditions'
import AcademyPayment from '~/pages/academy_payment/index'
import AcademyPaymentSuccess from '~/pages/academy_payment/success'
import AcademyPaymentCancel from '~/pages/academy_payment/cancel'

import AcademyClientProfile from '~/pages/academy_client/academy_client_profile'
import AcademyClientCourses from '~/pages/academy_client/academy_client_courses/index'
import AcademyClientCourseDetail from '~/pages/academy_client/academy_client_courses/_detail'
import AcademyClientOnlineCourses from '~/pages/academy_client/academy_client_online-courses/index'
import AcademyClientOnlineCourseDetail from '~/pages/academy_client/academy_client_online-courses/_detail'
import AcademyClientPayment from '~/pages/academy_client/academy_client_payment/index'
import AcademyClientPaymentSuccess from '~/pages/academy_client/academy_client_payment/success'
import AcademyClientPaymentCancel from '~/pages/academy_client/academy_client_payment/cancel'
import AcademyClientPlayer from '~/pages/academy_client/academy_client_player'

import Template from '~/pages/template'
import AcademyTemplate from '~/pages/academy_template'


// this.$router
Vue.use(Router)
// Vue.use(VueMeta, {
//   refreshOnceOnNavigation: true
// })

export async function createRouter() {
  const { data } = await axios.get(`${process.env.apiUrl}/api/custom/routes`)
  
  // We are aliasing routes based on what's configured in Strapi. This is allows the client to rename routes them selfs
  function getRoute(key) {
    try {
      return '/' + data.find((route) => route.key === key)?.url?.url_alias
    } catch (error) {
      console.log(error)
      return Math.random()
    }
  }

  return new Router({
    scrollBehavior(to, from, savedPosition) {
      const defaultPosition = false
      const scrollTopPosition = { x: 0, y: 0 }
      let position = defaultPosition
      if (savedPosition) {
        position = savedPosition
      }
      else if (to.hash && document.querySelector(to.hash)) {
        position = { selector: to.hash }
      }
      position = scrollTopPosition

      return new Promise((resolve) => {
        resolve(position)
      })
    },
    mode: 'history',
    routes: [
      {
        name: 'index',
        path: '/',
        component: Index
      },
      {
        name: 'login-passwordless',
        path: '/login/passwordless',
        redirect: to => ({
          name: 'index',
          query: { 
            loginToken: to.query.code || null
          }
        }),
      },
      {
        name: 'reset-password',
        path: '/reset-password',
        redirect: to => ({
          name: 'index',
          query: { 
            resetPwdCode: to.query.code
          }
        }),
      },
      {
        name: 'lessons',
        path: getRoute('gym-nase-lekce'),
        component: Lessons,
      },
      {
        name: 'lessons-detail',
        path: getRoute('gym-nase-lekce') + '/:slug',
        component: LessonsDetail,
      },
      {
        name: 'schedule',
        path: getRoute('gym-rozvrh'),
        component: Schedule,
      },
      {
        name: 'pricing',
        path: getRoute('gym-cenik'),
        component: Pricing,
      },
      {
        name: 'trainers',
        path: getRoute('gym-treneri'),
        component: Trainers,
      },
      {
        name: 'trainers-detail',
        path: getRoute('gym-treneri') + '/:slug',
        component: TrainersDetail,
      },
      {
        name: 'workout',
        path: getRoute('gym-workout-videa'),
        component: Workout,
      },
      {
        name: 'workout-detail',
        path: getRoute('gym-workout-videa') + '/:slug',
        component: WorkoutDetail,
      },
      {
        name: 'questions',
        path: getRoute('gym-otazky-a-odpovedi'),
        component: Questions,
      },
      {
        name: 'free',
        path: getRoute('gym-lekce-zdarma'),
        component: Free,
      },
      {
        name: 'free-group',
        path: getRoute('gym-lekce-zdarma-skupina'),
        component: FreeGroup,
      },
      {
        name: 'personal',
        path: getRoute('gym-osobni-trenink'),
        component: Personal,
      },
      {
        name: 'magazine',
        path: getRoute('gym-magazin'),
        component: Magazine,
      },
      {
        name: 'magazine-detail',
        path: getRoute('gym-magazin') + '/:slug',
        component: MagazineDetail,
      },
      {
        name: 'contacts',
        path: getRoute('gym-kontakt'),
        component: Contacts,
      },
      {
        name: 'personal-data',
        path: getRoute('gym-zpracovani-osobnich-udaju'),
        component: PersonalData,
      },
      {
        name: 'attendance-check',
        path: "/attendance-check",
        component: AttendanceCheck,
      },

      // ACADEMY
      {
        name: 'akademie',
        path: getRoute('akademie'),
        component: AcademyAcademy
      },
      {
        name: 'akademie-lectures',
        path: getRoute('akademie-fitness-skoleni'),
        component: AcademyLectures
      },
      {
        name: 'akademie-lectures-detail',
        path: getRoute('akademie-fitness-skoleni') + '/:slug',
        component: AcademyLecturesDetail
      },
      {
        name: 'certifikace-osobni-trener',
        path: getRoute('akademie-fitness-skoleni') + '/certifikace-osobni-trener',
        component: AcademyLecturesDetail
      },
      {
        name: 'certifikovany-kurz-trenera-deti-a-mladeze',
        path: getRoute('akademie-fitness-skoleni') + '/certifikovany-kurz-trenera-deti-a-mladeze',
        component: AcademyLecturesDetail
      },
      {
        name: 'akademie-online',
        path: getRoute('akademie-online-kurz'),
        component: AcademyOnline
      },
      {
        name: 'akademie-online-detail',
        path: getRoute('akademie-online-kurz') + '/:slug',
        component: AcademyOnlineDetail
      },
      {
        name: 'akademie-trainers',
        path: getRoute('akademie-lektori'),
        component: AcademyTrainers
      },
      {
        name: 'akademie-trainers-detail',
        path: getRoute('akademie-lektori') + '/:slug',
        component: AcademyTrainerDetail
      },
      {
        name: 'akademie-about',
        path: getRoute('akademie-o-nas'),
        component: AcademyAbout
      },
      {
        name: 'akademie-in-house-skoleni',
        path: getRoute('akademie-inhouse-skoleni'),
        component: AcademyInHouse
      },
      {
        name: 'akademie-kontakt',
        path: getRoute('akademie-kontakt'),
        component: AcademyContact
      },
      {
        name: 'akademie-magazin',
        path: getRoute('akademie-magazin'),
        component: AcademyMagazine
      },
      {
        name: 'akademie-magazin-detail',
        path: getRoute('akademie-magazin') + '/:slug',
        component: AcademyMagazineDetail
      },
      {
        name: 'akademie-eventy',
        path: getRoute('akademie-event'),
        component: AcademyEvents
      },
      {
        name: 'akademie-personal-data',
        path: getRoute('akademie-zpracovani-osobnich-udaju'),
        component: AcademyPersonalData,
      },
      {
        name: 'akademie-business-conditions',
        path: getRoute('akademie-vseobecne-obchodni-podminky'),
        component: AcademyBusinessConditions,
      },
      {
        name: 'akademie-payment',
        path:  getRoute('akademie-fitness-skoleni') + '/:slug/zapis',
        component: AcademyPayment,
        props: true
      },
      {
        name: 'akademie-payment-success',
        path: '/zapis/success',
        component: AcademyPaymentSuccess,
        props: true
      },
      {
        name: 'akademie-payment-cancel',
        path: '/zapis/cancel',
        component: AcademyPaymentCancel,
      },
      {
        name: 'akademie-muj-profil',
        path: '/akademie-muj-profil',
        component: AcademyClientProfile,
      },
      {
        name: 'akademie-moje-skoleni',
        path: '/akademie-moje-skoleni',
        component: AcademyClientCourses,
      },
      {
        name: 'akademie-moje-skoleni-detail',
        path: '/akademie-moje-skoleni/:slug',
        component: AcademyClientCourseDetail,
        props: true
      },
      {
        name: 'akademie-moje-skoleni-detail-player',
        path: '/akademie-moje-skoleni/:slug/player',
        component: AcademyClientPlayer,
        props: true
      },
      {
        name: 'akademie-moje-skoleni-zapis',
        path: '/akademie-moje-skoleni/:slug/zapis',
        component: AcademyClientPayment,
        props: true
      },
      {
        name: 'akademie-moje-skoleni-zapis-success',
        path: '/zapis/uspesne-zakoupeno',
        component: AcademyClientPaymentSuccess,
        props: true
      },
      {
        name: 'akademie-moje-skoleni-zapis-cancel',
        path: '/zapis/platba-se-nezdarila',
        component: AcademyClientPaymentCancel,
      },
      {
        name: 'akademie-moje-online-kurzy',
        path: '/akademie-moje-online-kurzy',
        component: AcademyClientOnlineCourses,
      },
      {
        name: 'akademie-moje-online-kurzy-detail',
        path: '/akademie-moje-online-kurzy/:slug',
        component: AcademyClientOnlineCourseDetail,
        props: true
      },
      {
        name: 'akademie-moje-online-kurzy-detail-player',
        path: '/akademie-moje-online-kurzy/:slug/player',
        component: AcademyClientPlayer,
        props: true
      },
      {
        name: 'template',
        path: '/pages/:slug',
        component: Template,
      },
      {
        name: 'akademie-template',
        path: '/stranky/:slug',
        component: AcademyTemplate,
      },
    ],
  })
}
