
import { collectionBySiteContextFilter } from '~/helpers/filters'

export default {
  name: 'AcademyTrainersDetail',
  layout: 'academy_default',
  async asyncData({ app, store, route, redirect }) {
    try {
      const { slug } = route.params

      const [trainersPage, trainerBySlug] = await Promise.all([
        app.$api.getAcademyTrainersPage(),
        app.$api.getAcademyTrainerBySlug(slug),
      ])

      // Filter out clankies that don't have current context or are not from current instructor
      const { clankies } = trainerBySlug.data.data[0].attributes
      const clankiesFilter = collectionBySiteContextFilter([store.getters.siteContextId, 3])
      trainerBySlug.data.data[0].attributes.clankies.data = clankies.data.filter(clankiesFilter)

      return {
        trainer: trainerBySlug.data.data[0].attributes,
        courses: trainerBySlug.data.data[0].attributes.akademie_skolenis.data,
        page: trainersPage.data.data.attributes,
        api: process.env.apiUrl
      }
    } catch(e) {
      console.error(e)
      redirect('/')
    }
  },
  head() {
    return this.$seo.generate(
      this.page?.serp,
      this.page?.social_sharing,
      this.trainer?.full_name
    )
  },
  computed: {
    generatedImage() {
      if (!this.trainer?.trainer_photo?.data?.attributes?.url) {
        return ''
      }
      return this.trainer.trainer_photo.data.attributes.url
    },
    sortedArticles() {
      const articles = this.trainer.clankies.data
      return articles.sort(function(a,b) {
        return new Date(b.attributes.publish_date) - new Date(a.attributes.publish_date);
      })
    },
    today () {
      const today = new Date()
      let day = today.getDate()
      day = day.toString()
      if (day.length < 2) {
        day = '0' + day
      }
      let month = today.getMonth() + 1
      month = month.toString()
      if (month.length < 2) {
        month = '0' + month
      }
      return today.getFullYear() + '-' + month + '-' + day
    },
    cardInstances() {
      const cards = []
      this.courses.forEach((course) => {
        if (course.attributes.dates) {
          course.attributes.dates.date.forEach((d) => {
          cards.push({course, date: d})
          })
        } else {
          return null
        }

      })
      return cards
    },
    filteredCourses() {
      let sortedCards = [...this.cardInstances]
      sortedCards = sortedCards.filter(date => date.date.date > this.today)
      sortedCards = sortedCards.sort(function (a, b) {
        return (Date.parse(a.date.date) > Date.parse(b.date.date)) ? 1 : (Date.parse(a.date.date) < Date.parse(b.date.date)) ? -1 : 0;
      })
      return sortedCards
    }
  },
}
