
import { format } from 'date-fns'

export default {
  name: 'CardArticle',
  props: {
    title: {
      type: String,
      required: true,
    },
    image: {
      type: [String, Object],
      required: true,
    },
    date: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    link: {
      type: [Object, String],
      required: true,
    },
  },
  computed: {
    dateFormatted() {
      if (!this.date) return ''
      try {
        return format(new Date(this.date), 'd. M. y')
      } catch {
        return this.date | ''
      }
    },
    src() {
      if (!this.image || !this.image.attributes) return ''
      return this.image.attributes.url
    },
    linkUrl() {
      return this.link || '/'
    },
  },
}
