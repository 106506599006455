// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/images/backgrounds/bgr-texture.jpg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".academy-header-contacts, .header-contacts{position:relative;background-size:cover;background-position:center;padding-top:5rem;}.academy-header-contacts,.header-contacts{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");}.academy-header-contacts__inner, .header-contacts__inner{display:flex;align-items:flex-start;background-size:cover;background-position:center;padding-top:5rem;padding-bottom:5rem;}.academy-header-contacts__scroll,.header-contacts__scroll{-webkit-animation:bounce .9s linear infinite alternate;animation:bounce .9s linear infinite alternate}.academy-header-contacts__scroll, .header-contacts__scroll{position:absolute;bottom:2.25rem;left:50%;--tw-translate-x:-50%;transform:translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));}@media screen and (max-width:768px){.academy-header-contacts__inner,.header-contacts__inner{min-height:calc(50vh - 5rem)}.academy-header-contacts__inner, .header-contacts__inner{padding-top:2.5rem;padding-bottom:10rem;}}@-webkit-keyframes bounce{0%{transform:translateY(0)}20%{transform:translateY(0)}to{transform:translateY(15px) scale(.9)}}@keyframes bounce{0%{transform:translateY(0)}20%{transform:translateY(0)}to{transform:translateY(15px) scale(.9)}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
