
export default {
  name: 'NavClient',
  props: {
    logo: {
      type: Array,
      required: true
    },
    items: {
      type: Array,
      required: true,
    },
    link: {
      type: Array,
      required: true,
    }
  },
  methods: {
    handleLogout() {
      this.$store.dispatch('user/logout')
      this.$router.push('/')
    }
  }
}
