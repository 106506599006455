export const state = () => ({
  pastVisible: false,
})

export const mutations = {
  switch(state) {
    state.pastVisible = !state.pastVisible
  },
  setVisible(state, visible) {
    state.pastVisible = visible
  }
}

export const actions = {
  switchToggle({ commit }) {
    commit('setVisible', false)
  }
}