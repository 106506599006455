var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"academy-card-testimonial px-4",class:{ full: !_vm.generatedLogo }},[_c('div',{staticClass:"academy-card-testimonial__inner flex flex-col justify-center items-center"},[(_vm.generatedLogo)?_c('div',{staticClass:"academy-card-testimonial__image mb-5"},[_c('picture',[_c('source',{attrs:{"media":"(min-width: 769px)","srcset":`
          ${'/_ipx/f_jpeg,h_64/strapi' + _vm.generatedLogo} 1x,
          ${'/_ipx/f_jpeg,h_128/strapi' + _vm.generatedLogo} 1.5x
          `}}),_vm._v(" "),_c('source',{attrs:{"media":"(max-width: 768px)","srcset":`
          ${'/_ipx/f_jpeg,h_64/strapi' + _vm.generatedLogo} 1x,
          ${'/_ipx/f_jpeg,h_128/strapi' + _vm.generatedLogo} 1.5x
          `}}),_vm._v(" "),_c('img',{attrs:{"src":'/_ipx/f_jpeg,h_64/strapi' + _vm.generatedLogo,"alt":""}})])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"academy-card-testimonial__header flex items-center justify-center md:justify-start my-5 md:my-10"},[_c('div',{staticClass:"constructor-title mb-5"},[_vm._v(_vm._s(_vm.name))])]),_vm._v(" "),_c('div',{staticClass:"mt-5 mb-9 academy-card-testimonial__text flex flex-col items-start lg:grid lg:grid-cols-3"},[_c('div',{staticClass:"academy-card-testimonial__lectures mb-10 lg:mb-0"},[_c('p',{staticClass:"subtitle-bold uppercase mb-3 md:mb-5"},[_vm._v("Rozsah školení")]),_vm._v(" "),_vm._l((_vm.courses),function(course){return _c('ul',{key:course.id,staticClass:"ml-6"},[_c('nuxt-link',{staticClass:"hover:underline",attrs:{"to":{
            name: 'akademie-lectures-detail',
            params: {
              slug: course.attributes.url_alias,
            }
          }}},[_c('li',{staticClass:"mb-2"},[_vm._v(_vm._s(course.attributes.course_name))])])],1)})],2),_vm._v(" "),_c('div',{staticClass:"academy-card-testimonial__trainers mb-10 lg:mb-0"},[_c('p',{staticClass:"subtitle-bold uppercase mb-3 md:mb-5"},[_vm._v("Školitelé")]),_vm._v(" "),_vm._l((_vm.trainers),function(trainer){return _c('ul',{key:trainer.id,staticClass:"ml-6"},[_c('nuxt-link',{staticClass:"hover:underline",attrs:{"to":{
            name: 'akademie-trainers-detail',
            params: {
              slug: trainer.attributes.url_alias,
            },
          }}},[_c('li',{staticClass:"mb-2"},[_vm._v(_vm._s(trainer.attributes.full_name))])])],1)})],2),_vm._v(" "),_c('div',{staticClass:"academy-card-testimonial__description mb-10 lg:mb-0"},[_c('p',{staticClass:"subtitle-bold uppercase mb-3 md:mb-5"},[_vm._v("Doporučení")]),_vm._v(" "),_c('p',[_vm._v(_vm._s(_vm.description))])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }