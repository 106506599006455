
export default {
  name: 'Contacts',
  async asyncData({ app, redirect }) {
    try {
      const { data } = await app.$api.getContactsPage()
      return {
        page: data.data.attributes,
      }
    } catch {
      redirect('/')
    }
  },
  head() {
    return this.$seo.generate(
      this.page?.serp,
      this.page?.social_sharing,
      this.page?.page_title_h1
    )
  },
}
