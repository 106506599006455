import qs from 'qs'

export const getWorkoutPage = (axios) => {
  const populate = [
    'serp',
    'social_sharing',
    'social_sharing.social_image',
    'workout_points',
    'workout_points.icon',
    'cover_image',
  ]
  const query = `
    populate=${populate.join(',')}
    `
  return axios.get(`/api/gym-workout-videa?${query.trim()}`)
}

export const getWorkoutVideos = (axios) => {
  const query = qs.stringify(
    {
      filters: {
        show_on_web: {
          $eq: true,
        },
      },
      sort: ['sort_index:asc'],
      populate: ['cover_image'],
    },
    { encodeValuesOnly: true }
  )
  return axios.get(`/api/workouts?${query}`)
}

export const getWorkoutVideoBySlug = (axios, slug) => {
  const query = qs.stringify(
    {
      populate: [
        'layout',
        'layout.image',
        'layout.image_right',
        'layout.image_left',
        'cover_image',
        'layout.file',
        'layout.numbered_blocks',
        'layout.numbered_blocks.image',
        'layout.numbered_blocks.video',
        'layout.images',
        'trainers',
        'trainers.trainer_photo',
        'trainers.photo_for_video',
        'trainers.trainers_points',
        'related_videos',
        'related_videos.cover_image',
      ],
      filters: {
        url_alias: {
          $eq: slug,
        },
      },
    },
    {
      encodeValuesOnly: true,
    }
  )
  return axios.get(`/api/workouts?${query}`)
}
