import qs from 'qs'

export const getAcademyTemplateBySlug = (axios, slug) => {
  const query = qs.stringify(
    {
      populate: [
        'serp',
        'social_sharing',
        'social_sharing.social_image',
        'cover_image',
        'layout.file',
        'layout.numbered_blocks',
        'layout.numbered_blocks.image',
        'layout.numbered_blocks.video',
        'layout.image',
        'layout.image_right',
        'layout.image_left',
        'layout.images',
        'layout.video',
        'layout.embed_vod',
        'layout.timetable',
        'layout.image_on_right',
        'types'
      ],
      filters: {
        $and: [
          {
            url_alias: {
              $eq: slug,
            },
          },
          {
            types: {
              filter_name: {
                $eq: 'Akademie'
              }
            }
          }
        ]
      },
    },
    {
      encodeValuesOnly: true,
    }
  )
  return axios.get(`/api/strankies/?${query}`)
}