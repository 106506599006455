
import gsap from 'gsap'
// import { gsap } from 'gsap/dist/gsap'
// import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
import { defineComponent, ref, computed } from '@nuxtjs/composition-api'

// gsap.registerPlugin(ScrollTrigger)

export default defineComponent({
  name: 'BlockImage',
  props: {
    content: {
      type: Object,
      default: () => ({}),
    },
  },
  setup({ content }) {
    const api = ref(`${process.env.apiUrl}`)
    const image = computed(() => {
      return `${content?.image?.data?.attributes?.url}`
    })
    return { api, image }
  },
  mounted() {
    if (this.$route.name !== 'personal') {
      this.animateOnScroll()
    } this.animateOnScroll2()
  },
  beforeDestroy() {
    if (this.tlBlack) this.tlBlack.kill()
    if (this.tlYellow) this.tlYellow.kill()
    this.tlBlack = null
    this.tlYellow = null
  },
  methods: {
    animateOnScroll() {
      this.tlBlack = gsap.timeline({
        scrollTrigger: {
          trigger: '.block-image',
          start: '-30%',
          end: '60%',
          onEnter: () => this.tlBlack.play(),
          onLeave: () => this.tlBlack.reverse().delay(0.2),
          onEnterBack: () => this.tlBlack.play().delay(0.2),
          onLeaveBack: () => this.tlBlack.reverse().delay(0.2)
        }
      })
      this.tlYellow = gsap.timeline({
        scrollTrigger: {
          trigger: '.block-image',
          start: '-30%',
          end: '60%',
          toggleActions: 'play reverse play reverse'
        }
      })

      this.tlYellow.to('.block-image__image--before', {
        width: '0%',
        duration: 1,
        ease: 'expo.inOut',
      })

      this.tlBlack.to('.block-image__image--after', {
        width: '0%',
        duration: 0.8,
        ease: 'expo.inOut',
        delay: 0.2,
      })
    },
    animateOnScroll2() {
      this.tlBlack = gsap.timeline({
        scrollTrigger: {
          trigger: '.block-image',
          start: '-30%',
        }
      })
      this.tlYellow = gsap.timeline({
        scrollTrigger: {
          trigger: '.block-image',
          start: '-30%',
        }
      })

      this.tlYellow.to('.block-image__image--before', {
        width: '0%',
        duration: 1,
        ease: 'expo.inOut',
      })

      this.tlBlack.to('.block-image__image--after', {
        width: '0%',
        duration: 0.8,
        ease: 'expo.inOut',
        delay: 0.2,
      })
    }
  },
})
