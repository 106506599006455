import { render, staticRenderFns } from "./schedule.vue?vue&type=template&id=22698860&"
import script from "./schedule.vue?vue&type=script&lang=js&"
export * from "./schedule.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SectionSchedule: require('/home/runner/work/3df/3df/components/Section/SectionSchedule/SectionSchedule.vue').default,BlockImage: require('/home/runner/work/3df/3df/components/Block/BlockImage/BlockImage.vue').default,Banner: require('/home/runner/work/3df/3df/components/Banner/Banner.vue').default,SectionRegister: require('/home/runner/work/3df/3df/components/Section/SectionRegister/SectionRegister.vue').default})
