
import emailValidator from "email-validator"
import context from '~/mixins/context'

export default {
  name: 'ModalRegister',
  mixins: [context],
  data: () => ({
    state: 'register',
    isVisible: false,
    isLoading: false,
    doesUserExist: false,
    hasRegistrationFailed: false,
    firstname: '',
    email: '',
    gdpr: false,
    errors: {},
  }),
  methods: {
    login() {
      this.$vfm.hide('register')
      this.$vfm.show('login')
    },
    validate() {
      this.errors = {
        firstname: this.firstname === '',
        email: !emailValidator.validate(this.email),
        gdpr: !this.gdpr,
      }

      return !Object.values(this.errors).some(v => !!v)
    },
    async handleRegister() {
      this.doesUserExist = false
      this.hasRegistrationFailed = false

      if (!this.validate()) {
        return
      }

      this.isLoading = true

      try {
        const found = await this.$store.dispatch('user/findUser', this.email)
        if (!found) {
          const res = await this.$store.dispatch('user/register', {
            email: this.email,
            username: this.email,
            context: {
              firstname: this.firstname
            }
          })

          if (res && res.email) {
            this.state = 'success'    
          }
          else {
            this.hasRegistrationFailed = true
          }
        }
        else { // if (found == true)
          this.doesUserExist = true
        }        
      }
      catch(e) {
        this.hasRegistrationFailed = true
      }

      this.isLoading = false
    },
    closeMenuAndModal() {
      this.$vfm.hide('register')
      this.$store.dispatch('menu/closeMenu')

      if (this.$store.getters.isAcademy) {
        this.$router.push({ name: 'akademie-moje-skoleni'})
      }
    },
  }
}
