
import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  name: 'ConstructorLayout',
  props: {
    layout: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const getComponent = (name) => {
      switch (name) {
        case 'layout.text-block':
          return 'ConstructorText'
        case 'layout.2-column-text':
          return 'ConstructorDoubleText'
        case 'layout.title':
          return 'ConstructorTitle'
        case 'layout.image':
          return 'ConstructorImage'
        case 'layout.button':
          return 'ConstructorButton'
        case 'layout.call-out':
          return 'ConstructorCallout'
        case 'layout.divider':
          return 'ConstructorDivider'
        case 'layout.embed':
          return 'ConstructorEmbed'
        case 'layout.embed-socials':
          return 'ConstructorEmbedSocials'
        case 'layout.file-download':
          return 'ConstructorFile'
        case 'layout.numbered-block':
          return 'ConstructorNumbered'
        case 'layout.photo-gallery':
          return 'ConstructorGallery'
        case 'layout.quote':
          return 'ConstructorBlockquote'
        case 'layout.promo-certified-course':
          return 'ConstructorPromo'
        case 'layout.schedule':
          return 'ConstructorSchedule'
        case 'layout.video':
          return 'ConstructorVideo'
        default:
          return 'Banner'
      }
    }
    return {
      getComponent,
    }
  },
})
