import { render, staticRenderFns } from "./academy_contact.vue?vue&type=template&id=56f35a28&"
import script from "./academy_contact.vue?vue&type=script&lang=js&"
export * from "./academy_contact.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BlockContent: require('/home/runner/work/3df/3df/components/Block/BlockContent/BlockContent.vue').default,BannerWhite: require('/home/runner/work/3df/3df/components/Banner/BannerWhite/BannerWhite.vue').default,Gallery: require('/home/runner/work/3df/3df/components/Gallery/Gallery.vue').default,AcademySectionRegister: require('/home/runner/work/3df/3df/components/AcademySectionRegister/AcademySectionRegister.vue').default})
