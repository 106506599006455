import qs from 'qs'

export const getTrainersPage = (axios) => {
  const populate = [
    'serp',
    'social_sharing',
    'social_sharing.social_image',
    'cover_image',
  ]
  const query = `
    populate=${populate.join(',')}
    `
  return axios.get(`/api/gym-treneri?${query.trim()}`)
}

export const getTrainers = (axios) => {
  const query = qs.stringify(
    {
      filters: {
        related_type: {
          id: {
            $eq: '1', // related to GYM
          },
        },
        show_on_web: {
          $eq: true,
        },
      },
      populate: ['photo_for_video', 'trainer_video', 'trainer_photo'],
    },
    {
      encodeValuesOnly: true,
    }
  )
  return axios.get(`/api/profily-trenerus?${query}`)
}

export const getTrainerBySlug = (axios, slug) => {
  const query = qs.stringify(
    {
      populate: [
        'trainer_photo',
        'trainers_points',
        'serp',
        'social_sharing',
        'social_sharing.social_image',
        'gym_lekces',
        'gym_lekces.cover_image',
        'clankies',
        'clankies.types',
        'clankies.cover_image',
      ],
      filters: {
        url_alias: {
          $eq: slug,
        },
      },
    },
    {
      encodeValuesOnly: true,
    }
  )
  return axios.get(`/api/profily-trenerus?${query}`)
}
