import { render, staticRenderFns } from "./_detail.vue?vue&type=template&id=67d0cacf&"
import script from "./_detail.vue?vue&type=script&lang=js&"
export * from "./_detail.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ConstructorLayout: require('/home/runner/work/3df/3df/components/Constructor/ConstructorLayout.vue').default,CardProfile: require('/home/runner/work/3df/3df/components/Card/CardProfile/CardProfile.vue').default,ConstructorTrainerDetails: require('/home/runner/work/3df/3df/components/Constructor/ConstructorTrainerDetails.vue').default,CardSmall: require('/home/runner/work/3df/3df/components/Card/CardSmall/CardSmall.vue').default,Banner: require('/home/runner/work/3df/3df/components/Banner/Banner.vue').default,SectionRegister: require('/home/runner/work/3df/3df/components/Section/SectionRegister/SectionRegister.vue').default})
