
import CollapseTransition from '@ivanv/vue-collapse-transition/src/CollapseTransition.vue'
import { defineComponent } from '@nuxtjs/composition-api'
export default defineComponent({
  name: 'CardCollapse',
  components: {
    CollapseTransition,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    body: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    isOpen: false,
  }),
  methods: {
    toggle() {
      this.isOpen = !this.isOpen
    },
  },
})
