import Vue from 'vue'
// Quick and dirty way to get the site settings
export default ({ store }) => {
  Vue.prototype.$s = (key) => {
    if (key && store.state.siteSettings) {
      return store.state.siteSettings[key]
    }
    
    return ''
  }
}
