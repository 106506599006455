import qs from 'qs'
export const getTestimonials = (axios) => {
  const query = qs.stringify(
    {
      filters: {
        types: {
          id: {
            $eq: '1', // related to GYM
          },
        },
        show_on_web: {
          $eq: true,
        },
      },
      pagination: {
        page: 1,
        pageSize: 9,
      },
      populate: ['photo'],
    },
    {
      encodeValuesOnly: true,
    }
  )
  return axios.get(`/api/references?${query}`)
}
