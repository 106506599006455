import qs from 'qs'
export const getAcademyMagazine = (axios) => {
  const query = qs.stringify({
    populate: [
      'serp',
      'social_sharing',
      'popular_articles',
      'popular_articles.cover_image',
      'promo_seal'
    ],
  })
  return axios.get(`/api/akademie-magazin?${query}`)
}
