
import gsap from 'gsap'

export default {
  name: 'AcademyOnline',
  layout: 'academy_default',
  async asyncData({ app, redirect }) {
    try {
      const { data } = await app.$api.getAcademyOnlineCoursesPage()
      const onlineCourses = await app.$api.getAcademyOnlineCourses()

      return {
        page: data.data.attributes,
        api: process.env.apiUrl,
        onlineCourses: onlineCourses.data.data
      }
    } catch {
      redirect('/')
    }
  },
  data()
      {
      return {
        cardCols: 4
      }
  },
  head() {
    return this.$seo.generate(
      this.page?.serp,
      this.page?.social_sharing,
      this.page?.page_title_h1
    )
  },
  computed: {
    coverImg() {
      if (!this.page?.cover_image?.data) {
        return ''
      }
      return this.page.cover_image.data.attributes.url
    },
    isMobile() {
      return process.client ? window.innerWidth < 768 : true
    },
  }
}
