import { render, staticRenderFns } from "./_trainer.vue?vue&type=template&id=0e036067&"
import script from "./_trainer.vue?vue&type=script&lang=js&"
export * from "./_trainer.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CardSmall: require('/home/runner/work/3df/3df/components/Card/CardSmall/CardSmall.vue').default,CardArticle: require('/home/runner/work/3df/3df/components/Card/CardArticle/CardArticle.vue').default,Banner: require('/home/runner/work/3df/3df/components/Banner/Banner.vue').default,SectionRegister: require('/home/runner/work/3df/3df/components/Section/SectionRegister/SectionRegister.vue').default})
