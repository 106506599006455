import { getHomepage } from '~/services/api/homepage'
import {
  getGroupLessons,
  getLessonBySlug,
  getLessonsPage,
  getLessonsPageLessons,
  getMostPopularArticles,
} from '~/services/api/lessons'
import { getSchedulePage } from '~/services/api/schedule'
import {
  getTrainerBySlug,
  getTrainers,
  getTrainersPage,
} from '~/services/api/trainers'
import { getPricingPage } from '~/services/api/pricing'
import {
  getWorkoutPage,
  getWorkoutVideos,
  getWorkoutVideoBySlug,
} from '~/services/api/workout'
import { getQuestionsPage } from '~/services/api/questions'

import { getMagazinePage } from '~/services/api/magazine'
import {
  getArticlesByPage,
  getNewestArticles,
  getArticleBySlug,
} from '~/services/api/articles'

import { getPersonalTrainingPage } from '~/services/api/personal'
import { getFreeLecturePage } from '~/services/api/free'

import { getTestimonials } from '~/services/api/testimonials'

import { getContactsPage } from '~/services/api/contacts'
import { 
  getPersonalDataAgreement,
  getAcademyPersonalDataAgreement,
  getAcademyBusinessConditions
} from '~/services/api/general'
import {
  findMe,
  findUser,
  updateMe,
  login,
  loginWithToken,
  register,
  setPassword,
  forgotPassword,
  resetPassword,
  userHasContext,
} from '~/services/api/auth'

import { getAcademyHomepage } from '~/services/api/academy_homepage'
import { getAcademyAcademy } from '~/services/api/academy_academy'

import {
  getAcademyLecturesPage,
  getAcademyLectures,
  getAcademyLectureBySlug,
  getAcademyCourseTypes
} from '~/services/api/academy_lectures'

import {
  getAcademyOnlineCoursesPage,
  getAcademyOnlineCourses,
  getAcademyOnlineCourseBySlug
} from '~/services/api/academy_online'
import {
  getAcademyTrainerBySlug,
  getAcademyTrainers,
  getAcademyTrainersPage,
} from '~/services/api/academy_trainers'
import { getAcademyAbout } from '~/services/api/academy_about'
import { getAcademyInHouse } from '~/services/api/academy_in-house'
import { getAcademyContact } from '~/services/api/academy_contact'
import { getAcademyMagazine } from '~/services/api/academy_magazine'
import {
  getAcademyArticlesByPage,
  getAcademyNewestArticles,
  getAcademyArticleBySlug,
} from '~/services/api/academy_articles'
import { getAcademyEventsPage, getAcademyEvents } from '~/services/api/academy_events'
import { getAcademyTestimonials } from '~/services/api/academy_testimonials'

import { getTemplateBySlug } from '~/services/api/templates'
import { getAcademyTemplateBySlug } from '~/services/api/academy_templates'

const apiFactory = (axios, store) => ({
  getHomepage: () => getHomepage(axios),

  getLessonsPage: () => getLessonsPage(axios),
  getLessonsPageLessons: () => getLessonsPageLessons(axios),
  getLessonBySlug: (slug) => getLessonBySlug(axios, slug),
  getGroupLessons: (slug) => getGroupLessons(axios, slug),
  getMostPopularArticles: (slug) => getMostPopularArticles(axios, slug),

  getSchedulePage: () => getSchedulePage(axios),

  getTrainersPage: () => getTrainersPage(axios),
  getTrainers: () => getTrainers(axios),
  getTrainerBySlug: (slug) => getTrainerBySlug(axios, slug),

  getPricingPage: () => getPricingPage(axios),
  getWorkoutPage: () => getWorkoutPage(axios),
  getWorkoutVideos: () => getWorkoutVideos(axios),
  getWorkoutVideoBySlug: (slug) => getWorkoutVideoBySlug(axios, slug),

  getQuestionsPage: () => getQuestionsPage(axios),
  getTestimonials: () => getTestimonials(axios),

  getNewestArticles: () => getNewestArticles(axios),
  getArticlesByPage: (page) => getArticlesByPage(axios, page),
  getArticleBySlug: (slug) => getArticleBySlug(axios, slug),
  getMagazinePage: () => getMagazinePage(axios),

  getContactsPage: () => getContactsPage(axios),
  getPersonalDataAgreement: () => getPersonalDataAgreement(axios),
  register: (data, userType) => register(axios, data, userType),
  login: (data) => login(axios, data),
  loginWithToken: (token) => loginWithToken(axios, token),
  setPassword: (data) => setPassword(axios, data, store),
  forgotPassword: (options) => forgotPassword(axios, options),
  resetPassword: (options) => resetPassword(axios, options),
  userHasContext: (options) => userHasContext(axios, options),
  findMe: (token, populate) => findMe(axios, token, populate),
  findUser: (email) => findUser(axios, email),
  updateMe: (token, data) => updateMe(axios, token, data),

  getPersonalTrainingPage: () => getPersonalTrainingPage(axios),
  getFreeLecturePage: () => getFreeLecturePage(axios),


  getAcademyHomepage: () => getAcademyHomepage(axios),
  getAcademyAcademy: () => getAcademyAcademy(axios),

  getAcademyLecturesPage: () => getAcademyLecturesPage(axios),
  getAcademyLectures: () => getAcademyLectures(axios),
  getAcademyLectureBySlug: (slug) => getAcademyLectureBySlug(axios, slug),


  getAcademyOnlineCoursesPage: () => getAcademyOnlineCoursesPage(axios),
  getAcademyOnlineCourses: () => getAcademyOnlineCourses(axios),
  getAcademyOnlineCourseBySlug: (slug) => getAcademyOnlineCourseBySlug(axios, slug),

  getAcademyTrainersPage: () => getAcademyTrainersPage(axios),
  getAcademyTrainers: () => getAcademyTrainers(axios),
  getAcademyTrainerBySlug: (slug) => getAcademyTrainerBySlug(axios, slug),

  getAcademyAbout: () => getAcademyAbout(axios),
  getAcademyInHouse: () => getAcademyInHouse(axios),
  getAcademyContact: () => getAcademyContact(axios),

  getAcademyMagazine: () => getAcademyMagazine(axios),
  getAcademyNewestArticles: () => getAcademyNewestArticles(axios),
  getAcademyArticlesByPage: (page) => getAcademyArticlesByPage(axios, page),
  getAcademyArticleBySlug: (slug) => getAcademyArticleBySlug(axios, slug),

  getAcademyEventsPage: () => getAcademyEventsPage(axios),
  getAcademyEvents: () => getAcademyEvents(axios),
  getAcademyTestimonials: () => getAcademyTestimonials(axios),
  getAcademyPersonalDataAgreement: () => getAcademyPersonalDataAgreement(axios),
  getAcademyBusinessConditions: () => getAcademyBusinessConditions(axios),
  getAcademyCourseTypes: () => getAcademyCourseTypes(axios),

  getTemplateBySlug: (slug) => getTemplateBySlug(axios, slug),
  getAcademyTemplateBySlug: (slug) => getAcademyTemplateBySlug(axios, slug)
})

export default ({ $axios, store }, inject) => {
  const api = apiFactory($axios, store)
  inject('api', api)
}
