export const state = () => ({
  newArticles: null,
})

export const mutations = {
  setNewArticles(state, payload) {
    state.newArticles = payload
  },
}

export const actions = {
  async fetchNewArticles({ commit }) {
    const { data } = await this.$api.getNewestArticles()
    commit('setNewArticles', data.data)
  },
  async getNewArticles({ state, dispatch }) {
    if (!state.newArticles) {
      await dispatch('fetchNewArticles')
    }

    return state.newArticles
  },
}
