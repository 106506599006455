
import modal from '~/mixins/modal'

export default {
  name: 'Workout',
  mixins: [modal], // openLoginModal, openRegisterModal
  async asyncData({ app, redirect }) {
    try {
      const { data } = await app.$api.getWorkoutPage()

      return {
        page: data.data.attributes,
        api: process.env.apiUrl,
        workouts: [],
      }
    } catch {
      redirect('/')
    }
  },
  async fetch() {
    try {
      const { data } = await this.$api.getWorkoutVideos()
      this.workouts = data.data
    } catch {
      this.workouts = []
    }
  },
  head() {
    return this.$seo.generate(
      this.page?.serp,
      this.page?.social_sharing,
      this.page?.page_title_h1
    )
  },
  computed: {
    coverImg() {
      if (!this.page?.cover_image?.data) {
        return ''
      }
      return this.page.cover_image.data.attributes.url
    },
  }
}
