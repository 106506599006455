
import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  name: 'SectionDetailBought',
  props: {
    date: {
      type: String,
      default: null
    },
    location: {
      type: String,
      default: null
    },
    invoice: {
      type: String,
      default: null
    },
    manual: {
      type: Object,
      default: null
    },
    online: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
       api: process.env.apiUrl,
    }
  },
  methods: {
    formatDate(date) {
      const options = { year: 'numeric', month: '2-digit', day: '2-digit' }
      return new Date(date).toLocaleDateString('cs-CS', options)
    },
    formatPrice(price) {
      return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
    }
  }
})
