export const state = () => ({
  user: null,
  error: {
    message: "",
    statusCode: 400,
  }
})

export const mutations = {
  SET_USER(state, payload) {
    state.user = payload
  },
  SET_ERROR(state, { message, statusCode }) {
    state.error.message = message
    state.error.statusCode = statusCode
  }
}

export const getters = {
  initialRegistration(state) {
    return !!state.user?.token
  },
  isLoggedIn(state) {
    return !!state.user
  }
}

export const actions = {
  async register({ dispatch, rootState }, payload) {
    try {
      const userType = rootState.siteContext === 'akademie' ? 2 : 1 // Strapi 'Type' collection
      const { data } = await this.$api.register(payload, userType)
      dispatch('passwordlessLogin', { token: data.token })
      
      return data
    } catch (error) {
      return {
        message: error?.response?.data?.error?.message,
        status: error?.response?.data?.error?.status,
      }
    }
  },
  /**
   * 
   * @param {Context} param0 Nuxt context
   * @param {Options} param1 { Email & Password }
   * @returns True or False based on login succession
   */
  async login({ commit, rootState }, { email, password }) {
    try {
      const [loginRes, contextRes] = await Promise.all([
        this.$api.login({ email, password }),
        this.$api.userHasContext({ identifier: email, context: rootState.siteContext === 'akademie' ? 2 : 1 })
      ])

      if (!contextRes.data.context) {
        throw new Error('Login failed. User does not have corresponding site context.')
      }

      commit('SET_USER', loginRes.data)

      const jwt = loginRes.data.jwt
      this.$cookies.set('token', jwt, {
        maxAge: 60 * 60 * 24 * 30,
        path: '/',
      })

      return true
    } catch (error) {
      console.error(error)

      commit('SET_ERROR', {
        message: error?.response?.data?.error?.message || 'Unknown error',
        statusCode: error?.response?.data?.error?.status || '500',
      })

      return false
    }
  },
  async passwordlessLogin({ commit }, { token }) {
    try {
      const { data } = await this.$api.loginWithToken(token)
  
      commit('SET_USER', data)

      console.log('passwordlessLogin', data)
      
      this.$cookies.set('token', data.jwt, {
        maxAge: 60 * 60 * 24 * 30,
        path: '/',
      })

      return true
    } catch (error) {
      console.error(error)

      commit('SET_ERROR', {
        message: error?.response?.data?.error?.message || 'Unknown error',
        statusCode: error?.response?.data?.error?.status || '500',
      })

      return false
    }
  },
  logout({ commit }) {
    this.$cookies.remove('token', { path: '/' })
    commit('SET_USER', null)
  },
  async updateMe({ commit }, payload) {
    try {
      const token = this.$cookies.get('token', { path: '/' })
      const { data: user } = await this.$api.updateMe(token, payload)

      const data = {
        user,
        jwt: this.$cookies.get('token', { path: '/' })
      }

      commit('SET_USER', data)
      
      return data
    } catch (error) {
      console.error(error)
      
      return {
        message: error?.response?.data?.error?.message,
        status: error?.response?.data?.error?.status,
      }
    }
  },
  async findMe(_, populate = undefined) {
    const token = this.$cookies.get('token', { path: '/' })
    const { data } = await this.$api.findMe(token, populate)

    return data
  },
  findUser({ error }, email) {
    return this.$api.findUser(email)
                    .then(({ data }) => data.length && data[0])
                    .catch(error)
  },
  async setPassword(_, password) {
    return await this.$api.setPassword({
      password,
      token: this.$cookies.get('token', { path: '/' }),
    })
  },
  async resetPassword(_, { code, password, passwordConfirmation }) {
    return await this.$api.resetPassword({
      code, 
      password, 
      passwordConfirmation,
    })
  },
  async forgotPassword(_, { email }) {
    return await this.$api.forgotPassword({ email })
  },
  async restoreSession({ commit, dispatch }) {
    const token = this.$cookies.get('token', { path: '/' })

    if (token) {
      try {
        const user = await dispatch('findMe')
        commit('SET_USER', { user, jwt: token })
      }
      catch(e) {
        // Token's invalid, remove from cookies
        this.$cookies.remove('token', { path: '/' })
      }
    }
  },
}
