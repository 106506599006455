import { render, staticRenderFns } from "./SectionSchedule.vue?vue&type=template&id=6dd1b470&"
import script from "./SectionSchedule.vue?vue&type=script&lang=js&"
export * from "./SectionSchedule.vue?vue&type=script&lang=js&"
import style0 from "./SectionSchedule.vue?vue&type=style&index=0&id=6dd1b470&prod&lang=postcss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CardSchedule: require('/home/runner/work/3df/3df/components/Card/CardSchedule/CardSchedule.vue').default})
