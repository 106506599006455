
import emailValidator from 'email-validator'
import context from '~/mixins/context'

export default {
  name: 'ModalLogin',
  mixins: [context],
  data() {
    return {
      isVisible: false,
      isLoading: false,
      hasLoginFailed: false,
      shouldRedirect: this.$store.getters.isAcademy,
      email: '',
      password: '',
      errors: {},
    }
  },
  methods: {
    beforeOpen(event) {
      this.shouldRedirect = event.ref.params.redirect ?? true
    },
    forgot() {
      this.$vfm.hide('login')
      this.$vfm.show('forgot')
    },
    register() {
      this.$vfm.hide('login')
      this.$vfm.show('register')
    },
    validate() {
      this.errors = {
        email: !emailValidator.validate(this.email),
        password: this.password === '',
      }

      return !Object.values(this.errors).some(v => !!v)
    },
    handleLogin() {
      if (!this.validate()) {
        return
      }

      this.isLoading = true
      
      this.$store.dispatch('user/login', {
        email: this.email.toLowerCase(),
        password: this.password,
      })
      .then((success) => {
        if (success) {
          this.$vfm.hide('login')

          if (this.shouldRedirect) {
            this.$router.push({ name: 'akademie-moje-skoleni'})
          }
        }
        else {
          this.hasLoginFailed = true
        }
      })
      .catch(() => { this.hasLoginFailed = true })
      .finally(() => { 
        this.isLoading = false
      })
    }
  }
}
