import qs from 'qs'

export const getAcademyLecturesPage = (axios) => {
  const query = qs.stringify(
    {
      populate: [
        'cover_image',
        'serp',
        'yellow_block',
        'yellow_block.image',
        'promo_course',
        'promo_course.image_top',
        'promo_course.image_middle',
        'promo_course.image_bottom',
        'promo_seal',
        'dates',
        'dates.date',
      ],
    },
    {
      encodeValuesOnly: true,
    }
  )

  return axios.get(`/api/akademie-fitness-skoleni?${query.trim()}`)
}

export const getAcademyLectures = (axios) => {
  const query = qs.stringify(
    {
      filters: {
        show_on_web: {
          $eq: true,
        },
      },
      pagination: {
        page: 1,
        pageSize: 48,
      },
      populate: [
        'cover_image',
        'dates',
        'dates.date',
        'course_types',
        'souvisejici_skolenis',
      ],
    },
    {
      encodeValuesOnly: true,
    }
  )
  return axios.get(`/api/akademie-skolenis?${query}`)
}

export const getAcademyLectureBySlug = (axios, slug) => {
  const query = qs.stringify(
    {
      pagination: {
        page: 1,
        pageSize: 48,
      },
      populate: [
        'cover_image',
        'serp',
        'social_sharing',
        'dates',
        'dates.date',
        'layout',
        'layout.file',
        'layout.numbered_blocks',
        'layout.numbered_blocks.image',
        'layout.numbered_blocks.video',
        'layout.image',
        'layout.image_right',
        'layout.image_left',
        'layout.images',
        'layout.video',
        'layout.timetable',
        'layout.image_on_right',
        'trainers',
        'trainers.trainer_photo',
        'trainers.photo_for_video',
        'trainers.trainers_points',
        'references',
        'references.photo',
        'references.types',
        'souvisejici_skolenis',
        'souvisejici_skolenis.dates.date',
        'souvisejici_skolenis.cover_image',
        'program.video.file',
        'course_manual',
      ],
      filters: {
        url_alias: {
          $eq: slug,
        },
      },
    },
    {
      encodeValuesOnly: true,
    }
  )
  return axios.get(`/api/akademie-skolenis/?${query}`)
}

export const getAcademyCourseTypes = (axios) => {
  const query = qs.stringify(
    {
      populate: ['course_types'],
    },
    {
      encodeValuesOnly: true,
    }
  )
  return axios.get(`/api/druhy-kurzus/?${query}`)
}
