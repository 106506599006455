
export default {
  name: 'Homepage',
  props: {
    page: {
      type: Object,
      default: null,
    },
    events: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      api: process.env.apiUrl,
      newArticles: [],
    }
  },
  async fetch() {
    this.newArticles = await this.$store.dispatch('articles/getNewArticles')
  },
  head() {
    return this.$seo.generate(
      this.page?.serp,
      this.page?.social_sharing,
      this.page?.page_title_h1
    )
  }
}
