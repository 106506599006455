
export default {
  name: 'Free',
  async asyncData({ store, app }) {
    try {
      const [pageRes, trainersRes, testimonials] = await Promise.all([
        app.$api.getFreeLecturePage(),
        app.$api.getTrainers(),
        store.dispatch('testimonials/getTestimonials')
      ])

      return {
        page: pageRes.data.data.attributes,
        promoVideo: pageRes.data.data.attributes.promo_video?.video.data.attributes,
        trainers: trainersRes.data.data, // copy pasting trainers/index.vue here
        api: process.env.apiUrl,
        testimonials
      }
    } 
    catch(e) {
      console.error(e)
    }
  },
  head() {
    return this.$seo.generate(
      this.page?.serp,
      this.page?.social_sharing,
      this.page?.page_title_h1
    )
  },
  computed: {
    coverImg() {
      if (!this.page?.cover_image?.data) {
        return ''
      }
      return this.page.cover_image.data.attributes.url
    },
  },
}
