import qs from 'qs'

export const getAcademyTrainersPage = (axios) => {
  const query = qs.stringify(
    {
      populate: [
        'cover_image',
        'serp',
        'promo_seal',
      ],
    },
    {
      encodeValuesOnly: true,
    }
  )

  return axios.get(`/api/akademie-lektori?${query.trim()}`)
}

export const getAcademyTrainers = (axios) => {
  const query = qs.stringify(
    {
      filters: {
        related_type: {
          id: {
            $eq: '2', // related to ACADEMY
          },
        },
        show_on_web: {
          $eq: true,
        },
      },
      populate: ['photo_for_video', 'trainer_video', 'trainer_photo'],
    },
    {
      encodeValuesOnly: true,
    }
  )
  return axios.get(`/api/profily-trenerus?${query}`)
}

export const getAcademyTrainerBySlug = (axios, slug) => {
  const query = qs.stringify(
    {
      populate: [
        'trainer_photo',
        'trainers_points',
        'serp',
        'social_sharing',
        'social_sharing.social_image',
        'akademie_skolenis',
        'akademie_skolenis.cover_image',
        'akademie_skolenis.dates.date',
        'clankies',
        'clankies.types',
        'clankies.cover_image',
      ],
      filters: {
        url_alias: {
          $eq: slug,
        },
      }
    },
    {
      encodeValuesOnly: true,
    }
  )
  return axios.get(`/api/profily-trenerus?${query}`)
}
