
import gsap from 'gsap'
// import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'

// gsap.registerPlugin(ScrollTrigger)

export default {
  name: 'AcademyAcademy',
  layout: 'academy_default',
  async asyncData({ app, redirect }) {
    try {
      const { data } = await app.$api.getAcademyAcademy()
      return {
        page: data.data.attributes,
        promoVideo: data.data.attributes.promo_video?.video.data.attributes,
        api: process.env.apiUrl,
      }
    } catch {
      redirect('/')
    }
  },
  head() {
    return this.$seo.generate(
      this.page?.serp,
      this.page?.social_sharing,
      this.page?.page_title_h1
    )
  },
  computed: {
    coverImg() {
      if (!this.page?.cover_image?.data) {
        return ''
      }
      return this.page.cover_image.data.attributes.url
    },
  },
  mounted() {
    this.animateOnScroll()
  },
  beforeDestroy() {
    this.tl.kill()
    this.tl = null
  },
  methods: { 
    animateOnScroll() {
      this.tl = gsap.timeline({
        scrollTrigger: {
          trigger: '.video-wrapper',
          start: '-20%'
        },
      })
      this.tl.set('.video-element', { x: 20, opacity: 0 })
      this.tl.fromTo('.video-element', {
        x: 20,
        opacity: 0
      },
      {
        x: 0,
        opacity: 1,
        duration: 0.5,
        ease: 'easeIn',
        delay: 0.2,
      })
    }
  }
}
