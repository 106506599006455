import qs from 'qs'

export const getAcademyInHouse = (axios) => {
  const query = qs.stringify(
    {
      populate: [
        'cover_image',
        'serp',
        'yellow_block_body',
        'yellow_block_body.icon',
        'promo_seal'
      ],
    },
    {
      encodeValuesOnly: true,
    }
  )

  return axios.get(`/api/akademie-inhouse-skoleni?${query.trim()}`)
}
