import { render, staticRenderFns } from "./NavCheck.vue?vue&type=template&id=446d80bd&"
import script from "./NavCheck.vue?vue&type=script&lang=js&"
export * from "./NavCheck.vue?vue&type=script&lang=js&"
import style0 from "./NavCheck.vue?vue&type=style&index=0&id=446d80bd&prod&lang=postcss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LogoAcademy: require('/home/runner/work/3df/3df/components/Logo/LogoAcademy.vue').default,Toggle: require('/home/runner/work/3df/3df/components/Toggle/Toggle.vue').default})
