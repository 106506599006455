export const getPricingPage = (axios) => {
  const populate = [
    'serp',
    'social_sharing',
    'social_sharing.social_image',
    'cover_image',
    'promo_block',
    'price_cards',
    'price_cards.image',
    'price_cards_2',
    'price_cards_2.image',
    'price_cards_3',
    'price_cards_3.image',
  ]
  const query = `
    populate=${populate.join(',')}
    `
  return axios.get(`/api/gym-cenik?${query.trim()}`)
}
