var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-testimonial px-4",class:{ full: !_vm.generatedImage }},[_c('div',{staticClass:"card-testimonial__inner"},[(_vm.generatedImage)?_c('div',{staticClass:"card-testimonial__image shrink-0 md:row-span-2"},[_c('picture',[_c('source',{attrs:{"media":"(min-width: 769px)","srcset":`
          ${'/_ipx/f_jpeg,s_240x240/strapi' + _vm.generatedImage} 1x,
          ${'/_ipx/f_jpeg,s_480x480/strapi' + _vm.generatedImage} 1.5x
          `}}),_vm._v(" "),_c('source',{attrs:{"media":"(max-width: 768px)","srcset":`
          ${'/_ipx/f_jpeg,s_88x88/strapi' + _vm.generatedImage} 1x,
          ${'/_ipx/f_jpeg,s_176x176/strapi' + _vm.generatedImage} 1.5x
          `}}),_vm._v(" "),_c('img',{attrs:{"src":'/_ipx/f_jpeg,s_240x240/strapi' + _vm.generatedImage,"alt":""}})])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"card-testimonial__header flex flex-col justify-center md:justify-start"},[_c('div',{staticClass:"paragraph-bold"},[_vm._v(_vm._s(_vm.author))]),_vm._v(" "),_c('div',{staticClass:"flex card-testimonial__stars mt-5"},_vm._l((_vm.stars),function(star){return _c('icon-star',{key:star})}),1)]),_vm._v(" "),_c('div',{staticClass:"mt-5 mb-9 card-testimonial__text",domProps:{"innerHTML":_vm._s(_vm.text)}})])])
}
var staticRenderFns = []

export { render, staticRenderFns }