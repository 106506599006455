
import gsap from 'gsap'
import { onMounted, onUnmounted, ref } from '@nuxtjs/composition-api'
export default {
  props: {
    content: {
      type: Array,
      default: () => [],
    },
  },
  setup({ content }) {
    let isMobile
    const api = ref(`${process.env.apiUrl}`)
    const getFullUrl = (idx) => {
      if (!content.length || !content[idx].image) return ''
      return content[idx].image.data?.attributes?.url
    }
    onMounted(() => {
      isMobile = window.innerWidth < 1024
      if (isMobile) {
        return
      }
      const slides = document.querySelectorAll('.overlay-slider__slide')
      slides.forEach((slide) => {
        const content = slide.querySelector('.overlay-slider__slide-content')
        const hidden = slide.querySelector('.overlay-slider__slide-hidden')
        if (!content || !hidden) return
        const hiddenHeight = hidden.offsetHeight
        gsap.set(content, { y: hiddenHeight / 2 })
      })
    })
    let tl = gsap.timeline({
      defaults: {
        duration: 0.55,
        ease: 'power1.out',
      },
    })
    onUnmounted(() => {
      tl.kill()
      tl = null // Let garbage collector collect this right away
    })
    const animation = (e) => {
      if (isMobile) {
        return
      }
      const target = e.target
      const mask = target.querySelector('.overlay-slider__slide-mask')
      const content = target.querySelector('.overlay-slider__slide-content')
      const title = content.querySelector('.hover-slide-title')
      const hidden = target.querySelector('.overlay-slider__slide-hidden')
      const hiddenBtn = hidden.querySelector('.btn')
      const hiddenP = hidden.querySelector('p')
      if (!mask || !content || !hidden || !hiddenBtn || !hiddenP || !title)
        return
      if (e.type === 'mouseenter') {
        tl.addLabel('start')
          .to(
            content,
            {
              y: 0,
              ease: 'power2.inOut',
            },
            'start'
          )
          .to(
            mask,
            {
              yPercent: 100,
            },
            'start'
          )
          .to(
            content,
            {
              color: '#1D1D1B',
            },
            'start'
          )
          .to(hiddenP, {
            autoAlpha: 1,
            y: 0,
            duration: 0.3,
          })
          .to(hiddenBtn, {
            autoAlpha: 1,
            y: 0,
            duration: 0.3,
          })
      } else {
        tl.progress(1)
        tl.addLabel('end')
          .to(
            content,
            {
              y: hidden.offsetHeight / 2,
              ease: 'power2.inOut',
            },
            'end'
          )
          .to(
            hiddenP,
            {
              autoAlpha: 0,
              y: -200,
              duration: 0.3,
            },
            'end'
          )
          .to(
            content,
            {
              color: '#F9F7F7',
            },
            'end'
          )
          .to(
            hiddenBtn,
            {
              autoAlpha: 0,
              y: -136,
              duration: 0.3,
            },
            'end'
          )
          .to(
            mask,
            {
              yPercent: -100,
              onComplete: () => {
                target.classList.remove('active')
              },
            },
            'end'
          )
          .to(hiddenP, {
            y: 200,
            duration: 0,
          })
          .to(hiddenBtn, {
            y: 136,
            duration: 0,
          })
      }
    }
    return {
      animation,
      api,
      getFullUrl,
    }
  },
}
