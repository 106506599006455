
export default {
  name: 'Magazine',
  async asyncData({ app, redirect, route }) {
    try {
      const { data } = await app.$api.getMagazinePage()

      let page = route.query.page ? Number(route.query.page) : 1
      let articles = await app.$api.getArticlesByPage(page)

      if (!articles.data.data.length) {
        page = 1
        articles = await app.$api.getArticlesByPage(page)
      }

      return {
        listPage: page,
        page: data?.data?.attributes,
        popular: data?.data?.attributes?.popular_articles?.data,
        meta: articles?.data?.meta?.pagination,
        articles: articles?.data?.data,
      }
    } catch {
      redirect('/')
    }
  },
  head() {
    return this.$seo.generate(
      this.page?.serp,
      this.page?.social_sharing,
      this.page?.page_title_h1
    )
  },
  mounted() {
    // In case articles came in empty due to invalid page number, this fixes
    // the page num in route
    this.$router.replace(`${this.$route.path}?page=${this.listPage}`)
  },
  methods: {
    async newPage(page) {
      try {
        const articles = await this.$api.getArticlesByPage(
          page > 0 ? this.meta.page + 1 : this.meta.page - 1
        )
        this.articles = articles.data.data
        this.meta = articles.data.meta?.pagination
        await this.$router.replace({
          name: 'magazine',
          query: { ...this.$route.query, page: this.meta.page },
        })
        window.scrollTo(0, 0)
      } catch (e) {
        console.log(e)
      }
    },
  },
}
