
import { useForm } from '~/composables/form'
import validators from '~/helpers/validators'

const CountryCodeByName = {
  'Česká republika': 'cz',
  'Slovensko': 'sk',
}

const CountryNameByCode = {
  'cz': 'Česká republika',
  'sk': 'Slovensko',
}

export default {
  name: 'PaymentOverview',
  props: {
    dates: {
      type: Array,
      default: null,
    },
    price: {
      type: Number,
      default: null
    },
    courseName: {
      type: String,
      default: null
    },
    courseSlug: {
      type: String,
      default: null
    },
    selected: {
      type: Object,
      default: null
    }
  },
  setup() {
    return {
      user: useForm([
        { name: 'firstName', validator: validators.firstName },
        { name: 'lastName',  validator: validators.lastName },
        { name: 'email',     validator: validators.email },
        { name: 'phone',     validator: validators.notEmpty },
        { name: 'gdpr',      validator: validators.gdpr },
      ]),
      invoice: useForm([
        { name: 'companyname' },
        { name: 'address' },
        { name: 'city' },
        { name: 'zip' },
        { name: 'companyID' },
        { name: 'taxID' },
      ])
    }
  },
  data() {
    return {
      selectedItem: this.selected,
      selectedCountry: 'Česká republika',
      errorUserExists: false,
    }
  },
  watch: {
    'user.fields.email.value'() {
      this.errorUserExists = false // reset when user changes email input
    },
    '$store.state.user.user'(newValue, oldValue) {
      if (!oldValue && newValue) { // user has logged in
        this.errorUserExists = false
        this.prefillForm()
      }
    }
  },
  beforeMount() {
    if (this.$store.getters['user/isLoggedIn']) {
      this.prefillForm()
    }
  },
  methods: {
    prefillForm() {
      const {
        firstname,
        lastname,
        phone,
        email,
        company_address_city,
        company_address_country,
        company_address_street,
        company_address_zip,
        company_id,
        company_name,
        company_vat_id,
      } = this.$store.state.user.user.user

      this.user.fields.firstName.value = firstname
      this.user.fields.lastName.value = lastname
      this.user.fields.phone.value = phone
      this.user.fields.email.value = email

      this.invoice.fields.companyname.value = company_name
      this.invoice.fields.address.value = company_address_street
      this.invoice.fields.city.value = company_address_city
      this.invoice.fields.zip.value = company_address_zip
      this.invoice.fields.companyID.value = company_id
      this.invoice.fields.taxID.value = company_vat_id

      this.selectedCountry = CountryNameByCode[company_address_country]
    },
    async checkEmail() {
      // If user is logged in, there's no need to recheck email on input
      if (!this.$store.getters['user/isLoggedIn']) {
        const found = await this.$store.dispatch('user/findUser', this.user.fields.email.value)
        this.errorUserExists = !!found
      }
    },
    formatDate(date) {
      const options = { year: 'numeric', month: '2-digit', day: '2-digit' }
      return new Date(date).toLocaleDateString('cs-CS', options)
    },
    formatPrice(price) {
      return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
    },
    onPaymentClick() {
      this.user.enableValidation()

      if (this.user.isValid()) {
        return this.$emit('submit', {
          user: {
            ...this.user.json(),
            siteContext: this.$store.getters.siteContextId,
          },
          invoice: {
            ...this.invoice.json(),
            country: CountryCodeByName[this.selectedCountry]
          },
        })
      }
    }
  }
}
