
import { useStore } from '@nuxtjs/composition-api'

export default {
  name: "Toggle",
  props: ['value'],
  setup() {
    const store = useStore()
    const switchToggle = () => {
      store.commit('attendance/switch')
    }
    
    return { switchToggle }
  },
}

