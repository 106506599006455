
import { defineComponent } from '@nuxtjs/composition-api'
import CardSmall from '~/components/Card/CardSmall/CardSmall'

export default defineComponent({
  name: 'WorkoutDetail',
  components: { CardSmall },
  async asyncData({ app, redirect, route }) {
    const { data } = await app.$api.getWorkoutVideoBySlug(route.params.slug)
    if (!data) {
      redirect('/')
    }
    return {
      page: data.data[0].attributes,
      api: process.env.apiUrl,
      trainers: data?.data[0]?.attributes?.trainers?.data,
      videos: data?.data[0]?.attributes?.related_videos?.data,
    }
  },
  head() {
    return this.$seo.generate(
      this.page?.serp,
      this.page?.social_sharing,
      this.page?.video_name
    )
  },
  computed: {
    coverImage() {
      if (!this.page || !this.page.cover_image || !this.page.cover_image.data) {
        return null
      }
      return `${this.page.cover_image.data.attributes.url}`
    },
  },
  methods: {
    scrollScreen() {
      const header = document.querySelector('.details-page__header')
      const headerHeight = header.offsetHeight
      if (!header && !headerHeight) {
        return
      }
      window.scrollTo({
        top: headerHeight,
        behavior: 'smooth',
      })
    },
  },
})
