
import { computed, useStore, watch } from '@nuxtjs/composition-api'
import modal from '~/mixins/modal'

export default {
  name: 'Menu',
  props: {
    leftLinks: {
      type: Array,
      required: true
    },
    rightLinks: {
      type: Array,
      required: true
    },
    contactLinks: {
      type: Array,
      required: true
    }
  },
  mixins: [modal], // openLoginModal, openRegisterModal
  setup() {
    const store = useStore()
    const menuIsVisible = computed(() => store.state.menu.menuIsVisible)
    watch(menuIsVisible, (newValue) => {
      if (!document) return
      if (newValue) {
        document.body.classList.add('no-scroll')
      } else {
        document.body.classList.remove('no-scroll')
      }
    })
    return {
      menuIsVisible,
      store,
    }
  },
  methods: {
    hideMenu() {
      this.$store.dispatch('menu/closeMenu')
    },
  },
}
