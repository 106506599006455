
export default {
  name: 'AcademyClientOnlineCourses',
  layout: 'academy_client',
  async asyncData({ app, redirect, store }) {
    try {
      const [allOnlineCourses, user] = await Promise.all([
        app.$api.getAcademyOnlineCourses(),
        store.dispatch('user/findMe')
      ])

      return {
        api: process.env.apiUrl,
        user,
        boughtOnlineCourses: user.online_course_inventory,
        allOnlineCourses: allOnlineCourses.data.data,
      }
    } catch(e) {
      console.error(e)
      redirect('/')
    }
  },
  head() {
    return {
      title: 'Moje online kurzy | 3D Fitness Academy'
    }
  },
  computed: {
    filteredOnlineCourses() {
      const notBoughtAllOnlineCourses = this.allOnlineCourses.filter(online => !this.boughtOnlineCourses.some(boughtOnline => online.id === boughtOnline.online_kurzy.id))
      return notBoughtAllOnlineCourses
    }
  }
}
