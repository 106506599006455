
export default {
  props: {
    to: {
      type: String,
      default: 'lessons',
    },
    content: {
      type: Object,
      required: true,
    },
    overlay: {
      type: Boolean,
      default: false,
    },
    isNew: {
      type: Boolean,
      default: false,
    },
    image: {
      type: String,
      default: '',
    },
    time: {
      type: String,
      default: 'Online'
    },
    isOnline: {
      type: Boolean,
      default: false,
    },
    date: {
      type: String,
      default: null
    }
  },
  computed: {
    title() {
      return this.content?.lesson_name || this.content?.video_name || this.content?.course_name || this.content?.online_course_name || ''
    },
    generatedImage() {
      return this.content?.cover_image?.data?.attributes?.url || this.content.cover_image?.url
    },
    href() {
      return {
        name: `${this.to}-detail`,
        params: {
          slug: this.content.url_alias,
        },
      }
    },
  },
  methods: {
    formatDate(date) {
      const options = { year: 'numeric', month: '2-digit', day: '2-digit' }
      return new Date(date).toLocaleDateString('cs-CS', options)
    },
  },
}
