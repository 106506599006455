import { render, staticRenderFns } from "./personal.vue?vue&type=template&id=4a3219e4&"
import script from "./personal.vue?vue&type=script&lang=js&"
export * from "./personal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HeaderDefault: require('/home/runner/work/3df/3df/components/Header/HeaderDefault/HeaderDefault.vue').default,BlockImage: require('/home/runner/work/3df/3df/components/Block/BlockImage/BlockImage.vue').default,BannerLogo: require('/home/runner/work/3df/3df/components/Banner/BannerLogo/BannerLogo.vue').default,Banner: require('/home/runner/work/3df/3df/components/Banner/Banner.vue').default,SectionRegister: require('/home/runner/work/3df/3df/components/Section/SectionRegister/SectionRegister.vue').default})
