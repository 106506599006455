
export default {
  name: 'Trainers',
  async asyncData({ store, app, redirect }) {
    try {
      const [trainersPage, trainers, testimonials] = await Promise.all([
        app.$api.getTrainersPage(),
        app.$api.getTrainers(),
        store.dispatch('testimonials/getTestimonials')
      ])

      return {
        page: trainersPage.data.data.attributes,
        trainers: trainers.data.data,
        testimonials
      }
    } catch {
      redirect('/')
    }
  },
  head() {
    return this.$seo.generate(
      this.page?.serp,
      this.page?.social_sharing,
      this.page?.page_title_h1
    )
  },
  computed: {
    coverImg() {
      if (!this.page) {
        return ''
      }
      return this.page?.cover_image?.data?.attributes?.url
    },
  },
}
