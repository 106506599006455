
import AcademyCardTestimonial from '~/components/Card/CardTestimonial/AcademyCardTestimonial.vue'

export default {
  name: 'AcademyInHouse',
  components: { AcademyCardTestimonial },
  layout: 'academy_default',
  async asyncData({ app, redirect }) {
    try {
      const { data } = await app.$api.getAcademyInHouse()
      const testimonials = await app.$api.getAcademyTestimonials()

      return {
        page: data.data.attributes,
        promoVideo: data.data.attributes.promo_video?.video.data.attributes,
        api: process.env.apiUrl,
        testimonials: testimonials.data.data
      }
    } catch {
      redirect('/')
    }
  },
  head() {
    return this.$seo.generate(
      this.page?.serp,
      this.page?.social_sharing,
      this.page?.page_title_h1
    )
  },
  computed: {
    coverImg() {
      if (!this.page?.cover_image?.data) {
        return ''
      }
      return this.page.cover_image.data.attributes.url
    },
  },
}
