
export default {
  name: 'AcademyTrainers',
  layout: 'academy_default',
  async asyncData({ app, redirect }) {
    try {
      const { data } = await app.$api.getAcademyTrainersPage()
      const trainers = await app.$api.getAcademyTrainers()

      return {
        page: data.data.attributes,
        api: process.env.apiUrl,
        trainers: trainers.data.data,
      }
    } catch(e) {
      console.error(e)
      redirect('/')
    }
  },
  head() {
    return this.$seo.generate(
      this.page?.serp,
      this.page?.social_sharing,
      this.page?.page_title_h1
    )
  },
  computed: {
    coverImg() {
      if (!this.page?.cover_image?.data) {
        return ''
      }
      return this.page.cover_image.data.attributes.url
    },
  },
}
