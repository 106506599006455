
import gsap from 'gsap'
import {
  computed,
  onMounted,
  onUnmounted,
  useRoute,
  useStore,
  watch,
} from '@nuxtjs/composition-api'

export default {
  name: 'Nav',
  props: {
    logo: {
      type: Array,
      required: true
    },
    items: {    // format: [{ url: { name: '' }, label: '' }, ...]
      type: Array,
      required: true,
    }
  },
  setup() {
    const store = useStore()
    const route = useRoute()
    const menuIsOpen = computed(() => store.state.menu.menuIsVisible)
    let tl = gsap.timeline()

    let nav
    let switchTriggers

    function debounce(func, timeout = 300) {
      let timer
      return (...args) => {
        clearTimeout(timer)
        timer = setTimeout(() => {
          func.apply(this, args)
        }, timeout)
      }
    }
    function runBurgerAnimation() {
      tl.to('.svg-menu-top', {
        duration: 0.3,
        rotation: !menuIsOpen.value ? 0 : -25.5,
      }).to(
        '.svg-menu-bottom',
        {
          duration: 0.3,
          rotation: !menuIsOpen.value ? 0 : 25.5,
          ease: 'power3.inOut',
        },
        '-=0.3'
      )
    }
    watch(route, () => {
      setTimeout(() => {
        init()
        if (menuIsOpen.value) {
          switchMenu()
        }
      }, 400)
    })
    const switchMenu = () => {
      store.commit('menu/switch')
      runBurgerAnimation()
    }
    watch(menuIsOpen, () => {
      if (!menuIsOpen.value) {
        runBurgerAnimation()
      }
    })
    const checkOffset = (item) => {
      return (
        window.scrollY + 80 > item.offsetTop &&
        window.scrollY + 50 < item.offsetTop + item.offsetHeight
      )
    }
    const switchColors = () => {
      if (Array.from(switchTriggers).some(checkOffset)) {
        nav.classList.add('nav--black')
      } else {
        nav.classList.remove('nav--black')
      }
    }
    const checkScroll = () => {
      if (window.scrollY > window.innerHeight) {
        nav.classList.add('nav--scrolled')
      } else {
        nav.classList.remove('nav--scrolled')
      }
    }

    const onKeyDown = (e) => {
      if (e.keyCode === 27 && menuIsOpen.value) {
        switchMenu()
      }
    }
    
    const processChangeSwitch = debounce(() => switchColors(), 100)
    const processChangeScroll = debounce(() => checkScroll(), 100)

    const onScroll = () => {
      processChangeSwitch()
      processChangeScroll()
    }

    const init = () => {
      switchTriggers = document.querySelectorAll('.js-nav-trigger')
      nav = document.querySelector('.nav')
      if (!nav) return
      switchColors()
     
      document.addEventListener('keydown', onKeyDown)
      window.addEventListener('scroll', onScroll)
    }
    onMounted(() => {
      init()
    })
    onUnmounted(() => {
      tl.kill()
      tl = null // Let garbage collector collect this right away
      
      document.removeEventListener('keydown', onKeyDown)
      window.removeEventListener('scroll', onScroll)
    })
    
    return { menuIsOpen, switchMenu }
  }
}
