
import { defineComponent } from '@nuxtjs/composition-api'
import { collectionBySiteContextFilter } from '~/helpers/filters'

export default defineComponent({
  name: 'AcademyClientCourseDetail',
  layout: 'academy_client',
  async asyncData({ app, store, redirect, route }) {
    try {
      const { data } = await app.$api.getAcademyLectureBySlug(route.params.slug)
      const user = await store.dispatch('user/findMe')
      const byContextFilter = collectionBySiteContextFilter(store.getters.siteContextId)
      const page = data?.data[0]?.attributes
  
      return {
        data: data?.data[0],
        page,
        user,
        dates: page?.dates,
        trainers: page.trainers?.data,
        references: page.references?.data.filter(byContextFilter),
        souvisejici_skolenis: page.souvisejici_skolenis?.data,
        program: page.program[0],
        boughtCourse: user.course_inventory?.filter(course => course.akademie_skoleni?.id === data.data[0].id )[0] || null,
        attendance: user.course_inventory?.filter(course => course.akademie_skoleni?.id === data.data[0].id )[0]?.course_attendance[0],
        api: process.env.apiUrl,
      }
    }
    catch(e) {
      console.error(e)
      redirect('/')
    }
  },
  head() {
    return this.$seo.generate(
      this.page?.serp,
      this.page?.social_sharing,
      this.page?.course_name
    )
  },
  computed: {
    coverImage() {
      if (!this.page || !this.page.cover_image || !this.page.cover_image.data) {
        return null
      }
      return `${this.page.cover_image.data.attributes.url}`
    },
  },
  methods: {
    scrollScreen() {
      const header = document.querySelector('.details-page__header')
      const headerHeight = header.offsetHeight
      if (!header && !headerHeight) {
        return
      }
      window.scrollTo({
        top: headerHeight,
        behavior: 'smooth',
      })
    },
  },
})
