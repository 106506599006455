
import { defineComponent } from '@nuxtjs/composition-api'
import ConstructorLayout from '~/components/Constructor/ConstructorLayout'
import CardAuthor from '~/components/Card/CardAuthor/CardAuthor'

export default defineComponent({
  name: 'LessonDetail',
  components: { CardAuthor, ConstructorLayout },
  async asyncData({ app, redirect, route }) {
    const { data } = await app.$api.getArticleBySlug(route.params.slug)
    if (!data || !data.data || !data.data.length) {
      redirect('/')
    }

    return {
      page: data?.data[0]?.attributes,
      author: data?.data[0]?.attributes?.author?.data?.attributes,
      api: process.env.apiUrl,
    }
  },
  head() {
    return this.$seo.generate(
      this.page?.serp,
      this.page?.social_sharing,
      this.page?.article_title
    )
  },
  computed: {
    coverImage() {
      if (!this.page || !this.page.cover_image || !this.page.cover_image.data) {
        return null
      }
      return `${this.page.cover_image.data.attributes.url}`
    },
  },
  methods: {
    scrollScreen() {
      const header = document.querySelector('.details-page__header')
      const headerHeight = header.offsetHeight
      if (!header && !headerHeight) {
        return
      }
      window.scrollTo({
        top: headerHeight,
        behavior: 'smooth',
      })
    },
  },
})
