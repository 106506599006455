
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import '~/assets/css/components/date-picker.pcss'
import 'vue2-datepicker/locale/cs'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/dist/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

export default {
  name: 'AcademyLectures',
  components: { DatePicker },
  layout: 'academy_default',
  async asyncData({ app, redirect }) {
    try {
      const [lecturesPage, courses, types] = await Promise.all([
        app.$api.getAcademyLecturesPage(),
        app.$api.getAcademyLectures(),
        app.$api.getAcademyCourseTypes()
      ])

      return {
        page: lecturesPage.data.data.attributes,
        api: process.env.apiUrl,
        courses: courses.data.data,
        types: types.data.data
      }
    } catch(e) {
      console.error(e)
      redirect('/')
    }
  },
  data() {
      return {
        cardCols: 4,
        customTypeSelect: 'Jakékoliv',
        dateRange: null,
        datePickerOpen: false,
      }
  },
  head() {
    return this.$seo.generate(
      this.page?.serp,
      this.page?.social_sharing,
      this.page?.page_title_h1
    )
  },
  computed: {
    coverImg() {
      if (!this.page?.cover_image?.data) {
        return ''
      }
      return this.page.cover_image.data.attributes.url
    },
    filterOn() {
      if (this.dateRange !== null) {
        return true
      }
      if (this.customTypeSelect !== 'Jakékoliv') {
        return true
      }
      return false
    },
    isMobile() {
      return process.client ? window.innerWidth < 768 : true
    },
    today () {
      const today = new Date()
      let day = today.getDate()
      day = day.toString()
      if (day.length < 2) {
        day = '0' + day
      }
      let month = today.getMonth() + 1
      month = month.toString()
      if (month.length < 2) {
        month = '0' + month
      }
      return today.getFullYear() + '-' + month + '-' + day
    },
    allTypes() {
      const types = []
      for(let i = 0; i < this.types.length; i++) {
        types.push(this.types[i].attributes.name)
      }
      types.unshift('Jakékoliv')
      return types
    },
    cardInstances() {
      const cards = []
      this.courses.forEach((course) => {
        if (course.attributes.dates) {
          course.attributes.dates.date.forEach((d) => {
          cards.push({course, date: d})
          })
        } else {
          return null
        }

      })
      return cards
    },
    filteredCourses() {
      let sortedCards = [...this.cardInstances]
      sortedCards = sortedCards.filter(date => date.date.date > this.today)
      if (this.customTypeSelect !== 'Jakékoliv') {
        sortedCards = sortedCards.filter((el) => {
          return el.course.attributes.course_types.data.some(course => course.attributes.name === this.customTypeSelect)
        })
      }
      if (this.dateRange !== null) {
        const minDate = Date.parse(this.dateRange[0])
        const maxDate = Date.parse(this.dateRange[1])
        sortedCards = sortedCards.filter(
            (card) => {
              const date = Date.parse(card.date.date)
              return date >= minDate && date <= maxDate
            }
        )
      }
      sortedCards = sortedCards.sort(function (a, b) {
        return (Date.parse(a.date.date) > Date.parse(b.date.date)) ? 1 : (Date.parse(a.date.date) < Date.parse(b.date.date)) ? -1 : 0;
      })
      return sortedCards
    }
  },
  watch: {
    datePickerOpen() {
      const dateInput = document.querySelector('.mx-input')
      this.datePickerOpen === false
          ? dateInput.classList.remove('picker-open')
          : dateInput.classList.add('picker-open')
    }
  },
  methods: {
    removeFilter() {
      this.dateRange = null
      this.customTypeSelect = this.allTypes[0]
      this.$refs.select = this.allTypes[0]
      console.log(this.customTypeSelect)
    },
    openCalendar() {
      this.datePickerOpen = true
      setTimeout(() => {
        const firstDate = document.querySelector('.cell.active')
        if (document.querySelectorAll('.cell.active').length === 1) {
          firstDate.classList.add('single-active')
        }
        if (document.querySelectorAll('.cell.active').length === 2) {
          document
              .querySelectorAll('.cell.active')[1]
              .classList.add('end-date')
        }
      }, 100)
    },
    closeCalendar() {
      this.datePickerOpen = false
    },
    updateCalendar() {
      setTimeout(() => {
        const firstDate = document.querySelector('.cell.active')
        firstDate.classList.add('start-date')
        document.querySelector('.mx-datepicker-main').classList.add('picking')
      }, 100)
    },
  }
}
