
import context from '~/mixins/context'

export default {
  name: "ModalPassword",
  mixins: [context],
  data: () => ({
    isVisible: false,
    canClose: true,   // In some cases we want to force user to set the password
    onSubmit: null,   // set in beforeOpen()
    state: 'set',
    password: '',
    passwordConfirm: '',
    validate: false,
  }),
  computed: {
    isFormValid() { return !this.passwordError && !this.confirmError && !this.equalityError },
    passwordError() { return this.validate && this.password.length < 8 },
    confirmError() { return this.validate && this.passwordConfirm === '' },
    equalityError() { return this.validate && !this.passwordError && !this.confirmError && this.password !== this.passwordConfirm },
  },
  methods: {
    beforeOpen(event) {
      this.state = 'set'
      
      this.canClose = event.ref.params.canClose ?? this.canClose
      this.onSubmit = event.ref.params.onSubmit ?? this.defaultOnSubmit
    },
    async defaultOnSubmit(password, _passwordConfirm) {
      await this.$store
        .dispatch('user/setPassword', password)
        .catch(console.error)
    },
    handleSubmit() {
      this.validate = true
      
      if (this.isFormValid) {
        this.onSubmit(this.password, this.passwordConfirm)
            .then(() => {
              this.state = 'success'
              this.canClose = true
            })
      }
    },
  },
}
