import qs from 'qs'

export const getLessonsPage = (axios) => {
  const query = qs.stringify(
    {
      populate: [
        'serp',
        'social_sharing',
        'social_sharing_default_img',
        'yellow_block',
        'yellow_block.image',
        'cover_image',
      ],
    },
    {
      encodeValuesOnly: true,
    }
  )
  return axios.get(`/api/gym-nase-lekce?${query}`)
}

export const getLessonsPageLessons = (axios) => {
  const query = qs.stringify(
    {
      filters: {
        show_on_web: {
          $eq: true,
        },
      },
      populate: ['cover_image'],
      sort: ['sort_index:asc'],
    },
    {
      encodeValuesOnly: true,
    }
  )
  return axios.get(`/api/gym-lessons?${query}`)
}

export const getLessonBySlug = (axios, slug) => {
  const query = qs.stringify(
    {
      populate: [
        'cover_image',
        'layout',
        'layout.file',
        'layout.numbered_blocks',
        'layout.numbered_blocks.image',
        'layout.numbered_blocks.video',
        'layout.image',
        'layout.images',
        'layout.image_right',
        'layout.image_left',
        'layout.video',
        'trainers',
        'trainers.trainer_photo',
        'trainers.photo_for_video',
        'trainers.trainers_points',
      ],
      filters: {
        url_alias: {
          $eq: slug,
        },
      },
    },
    {
      encodeValuesOnly: true,
    }
  )
  return axios.get(`/api/gym-lessons/?${query}`)
}

export const getGroupLessons = (axios) => {
  const query = qs.stringify(
    {
      populate: ['price_cards', 'price_cards.image'],
    },
    {
      encodeValuesOnly: true,
    }
  )
  return axios.get(`/api/gym-cenik?${query}`)
}

export const getMostPopularArticles = (axios) => {
  const query = qs.stringify({
    populate: ['popular_articles', 'popular_articles.cover_image'],
  })
  return axios.get(`/api/gym-magazin?${query}`)
}
