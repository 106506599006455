
import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  name: 'LessonDetail',
  async asyncData({ app, redirect }) {
    const { data } = await app.$api.getPersonalDataAgreement()
    if (!data) {
      redirect('/')
    }
    return {
      page: data?.data?.attributes,
      api: process.env.apiUrl,
    }
  },
  head() {
    return this.$seo.generate(
      this.page?.serp,
      this.page?.social_sharing,
      this.page?.lesson_name
    )
  },
  computed: {
    coverImage() {
      if (!this.page || !this.page.cover_image || !this.page.cover_image.data) {
        if (this.$s('header_default_img')?.data?.attributes?.url) {
          return this.api + this.$s('header_default_img')?.data?.attributes?.url
        }
        return ''
      }
      return `${this.api}${this.page.cover_image.data.attributes.url}`
    },
  },
  methods: {
    scrollScreen() {
      const header = document.querySelector('.details-page__header')
      const headerHeight = header.offsetHeight
      if (!header && !headerHeight) {
        return
      }
      window.scrollTo({
        top: headerHeight,
        behavior: 'smooth',
      })
    },
  },
})
