
import emailValidator from "email-validator"

export default {
  data() {
    return {
      state: 'set',
      email: '',
      validate: false,
      isLoading: false,
      isVisible: false,
      doesUserExist: true,
    }
  },
  computed: {
    emailError() { return this.validate && !emailValidator.validate(this.email) }
  },
  methods: {
    validateForgotPassword() {
      this.validate = true
      return !this.emailError
    },
    async forgotPassword() {
      if (!this.validateForgotPassword()) {
        return
      }

      this.validate = false
      this.isLoading = true
      
      try {
        const found = await this.$store.dispatch('user/findUser', this.email)

        if (found) {
          await this.$store.dispatch('user/forgotPassword', { email: this.email })
          this.state = 'success'
        }
        else {
          this.doesUserExist = false
        }
      }
      catch(e) {
        console.error(e)
      }

      this.isLoading = false
    },
    register() {
      this.$vfm.hide('forgot')
      this.$vfm.show('register')
    }
  }
}
