import { render, staticRenderFns } from "./questions.vue?vue&type=template&id=2f30803f&"
import script from "./questions.vue?vue&type=script&lang=js&"
export * from "./questions.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HeaderDefault: require('/home/runner/work/3df/3df/components/Header/HeaderDefault/HeaderDefault.vue').default,CardCollapse: require('/home/runner/work/3df/3df/components/Card/CardCollapse/CardCollapse.vue').default,Banner: require('/home/runner/work/3df/3df/components/Banner/Banner.vue').default,SectionRegister: require('/home/runner/work/3df/3df/components/Section/SectionRegister/SectionRegister.vue').default})
