import qs from 'qs'
export const getAcademyNewestArticles = (axios) => {
  const query = qs.stringify(
    {
      filters: {
        types: {
          id: {
            $eq: '2', // related to ACADEMY
          },
        },
        show_on_web: {
          $eq: true,
        },
      },
      sort: ['publish_date:desc'],
      pagination: {
        page: 1,
        pageSize: 3,
      },
      populate: ['cover_image'],
    },
    {
      encodeValuesOnly: true,
    }
  )
  return axios.get(`/api/clanky?${query}`)
}
export const getAcademyArticlesByPage = (axios, page) => {
  const query = qs.stringify(
    {
      filters: {
        types: {
          id: {
            $eq: '2', // related to ACADEMY
          },
        },
        show_on_web: {
          $eq: true,
        },
      },
      sort: ['publish_date:desc'],
      pagination: {
        page,
        pageSize: 3,
      },
      populate: [
        'cover_image'
      ],
    },
    {
      encodeValuesOnly: true,
    }
  )
  return axios.get(`/api/clanky?${query}`)
}

export const getAcademyArticleBySlug = (axios, slug) => {
  const query = qs.stringify(
    {
      populate: [
        'serp',
        'social_sharing',
        'social_sharing.social_image',
        'cover_image',
        'layout',
        'layout.file',
        'layout.image_left',
        'layout.image_right',
        'layout.numbered_blocks',
        'layout.numbered_blocks.image',
        'layout.numbered_blocks.video',
        'layout.image',
        'layout.image_right',
        'layout.image_left',
        'layout.images',
        'author',
        'author.trainer_photo',
        'author.photo_for_video',
        'author.trainers_points',
        'promo_seal'
      ],
      filters: {
        url_alias: {
          $eq: slug,
        },
      },
    },
    {
      encodeValuesOnly: true,
    }
  )
  return axios.get(`/api/clanky/?${query}`)
}
