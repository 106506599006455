
import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  name: 'AcademyClientOnlineCourseDetail',
  layout: 'academy_client',
  props: {
    selected: {
      type: Object,
      default: null
    }
  },
  async asyncData({ app, redirect, route }) {
    try {
      let d
      let cntxt
      if (route.name === 'akademie-moje-online-kurzy-detail-player') {
        d = app.$api.getAcademyOnlineCourseBySlug(route.params.slug)
        cntxt = 'moje-online-kurzy'
      } else {
        d = app.$api.getAcademyLectureBySlug(route.params.slug)
        cntxt = 'moje-skoleni'
      }

      const { data } = await d

      return {
        page: data?.data[0]?.attributes,
        context: cntxt,
        trainers: data?.data[0]?.attributes.trainers?.data,
        program: data?.data[0]?.attributes.program,
        chapters: cntxt === 'moje-online-kurzy' ? data?.data[0]?.attributes?.program?.video : data?.data[0]?.attributes?.program[0]?.video,
        api: process.env.apiUrl,
      }
    }
    catch(e) {
      console.error(e)
      redirect('/')
    }
  },
  data() {
    return {
      selectedCourse: '',
      renderVideo: true,
      thumbnailUrl: null,
      showDetails: false,
    }
  },
  async fetch() {
    if (this.url?.includes('vimeo')) {
      const { data } = await this.$axios.get(`https://vimeo.com/api/oembed.json?url=${this.url}`)
      this.thumbnailUrl = data.thumbnail_url
    }
  },
  head() {
    return this.$seo.generate(
      this.page?.serp,
      this.page?.social_sharing,
      this.page?.online_course_name
    )
  },
  computed: {
    coverImage() {
      if (!this.page || !this.page.cover_image || !this.page.cover_image.data) {
        return null
      }
      return `${this.page.cover_image.data.attributes.url}`
    },
    url() {
      // Transform youtube url into /embed/ version
      if (this.selectedCourse?.url?.includes('youtube') || this.selectedCourse?.url?.includes('youtu.be')) {
        return this.selectedCourse?.url
          .replace('watch?v=', 'embed/')
          .replace('https://youtu.be', 'https://youtube.com/embed')
      }
      if (this.selectedCourse?.url?.includes('vimeo')) {
        return this.selectedCourse?.url
          .replace('https://vimeo.com/', 'https://player.vimeo.com/video/')
      }
      return this.selectedCourse?.url
    },
    goBackDisabled() {
      const index = this.chapters.map(item => item.id).indexOf(this.selectedCourse?.id)
      if (index === 0) {
        return true
      } return false
    },
    goForwardDisabled() {
      const index = this.chapters.map(item => item.id).indexOf(this.selectedCourse?.id)
      if (index < this.chapters.length - 1) {
        return false
      } return true
    }
  },
  watch: {
    selectedCourse() {
      sessionStorage.setItem('selected', JSON.stringify(this.selectedCourse))
    }
  },
  mounted() {
    if (this.selected) {
      sessionStorage.setItem('selected', JSON.stringify(this.selected))
      this.selectedCourse = this.selected
    }
    else if (sessionStorage.getItem('selected')) {
      this.selectedCourse = JSON.parse(sessionStorage.getItem('selected'))
    }
    return null
  },
  methods: {
    goBack() {
      const index = this.chapters.map(item => item.id).indexOf(this.selectedCourse?.id)
      this.selectedCourse = this.chapters[index - 1]
    },
     goForward() {
      const index = this.chapters.map(item => item.id).indexOf(this.selectedCourse?.id)
      this.selectedCourse = this.chapters[index + 1]
    },
    scrollScreen() {
      const header = document.querySelector('.details-page__header')
      const headerHeight = header.offsetHeight
      if (!header && !headerHeight) {
        return
      }
      window.scrollTo({
        top: headerHeight,
        behavior: 'smooth',
      })
    },
    // async forceRender() {
    //   this.$refs.playerContent.classList.add('invisible')
    //   setTimeout(() => {
    //     this.renderVideo = false
    //   }, 200);
    //   await this.$nextTick()
    //   setTimeout(() => {
    //     this.renderVideo = true
    //   }, 200);
    //   setTimeout(() => {
    //     this.$refs.playerContent.classList.remove('invisible')
    //   }, 200);
    // }
  },
})
