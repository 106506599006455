import { render, staticRenderFns } from "./pricing.vue?vue&type=template&id=68b4542a&"
import script from "./pricing.vue?vue&type=script&lang=js&"
export * from "./pricing.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HeaderDefault: require('/home/runner/work/3df/3df/components/Header/HeaderDefault/HeaderDefault.vue').default,CardBig: require('/home/runner/work/3df/3df/components/Card/CardBig/CardBig.vue').default,CardTestimonial: require('/home/runner/work/3df/3df/components/Card/CardTestimonial/CardTestimonial.vue').default,Banner: require('/home/runner/work/3df/3df/components/Banner/Banner.vue').default,SectionRegister: require('/home/runner/work/3df/3df/components/Section/SectionRegister/SectionRegister.vue').default})
