import { render, staticRenderFns } from "./Homepage.vue?vue&type=template&id=64a4a717&"
import script from "./Homepage.vue?vue&type=script&lang=js&"
export * from "./Homepage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HeaderHomepage: require('/home/runner/work/3df/3df/components/Header/HeaderHomepage/HeaderHomepage.vue').default,OverlaySlider: require('/home/runner/work/3df/3df/components/OverlaySlider/OverlaySlider.vue').default,CardArticle: require('/home/runner/work/3df/3df/components/Card/CardArticle/CardArticle.vue').default,Banner: require('/home/runner/work/3df/3df/components/Banner/Banner.vue').default,SectionRegister: require('/home/runner/work/3df/3df/components/Section/SectionRegister/SectionRegister.vue').default})
