// We can't utilize this way of opening modals fully, dsnce we don't have time, 
// nor tests to perform a proper refactor
// 
// That's why you'll see use of both $vfm directly and this mixin at the same time
// 

export default {
  methods: {
    openLoginModal() {
      const modal = this.$isMobile() ? 'login' : 'modal-form'
      this.$vfm.show(modal)
    },
    openRegisterModal() {
      if (this.$isMobile()) {
        this.$vfm.show('register')
      }
      else {
        window.localStorage.setItem('form-state', 'register')
        this.$vfm.show('modal-form')
      }
    }
  }
}
