
export default {
  name: 'AcademyClientCourses',
  layout: 'academy_client',
  async asyncData({ app, redirect, store }) {
    try {
      const [allCourses, user] = await Promise.all([
        app.$api.getAcademyLectures(),
        store.dispatch('user/findMe')
      ])

      return {
        api: process.env.apiUrl,
        allCourses: allCourses.data.data,
        user,
        boughtCourses: user.course_inventory?.filter(course => course?.course_attendance[0]?.has_attended !== true && course.akademie_skoleni?.publishedAt ),
        boughtOnlineCourses: user.online_course_inventory.filter(course => !course.course_certificate_multi?.length && course.online_kurzy?.publishedAt)
      }
    } catch(e) {
      console.error(e)
      redirect('/')
    }
  },
  head() {
    return {
      title: 'Moje školení | 3D Fitness Academy'
    }
  },
  computed: {
    today () {
      const today = new Date()
      let day = today.getDate()
      day = day.toString()
      if (day.length < 2) {
        day = '0' + day
      }
      let month = today.getMonth() + 1
      month = month.toString()
      if (month.length < 2) {
        month = '0' + month
      }
      return today.getFullYear() + '-' + month + '-' + day
    },
    cardInstances() {
      const cards = []
      this.allCourses.forEach((course) => {
        if (course.attributes.dates) {
          course.attributes?.dates?.date.forEach((d) => {
          cards.push({course, date: d})
          })
        } else {
          return null
        }

      })
      return cards
    },
    filteredCourses() {
      let sortedCards = [...this.cardInstances]
      sortedCards = sortedCards.filter(date => date.date?.date > this.today)
      sortedCards = sortedCards.sort(function (a, b) {
        return (Date.parse(a.date?.date) > Date.parse(b.date?.date)) ? 1 : (Date.parse(a.date?.date) < Date.parse(b.date?.date)) ? -1 : 0;
      })
      sortedCards = sortedCards.slice(0, 3)
      return sortedCards
    },
    filteredBoughtCourses() {
      let boughtCourses = this.boughtCourses?.slice()
      boughtCourses = boughtCourses?.sort(function (a, b) {
        return (Date.parse(a.course_attendance[0]?.occurance_date) > Date.parse(b.course_attendance[0]?.occurance_date)) ? 1 : (Date.parse(a.course_attendance[0]?.occurance_date) < Date.parse(b.course_attendance[0]?.occurance_date)) ? -1 : 0;
      })
      return boughtCourses
    },
    attendedCourses() {
      let allAttended = []
      const attended = this.user.course_inventory?.filter(course => course?.course_attendance[0]?.has_attended === true && course.akademie_skoleni?.publishedAt )
      const onlineAttended = this.user.online_course_inventory?.filter(course => course?.course_certificate_multi?.length && course.online_kurzy?.publishedAt)
      allAttended = [...attended, ...onlineAttended]
      return allAttended
    }
  },
}
