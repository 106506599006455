import { render, staticRenderFns } from "./_detail.vue?vue&type=template&id=46393fc1&"
import script from "./_detail.vue?vue&type=script&lang=js&"
export * from "./_detail.vue?vue&type=script&lang=js&"
import style0 from "./_detail.vue?vue&type=style&index=0&id=46393fc1&prod&lang=postcss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SectionClientCourseBought: require('/home/runner/work/3df/3df/components/Section/SectionClientCourseBought/SectionClientCourseBought.vue').default,SectionClientCourseCompleted: require('/home/runner/work/3df/3df/components/Section/SectionClientCourseCompleted/SectionClientCourseCompleted.vue').default,ConstructorLayout: require('/home/runner/work/3df/3df/components/Constructor/ConstructorLayout.vue').default,CardProfile: require('/home/runner/work/3df/3df/components/Card/CardProfile/CardProfile.vue').default,ConstructorTrainerDetails: require('/home/runner/work/3df/3df/components/Constructor/ConstructorTrainerDetails.vue').default})
