import qs from 'qs'

export const getAcademyAcademy = (axios) => {
  const query = qs.stringify(
    {
      populate: [
        'cover_image',
        'serp',
        'promo_video',
        'promo_video.video',
        'promo_course',
        'promo_course.image_top',
        'promo_course.image_middle',
        'promo_course.image_bottom',
        'hover_columns',
        'hover_columns.image',
        'promo_course_02',
        'promo_course_02.image_top',
        'promo_course_02.image_middle',
        'promo_course_02.image_bottom'
      ],
    },
    {
      encodeValuesOnly: true,
    }
  )

  return axios.get(`/api/akademie?${query.trim()}`)
}
