
export default {
  props: {
    trainer: {
      type: Object,
      required: true,
    },
    points: {
      type: Array,
      required: true,
    },
    bio: {
      type: String,
      required: true,
    },
    link: {
      type: String,
      default: 'trainers-detail'
    }
  },
}
