export const state = () => ({
  academy_testimonials: [],
})

export const mutations = {
  getAcademyTestimonials(state, payload) {
    state.academy_testimonials = payload
  },
}

export const actions = {
  async getAcademyTestimonials({ commit }) {
    const { data } = await this.$api.getAcademyTestimonials()
    commit('getAcademyTestimonials', data.data)
  },
}
