
export default {
  name: 'Personal',
  async asyncData({ app }) {
    try {
      const { data } = await app.$api.getPersonalTrainingPage()

      return {
        page: data.data.attributes,
      }
    } 
    catch(e) {
      console.error(e)  
    }
  },
  head() {
    return this.$seo.generate(
      this.page?.serp,
      this.page?.social_sharing,
      this.page?.page_title_h1
    )
  },
  computed: {
    coverImg() {
      if (!this.page?.cover_image?.data) {
        return ''
      }
      return this.page.cover_image.data.attributes.url
    },
  },
}
