export const getQuestionsPage = (axios) => {
  const populate = [
    'serp',
    'social_sharing',
    'social_sharing.social_image',
    'cover_image',
    'question_answer_block',
  ]
  const query = `
    populate=${populate.join(',')}
    `
  return axios.get(`/api/gym-otazky-a-odpovedi?${query.trim()}`)
}
