export const state = () => ({
  testimonials: null,
})

export const mutations = {
  setTestimonials(state, payload) {
    state.testimonials = payload
  },
}

export const actions = {
  async fetchTestimonials({ commit }) {
    const { data } = await this.$api.getTestimonials()
    commit('setTestimonials', data.data)
  },

  async getTestimonials({ state, dispatch }) {
    if (!state.testimonials) {
      await dispatch('fetchTestimonials')
    }

    return state.testimonials
  },
}
