
export default {
  name: 'Gallery',
  props: {
    images: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    index: null,
    api: process.env.apiUrl,
  }),
  computed: {
    previewImages() {
      const res = []
      if (!this.images || !this.images.length) return res
      this.images.forEach((img) => {
        res.push(img.attributes?.url)
      })
      return res
    },
    formattedImages() {
      const res = []
      if (!this.images || !this.images.length) return res
      this.images.forEach((img) => {
        res.push('/_ipx/f_jpeg,w_1440/strapi' + img.attributes?.url)
      })
      return res
    },
  }
}
