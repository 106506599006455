
export default {
  layout: 'academy_default',
  data() {
    return {
      title: this.$route.query.title,
      date: this.$route.query.date,
      location: this.$route.query.location,
    }
  },
  head() {
    return {
      title: 'Zápis na školení | 3D Fitness Academy'
    }
  },
  methods: {
    formatDate(date) {
      const options = { year: 'numeric', month: '2-digit', day: '2-digit' }
      return new Date(date).toLocaleDateString('cs-CS', options)
    },
  }
}
