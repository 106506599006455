
import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  name: 'CardClient',
  props: {
    user: {
      type: Object,
      required: true,
    }
  },
  data() {
    const { profile_image } = this.user

    return {
      avatar: false,
      avatarData: {},
      avatarUrl: profile_image && `/_ipx/f_jpeg,s_400x400/strapi${profile_image.url}`
    }
  },
  beforeMount() {
    this.avatarData.refId = this.user.id
    this.avatarData.field = 'image'
    this.avatarData.ref = 'user'
  },
  methods: {
    uploadAvatar() {
      this.$refs.avatarUpload.click()
    },
    async onFileChange(e) {
      const files = e.target.files || e.dataTransfer.files
      if (!files.length) return

      const formData = new FormData()
      formData.append('files', files[0], `avatar_${this.user.firstname.toLowerCase()}.jpg`)
      formData.append('refId', `${this.user.id}`)
      formData.append('ref', 'plugin::users-permissions.user')
      formData.append('field', 'profile_image')

      try {
        // We need to clear it first because Strapi doesn't update the profile_image 
        // field if there already is an image assigned
        if (this.user.profile_image) {
          await this.$store.dispatch('user/updateMe', { profile_image: null })
        }

        const { data } = await this.$axios.post(`/api/upload`, formData)
        
        if (data && data.length) {
          this.avatarUrl = `/_ipx/f_jpeg,s_400x400/strapi${data[0].url}`
        }
      } catch (e) {
        console.error(e)
      }
    },
  }
})
