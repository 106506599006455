const authFactory = (store, $cookies) => ({
  isAuthenticated: () => {
    if (!store.state.user.user) {
      return false
    }
    
    if (store.state.user.user?.sent) {
      return true
    }
    if (store.state.user.user?.jwt) {
      return true
    }
    if ($cookies.get('auth-temp')?.token) {
      return true
    }
    return false
  },
  isInitial: () => {
    return store.getters['user/initialRegistration']
  },
})

export default ({ store, app }, inject) => {
  const auth = authFactory(store, app.$cookies)
  inject('auth', auth)
}
