
import { defineComponent } from "@nuxtjs/composition-api";

export default defineComponent({
  name: 'PaymentBought',
  props: {
    selected: {
      type: Object,
      default: null
    }
  },
  methods: {
    formatDate(date) {
      const options = { year: 'numeric', month: '2-digit', day: '2-digit' }
      return new Date(date).toLocaleDateString('cs-CS', options)
    },
  }
})
