
import PaymentNotSelected from '~/components/AcademyPaymentOverview/PaymentNotSelected.vue'
import PaymentOverview from '~/components/AcademyPaymentOverview/PaymentOverview.vue'
import PaymentBought from '~/components/AcademyPaymentOverview/PaymentBought.vue'

export default {
  name: 'AcademyClientPaymentOverview',
  components: {
    PaymentNotSelected,
    PaymentOverview,
    PaymentBought,
  },
  layout: 'academy_client',
  props: {
    dates: {
      type: Array,
      default: null
    },
    price: {
      type: Number,
      default: null
    },
    courseName: {
      type: String,
      default: null
    },
    courseSlug: {
      type: String,
      default: null
    },
    selected: {
      type: Object,
      default: null
    }
  },
  head() {
    return {
      title: 'Zápis na školení | 3D Fitness Academy'
    }
  },
  methods: {
    handlePaymentSubmit({ user, invoice }) {
      try {
        this.$store.dispatch('payment/initiatePayment', {
          user,
          invoice,
          successRoute: `/zapis/uspesne-zakoupeno`,
          cancelRoute: `/zapis/platba-se-nezdarila`,
          course: {
            price: `${this.price}00`,
            title: this.courseName,
            slug: this.courseSlug,
            date: this.selected.date,
            location: this.selected.location,        
          }
        })
      }
      catch(e) {
        console.error(e)
      }
    }
  }
}

