
export default {
  name: 'CardAuthor',
  props: {
    content: {
      type: Object,
      required: true,
    },
    link: {
      type: String,
      required: true,
    },
    bio: {
      type: String,
      required: true
    }
  },
}
