export const state = () => ({
  image: null,
})

export const mutations = {
  getImage(state, payload) {
    state.image = payload
  },
}

export const actions = {
  async getImage({ commit, rootState }) {
    if (this.$cookies.get('popup')) {
      return null
    }
    const { data } = await this.$axios.get(`/api/${rootState.siteContext}-popup?populate=image`)
    this.$cookies.set('popup', data.data)
    commit('getImage', data.data)
  },
}
