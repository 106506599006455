
import { defineComponent } from '@nuxtjs/composition-api'
import { collectionBySiteContextFilter } from '~/helpers/filters'

export default defineComponent({
  name: 'AcademyLecturesDetail',
  layout: 'academy_default',
  async asyncData({ app, store, redirect, route }) {
    try {
      const { data } = await app.$api.getAcademyLectureBySlug(route.params.slug)
      const byContextFilter = collectionBySiteContextFilter(store.getters.siteContextId)
      const page = data?.data[0]?.attributes

      return {
        data: data?.data[0],
        page,
        dates: page.dates,
        trainers: page.trainers?.data,
        references: page.references?.data.filter(byContextFilter),
        souvisejici_skolenis: page.souvisejici_skolenis?.data,
        program: page.program[0],
        api: process.env.apiUrl,
      }
    }
    catch(e) {
      console.error(e)
      redirect('/')
    }
  },
  data() {
    return {
      bought: false,
      tl: '',
    }
  },
  head() {
    return this.$seo.generate(
      this.page?.serp,
      this.page?.social_sharing,
      this.page?.course_name
    )
  },
  // metaInfo() {
  //   const page = this.page
  //   console.log(page)
  //   console.log(page?.serp?.serp_description_meta)
  //   console.log(page?.social_sharing?.social_description)
  //   return {
  //     title: page?.serp?.serp_title + ' | ' + this.$store.getters.siteSettings.site_name,
  //     meta: [
  //       {
  //         hid: 'description',
  //         name: 'description',
  //         content: page?.serp?.serp_description_meta,
  //       },
  //       {
  //         hid: 'og:title',
  //         property: 'og:title',
  //         content: page?.social_sharing?.social_title,
  //       },
  //       {
  //         property: 'twitter:title',
  //         content: page?.social_sharing?.social_title,
  //       },
  //       {
  //         hid: 'og:description',
  //         property: 'og:description',
  //         content:page?.social_sharing?.social_description,
  //       },
  //       {
  //         property: 'twitter:description',
  //         content: page?.social_sharing?.social_description,
  //       },
  //       {
  //         hid: 'og:image',
  //         property: 'og:image',
  //         content: this.api + page?.social_sharing?.social_image.data.attributes.url,
  //       },
  //       {
  //         property: 'twitter:image',
  //         content: this.api + page?.social_sharing?.social_image.data.attributes.url,
  //       },
  //       {
  //         hid: 't-type',
  //         name: 'twitter:card',
  //         content: 'summary_large_image',
  //       }
  //     ]
  //   }
  // },
  computed: {
    coverImage() {
      if (!this.page || !this.page.cover_image || !this.page.cover_image.data) {
        return null
      }
      return `${this.page.cover_image.data.attributes.url}`
    },
  },
  mounted() {
    if (this.$store.getters['user/isLoggedIn'] && this.program) {
      this.getUser()
    } else {
      this.tl = setInterval(() => {
        if (this.$store.getters['user/isLoggedIn'] && this.program) {
          this.getUser()
          clearInterval(this.tl)
        } else {
          clearInterval(this.tl)
        }
      }, 800)
    }
  },
  methods: {
    async getUser() {
      console.log('trigger')
      const user = await this.$store.dispatch('user/findMe')
      if (user.course_inventory.find(course => course.akademie_skoleni.id === this.data?.id)) {
        this.bought = true
      } else {
        this.bought = false
      }
    },
    scrollScreen() {
      const header = document.querySelector('.details-page__header')
      const headerHeight = header.offsetHeight
      if (!header && !headerHeight) {
        return
      }
      window.scrollTo({
        top: headerHeight,
        behavior: 'smooth',
      })
    },
  },
})
