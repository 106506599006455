

export default {
  name: "Select",
  props: {
    placeholder: {
      type: String,
      default: null
    },
    options: {
      type: [Array, Object],
      required: true
    },
  },
  data() {
    return {
      selected: '',
    }
  },
  methods: {
    changeValue() {
      this.$emit("input", this.selected);
    }
  }
}
