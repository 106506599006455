
/**
 * 
 * @param {Array[Number],Number} siteContextIds Single ID or an Array of Ids 
 * @returns 
 */
export const collectionBySiteContextFilter = (siteContextIds) => {
  const ids = Array.isArray(siteContextIds) ? siteContextIds : [siteContextIds]

  return (collection) => {
    const types = collection.attributes.types?.data ?? []

    if (!types || !types.length) {
      return false
    }

    // If any collection.types has ID === siteContextId
    return types.some(type => ids.includes(type.id))
  }
}