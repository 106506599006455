
import { defineComponent } from '@nuxtjs/composition-api'
import emailValidator from "email-validator"
import CollapseTransition from '@ivanv/vue-collapse-transition/src/CollapseTransition.vue'
import UploadProfileImage from '~/components/Card/CardClient/UploadProfileImage.vue'

const CountryNameByCode = {
  'cz': 'Česká republika',
  'sk': 'Slovensko',
}

const CountryCodeByName = {
  'Česká republika': 'cz',
  'Slovensko': 'sk',
}

export default defineComponent({
  name: 'SectionClientProfile',
  components: {
    CollapseTransition,
    UploadProfileImage,
  },
  props: {
    user: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      passwordIsOpen: false,
      billingIsOpen: false,
      firstname: this.user.firstname ?? '',
      lastname: this.user.lastname ?? '',
      email: this.user.email,
      phone: this.user.phone ?? '',
      companyname: this.user.company_name,
      address: this.user.company_address_street,
      city: this.user.company_address_city,
      zip: this.user.company_address_zip,
      companyID: this.user.company_id,
      taxID: this.user.company_vat_id,
      company_address_country: CountryNameByCode[this.user.company_address_country],
      validate: false,
      loading: false,
      errorUserExists: false,
      onSubmit: this.defaultOnSubmit,
      password: '',
      passwordConfirm: '',
    }
  },
  computed: {
    firstnameError() { return this.validate && this.firstname.length < 2 },
    lastnameError() { return this.validate && this.lastname.length < 2 },
    emailError() { return this.errorUserExists || (this.validate && !emailValidator.validate(this.email)) },
    isFormValid() { return !this.passwordError && !this.confirmError && !this.equalityError },
    passwordError() { return this.validate && this.password.length < 8 },
    confirmError() { return this.validate && this.passwordConfirm === '' },
    equalityError() { return this.validate && !this.passwordError && !this.confirmError && this.password !== this.passwordConfirm },
  },
  watch: {
    email() {
      this.errorUserExists = false // reset when user changes email input
    }
  },
  methods: {
    toggleBilling() {
      this.billingIsOpen = !this.billingIsOpen
    },
    togglePassword() {
      this.passwordIsOpen = !this.passwordIsOpen
    },
    async defaultOnSubmit(password, _passwordConfirm) {
      await this.$store
        .dispatch('user/setPassword', password)
        .catch(console.error)
    },
    handleSubmit() {
      this.validate = true
      
      if (this.isFormValid) {
        this.onSubmit(this.password, this.passwordConfirm)
            .then(() => {
              this.$vfm.show('confirm')
              this.canClose = true
            })
      }
    },
    async changeValue() {
      console.log('change')
      this.validate = true
      if ( !this.firstnameError && !this.lastnameError && !this.emailError ) {
        try {
          await this.$store.dispatch('user/updateMe', {
            firstname: this.firstname,
            lastname: this.lastname,
            email: this.email,
            phone: this.phone,
            company_name: this.companyname,
            company_address_street: this.address,
            company_address_city: this.city,
            company_address_zip: this.zip,
            company_id: this.companyID,
            company_vat_id: this.taxID,
            company_address_country: CountryCodeByName[this.company_address_country]
          })

          this.$vfm.show('changed')
          setTimeout(() => {
            this.$vfm.hide('changed')
          }, 2500);
          this.validate = false
        } catch (e) {
          console.log(e)
        } 
      } return null
    }
  },
})
