import { render, staticRenderFns } from "./academy_in-house.vue?vue&type=template&id=3d1d6342&"
import script from "./academy_in-house.vue?vue&type=script&lang=js&"
export * from "./academy_in-house.vue?vue&type=script&lang=js&"
import style0 from "./academy_in-house.vue?vue&type=style&index=0&id=3d1d6342&prod&lang=postcss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HeaderDefault: require('/home/runner/work/3df/3df/components/Header/HeaderDefault/HeaderDefault.vue').default,BlockContent: require('/home/runner/work/3df/3df/components/Block/BlockContent/BlockContent.vue').default,AcademySectionRegister: require('/home/runner/work/3df/3df/components/AcademySectionRegister/AcademySectionRegister.vue').default})
