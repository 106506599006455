import { render, staticRenderFns } from "./Nav.vue?vue&type=template&id=0084f5f9&"
import script from "./Nav.vue?vue&type=script&lang=js&"
export * from "./Nav.vue?vue&type=script&lang=js&"
import style0 from "./Nav.vue?vue&type=style&index=0&id=0084f5f9&prod&lang=postcss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconMenu: require('/home/runner/work/3df/3df/components/Icon/IconMenu.vue').default,Nav: require('/home/runner/work/3df/3df/components/Nav/Nav.vue').default})
