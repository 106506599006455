import qs from 'qs'

export const getPersonalTrainingPage = (axios) => {
  const query = qs.stringify(
    {
      populate: [
        'cover_image',
        'serp',
        'social_sharing',
        'yellow_block1',
        'yellow_block1.image',
        'yellow_block2',
        'yellow_block2.image',
        'yellow_block3',
        'yellow_block3.image',
      ],
    },
    {
      encodeValuesOnly: true,
    }
  )

  return axios.get(`/api/gym-osobni-trenink?${query.trim()}`)
}
