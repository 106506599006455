import { render, staticRenderFns } from "./CardAuthor.vue?vue&type=template&id=00161413&"
import script from "./CardAuthor.vue?vue&type=script&lang=js&"
export * from "./CardAuthor.vue?vue&type=script&lang=js&"
import style0 from "./CardAuthor.vue?vue&type=style&index=0&id=00161413&prod&lang=postcss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CardProfile: require('/home/runner/work/3df/3df/components/Card/CardProfile/CardProfile.vue').default})
