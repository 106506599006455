
import { collectionBySiteContextFilter } from '~/helpers/filters'

export default {
  name: 'Trainer',
  async asyncData({ app, store, route, redirect }) {
    try {
      const { slug } = route.params
      const trainer = await app.$api.getTrainerBySlug(slug)

      const { clankies } = trainer.data.data[0].attributes
      const clankiesFilter = collectionBySiteContextFilter([store.getters.siteContextId, 3])
      trainer.data.data[0].attributes.clankies.data = clankies.data.filter(clankiesFilter)

      return { trainer: trainer.data.data[0].attributes, }
    } catch(e) {
      console.error(e)
      redirect('/')
    }
  },
  head() {
    return this.$seo.generate(
      this.page?.serp,
      this.page?.social_sharing,
      this.trainer?.full_name
    )
  },
  computed: {
    generatedImage() {
      if (!this.trainer?.trainer_photo?.data?.attributes?.url) {
        return ''
      }
      return this.trainer.trainer_photo.data.attributes.url
    },
    sortedArticles() {
      const articles = this.trainer.clankies.data
      return articles.sort(function(a,b) {
        return new Date(b.attributes.publish_date) - new Date(a.attributes.publish_date);
      })
    }
  },
}
