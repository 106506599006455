
import gsap from 'gsap'
// import { gsap } from 'gsap/dist/gsap'
// import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
import { defineComponent, ref, computed } from '@nuxtjs/composition-api'

// gsap.registerPlugin(ScrollTrigger)

export default defineComponent({
  name: 'BlockImage',
  props: {
    content: {
      type: Object,
      default: () => ({}),
    },
  },
  setup({ content }) {
    const api = ref(`${process.env.apiUrl}`)
    const image = computed(() => {
      return `${content?.image?.data?.attributes?.url}`
    })
    return { api, image }
  },
  computed: {
    isMobile() {
      return process.client ? window.innerWidth < 768 : true
    }
  },
  mounted() {
    if(!this.isMobile) {
      this.animateOnLoad()
    }
  },
  methods: {
    animateOnLoad() {
      gsap.fromTo('.block-image__image', {
        translateY: '0%'
      },
      {
        translateY: '-50%',
        duration: 0.8,
        ease: 'ease.in',
        delay: 0.2,
      })
    }
  }
})
