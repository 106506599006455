import qs from 'qs'

export const getPersonalDataAgreement = (axios) => {
  const query = qs.stringify(
    {
      populate: [
        'layout',
        'layout.image',
        'layout.image_right',
        'layout.image_left',
        'cover_image',
        'layout.file',
        'layout.numbered_blocks',
        'layout.numbered_blocks.image',
        'layout.numbered_blocks.video',
        'layout.images',
      ],
    },
    {
      encodeValuesOnly: true,
    }
  )
  return axios.get(`/api/gym-zpracovani-osobnich-udaju?${query}`)
}

export const getAcademyPersonalDataAgreement = (axios) => {
  const query = qs.stringify(
    {
      populate: [
        'layout',
        'layout.image',
        'layout.image_right',
        'layout.image_left',
        'cover_image',
        'layout.file',
        'layout.numbered_blocks',
        'layout.numbered_blocks.image',
        'layout.numbered_blocks.video',
        'layout.images',
      ],
    },
    {
      encodeValuesOnly: true,
    }
  )
  return axios.get(`/api/akademie-zpracovani-osobnich-udaju?${query}`)
}

export const getAcademyBusinessConditions = (axios) => {
  const query = qs.stringify(
    {
      populate: [
        'layout',
        'layout.image',
        'layout.image_right',
        'layout.image_left',
        'cover_image',
        'layout.file',
        'layout.numbered_blocks',
        'layout.numbered_blocks.image',
        'layout.numbered_blocks.video',
        'layout.images',
      ],
    },
    {
      encodeValuesOnly: true,
    }
  )
  return axios.get(`/api/akademie-vseobecne-obchodni-podminky?${query}`)
}