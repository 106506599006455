
import { defineComponent } from '@nuxtjs/composition-api'
import { useForm } from '~/composables/form'
import validators from '~/helpers/validators'

export default defineComponent({
  name: 'AcademySectionRegister',
  setup() {
    return {
      form: useForm([
        { name: 'firstname', validator: validators.firstName },
        { name: 'email',    validator: validators.email },
        { name: 'gdpr',     validator: validators.gdpr },
      ])
    }
  },
  methods: {
    async register() {
      this.form.enableValidation()
      if (!this.form.isValid()) {
        return
      }

      try {
        this.form.disableValidation()

        const res = await this.$store.dispatch('user/register', {
          email: this.form.fields.email.value,
          username: this.form.fields.email.value,
          context: {
            firstname: this.form.fields.firstname.value
          }
        })

        if (res.email) {
          this.form.fields.email.value = ''
          this.form.fields.firstname.value = ''
          this.form.fields.gdpr.value = false

          this.$vfm.show('form-set')
        }
      } catch (e) {
        await console.log(e)
      }
    },
  },
})
