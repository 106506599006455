
import { defineComponent } from '@nuxtjs/composition-api'
import CardProfile from '~/components/Card/CardProfile/CardProfile'
import ConstructorTrainerDetails from '~/components/Constructor/ConstructorTrainerDetails'

export default defineComponent({
  name: 'LessonDetail',
  components: { ConstructorTrainerDetails, CardProfile },
  async asyncData({ app, redirect, route }) {
    const { data } = await app.$api.getLessonBySlug(route.params.slug)
    if (!data) {
      redirect('/')
    }

    return {
      page: data?.data[0]?.attributes,
      trainers: data?.data[0]?.attributes?.trainers?.data,
      api: process.env.apiUrl,
      // lessons: [],
    }
  },
  // async fetch() {
  //   const { data } = await this.$api.getGroupLessons()
  //   this.lessons = data?.data?.attributes?.price_cards || []
  // },
  head() {
    return this.$seo.generate(
      this.page?.serp,
      this.page?.social_sharing,
      this.page?.lesson_name
    )
  },
  computed: {
    coverImage() {
      if (!this.page || !this.page.cover_image || !this.page.cover_image.data) {
        return null
      }
      return `${this.page.cover_image.data.attributes.url}`
    },
    isReservationHidden() {
      // Hide reservation for a particular lesson (https://www.notion.so/mediaform/c863bd74eb9d4ee0a8ccaf61610bfdc9?v=3f66c3a5047641c391e21b36a767cb82&p=3964867028444fe798c7a8c51c87a658&pm=s)
      if (this.$route.params.slug === 'deti-v-akci') {
        return true
      }

      return false
    },
  },
  methods: {
    scrollScreen() {
      const header = document.querySelector('.details-page__header')
      const headerHeight = header.offsetHeight
      if (!header && !headerHeight) {
        return
      }
      window.scrollTo({
        top: headerHeight,
        behavior: 'smooth',
      })
    },
  },
})
