import { computed, ref } from 'vue'

export function useFormField(shouldValidate, validateFn) {
  const field = ref('')
  const error = computed(() => shouldValidate.value && !validateFn(field.value))

  return [field, error]
}

/**
 * 
 * @param {Object[]} fieldsDef
 */
export function useForm(fieldsDef) {
  const shouldValidate = ref(false)
  const errors = ref({})
  const fields = {}

  fieldsDef.forEach(({ name, validator }) => {
    const defaultValidator = () => true
    const [ field, error ] = useFormField(shouldValidate, validator ?? defaultValidator)

    fields[name] = field
    errors[name] = error
  })

  function enableValidation() {
    shouldValidate.value = true
  }

  function disableValidation() {
    shouldValidate.value = false
  }

  function isValid() {
    return !Object.values(errors).some(err => err.value)
  }

  function json() {
    return Object
            .entries(fields)
            .reduce((acc, [name, { value }]) => {
              acc[name] = value
              return acc
            }, {})
  }

  return {
    enableValidation,
    disableValidation,
    isValid,
    fields,
    errors,
    json,
  }
}
