// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/images/backgrounds/banner-logo-bg.jpg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".banner-logo{min-height:596px;display:flex;align-items:center;justify-content:center;padding:10vw;position:relative;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat 50%/cover;}.banner-logo:after{z-index:1;content:\"\";position:absolute;top:0;left:0;width:100%;height:100%;background:var(--overlay-small)}.banner-logo svg{position:relative;z-index:2;width:40vw}@media screen and (max-width:768px){.banner-logo{min-height:288px}}.academy-banner-logo{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat 50%/cover}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
