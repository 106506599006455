
export default {
  name: 'CardSchedule',
  props: {
    content: {
      type: Object,
      default: () => ({
        title: 'Course title',
        trainer: 'Instructor Name',
        time: '7.00',
        end_time: '8.00',
        url: '#',
      }),
    },
  },
  computed: {
    cardTitle() {
      return this.content?.gym_lesson?.data?.attributes?.lesson_name || ''
    },
  },
  methods: {
    getTime(time) {
      if (time && time.length > 3) {
        return time.slice(0, -3)
      }
      return time
    },
  },
}
