
import { defineComponent, ref } from '@nuxtjs/composition-api'
import emailValidator from "email-validator"

export default defineComponent({
  setup() {
    const state = ref('login')
    const beforeOpen = () => {
      const stored = window.localStorage.getItem('form-state')
      state.value = 'login'
      if (stored === 'register') {
        switchState('register')
        state.value = 'register'
        window.localStorage.removeItem('form-state')
      }
    }
    const switchState = (newState) => {
      startAnimation(newState)
    }
    function startAnimation(newState) {
      if (newState === 'register' || newState === 'login') {
        setTimeout(() => {
          state.value = newState
        }, 100)
      }
      if (
        newState === 'register-success' ||
        newState === 'forgot-success' ||
        newState === 'forgot'
      ) {
        state.value = newState
      }
    }

    return {
      state,
      switchState,
      beforeOpen,
    }
  },
  data: () => ({
    email: '',
    password: '',
    validate: false,
    loading: false,
    errorUserExists: false,
    loginErrorCode: null,
  }),
  computed: {
    usernameError() { return this.validate && this.username === "" },
    emailError() { return this.errorUserExists || (this.validate && !emailValidator.validate(this.email)) },
    gdprError() { return this.validate && !this.gdpr },
    passwordError() { return this.validate && (this.password === "" || !!this.loginErrorCode) }
  },
  watch: {
    email() {
      this.errorUserExists = false // reset when user changes email input
    },
  },
  methods: {
    validateLogin() {
      this.validate = true
      return !this.emailError && !this.passwordError
    },
    async login() {
      if (!this.validateLogin()) {
        return
      }
      
      this.loading = true
      
      const success = await this.$store.dispatch('user/login', {
        email: this.email,
        password: this.password,
      })

      if (success) {
       this.$auth.isAuthenticated()
      } else {
       this.loginErrorCode = this.$store.state.user.error.statusCode
      }

      this.loading = false
    },
  },
})
