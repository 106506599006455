export const state = () => ({
  menuIsVisible: false,
})

export const mutations = {
  switch(state) {
    state.menuIsVisible = !state.menuIsVisible
  },
  setVisible(state, visible) {
    state.menuIsVisible = visible
  }
}

export const actions = {
  closeMenu({ commit }) {
    commit('setVisible', false)
  }
}
