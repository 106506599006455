
import { format } from 'date-fns'
export default {
  props: {
    content: {
      type: Object,
      required: true,
    },
    link: {
      type: String,
      default: null,
    },
  },
  computed: {
    dateFormatted() {
      if (!this.content?.publish_date) return ''
      try {
        return format(new Date(this.content?.publish_date), 'd. M. y')
      } catch {
        return this.content?.publish_date | ''
      }
    },
    generatedImage() {
      return this.content?.cover_image?.data?.attributes?.url
    },
  },
}
