
import gsap from 'gsap'
// import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
import { defineComponent, ref, computed } from '@nuxtjs/composition-api'

// gsap.registerPlugin(ScrollTrigger)

export default defineComponent({
  name: 'BannerPromoCourse',
  props: {
    content: {
      type: Object,
      default: () => ({}),
    },
  },
  setup({ content }) {
    const api = ref(`${process.env.apiUrl}`)
    const imageTop= computed(() => {
      return `${content?.image_top?.data?.attributes?.url}`
    })
    const imageMiddle = computed(() => {
      return `${content?.image_middle?.data?.attributes?.url}`
    })
    const imageBottom = computed(() => {
      return `${content?.image_bottom?.data?.attributes?.url}`
    })
    return { api, imageTop, imageMiddle, imageBottom }
  },
  mounted() {
    this.animateOnScroll1()
    if (this.content.images_on_right) {
      this.animateOnScroll2()
    }
  },
  beforeDestroy() {
    if (this.tlBlack) this.tlBlack.kill()
    if (this.tlYellow) this.tlYellow.kill()
    if (this.tlBlack2) this.tlBlack2.kill()
    if (this.tlYellow2) this.tlYellow2.kill()

    this.tlBlack = null
    this.tlYellow = null
    this.tlBlack2 = null
    this.tlYellow2 = null
  },
  methods: {
    animateOnScroll1() {
      this.tlBlack = gsap.timeline({
        scrollTrigger: {
          trigger: '.banner-promo-course__inner.normal',
          start: '-30%',
          end: '95%',
          onEnter: () => this.tlBlack.play(),
          onLeave: () => this.tlBlack.reverse().delay(0.2),
          onEnterBack: () => this.tlBlack.play().delay(0.2),
          onLeaveBack: () => this.tlBlack.reverse().delay(0.2)
        }
      })
      this.tlYellow = gsap.timeline({
        scrollTrigger: {
          trigger: '.banner-promo-course__inner.normal',
          start: '-30%',
          end: '95%',
          toggleActions: 'play reverse play reverse'
        }
      })
      this.tlYellow.fromTo('.normal .animate-text', {
        y: 20,
        opacity: 0,
      },
      {
        y: 0,
        opacity: 1,
        duration: 0.5,
        stagger: 0.1,
        ease: 'easeIn',
      }, '<')

      this.tlYellow.to('.normal .banner-promo-course__image--before', {
        width: '0%',
        duration: 1,
        ease: 'expo.inOut',
        stagger: 0.2
      }, '<')

      this.tlBlack.to('.normal .banner-promo-course__image--after', {
        width: '0%',
        duration: 0.8,
        ease: 'expo.inOut',
        delay: 0.2,
        stagger: 0.2
      })
    },
    animateOnScroll2() {
      this.tlBlack2 = gsap.timeline({
        scrollTrigger: {
          trigger: '.banner-promo-course__inner.flipped',
          start: '-30%',
          end: '95%',
          onEnter: () => this.tlBlack2.play(),
          onLeave: () => this.tlBlack2.reverse().delay(0.2),
          onEnterBack: () => this.tlBlack2.play().delay(0.2),
          onLeaveBack: () => this.tlBlack2.reverse().delay(0.2)
        }
      })
      this.tlYellow2 = gsap.timeline({
        scrollTrigger: {
          trigger: '.banner-promo-course__inner.flipped',
          start: '-30%',
          end: '95%',
          toggleActions: 'play reverse play reverse'
        }
      })
      this.tlYellow2.fromTo('.flipped .animate-text', {
        y: 20,
        opacity: 0,
      },
      {
        y: 0,
        opacity: 1,
        duration: 0.5,
        stagger: 0.1,
        ease: 'easeIn',
      }, '<')

      this.tlYellow2.to('.flipped .banner-promo-course__image--before', {
        width: '0%',
        duration: 1,
        ease: 'expo.inOut',
        stagger: 0.2
      }, '<')

      this.tlBlack2.to('.flipped .banner-promo-course__image--after', {
        width: '0%',
        duration: 0.8,
        ease: 'expo.inOut',
        delay: 0.2,
        stagger: 0.2
      })
    },
  }
})
