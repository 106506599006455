
import AcademyHeaderContacts from '~/components/Header/HeaderContancts/AcademyHeaderContacts/AcademyHeaderContacts.vue'
export default {
  name: 'AcademyContact',
  components: {
    AcademyHeaderContacts,
  },
  layout: 'academy_default',
  async asyncData({ app, redirect }) {
    try {
      const { data } = await app.$api.getAcademyContact()
      return {
        page: data.data.attributes,
        promoVideo: data.data.attributes.promo_video?.video.data.attributes,
        api: process.env.apiUrl,
      }
    } catch {
      redirect('/')
    }
  },
  data() {
    return {
      directions: [],
    }
  },
  head() {
    return this.$seo.generate(
      this.page?.serp,
      this.page?.social_sharing,
      this.page?.page_title_h1
    )
  },
  computed: {
    coverImg() {
      if (!this.page?.cover_image?.data) {
        return ''
      }
      return this.page.cover_image.data.attributes.url
    },
  },
  methods: {
    handleDirections(selected) {
      this.$data.directions = selected
    }
  }
}
