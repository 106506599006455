
import IconStar from '../../Icon/IconStar'

export default {
  name: 'CardTestimonial',
  components: { IconStar },
  props: {
    image: {
      type: String,
      default: '',
    },
    author: {
      type: String,
      default: 'Vendula',
    },
    stars: {
      type: Number,
      default: 5,
    },
    text: {
      type: String,
      default:
        '3D FITNESS GYM je silně návykové místo, kam se člověku chce jít v 7 ráno i v 8 večer a je vám líto, když zrovna na tu vaší oblíbenou lekci nemůžete jít. 3DFA pro mě neni fitness centrum (tam bych asi nikdy nechodila), ale kamarádi, na které se pokaždé těším, kteří mě dobijou baterky a spolehlivě dodají dobrou náladu. Super fyzička se postupně stala vedlejším efektem mých pravidelných návštěv. 3DFA pro mě znamená naprosto srdcovou záležitost. ',
    },
  },
  computed: {
    generatedImage() {
      if (!this.image) {
        return ''
      }
      const apiUrl = process.env.apiUrl
      return `${this.image}`
    },
  },
}
