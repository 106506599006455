import Vue from 'vue'

Vue.filter('nl2br', (text) => {
  return linebreakHandler(text)
})

function linebreakHandler(text) {
  const res = nl2br(text)
  return res
}

function nl2br(str, is_xhtml) {
  const breakTag =
    is_xhtml || typeof is_xhtml === 'undefined' ? '<br ' + '/>' : '<br>' // Adjust comment to avoid issue on phpjs.org display
  return (str + '').replace(
    /([^>\r\n]?)(\r\n|\n\r|\r|\n)/g,
    '$1' + breakTag + '$2'
  )
}
