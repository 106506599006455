import { render, staticRenderFns } from "./_article.vue?vue&type=template&id=7f6dcc5c&"
import script from "./_article.vue?vue&type=script&lang=js&"
export * from "./_article.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ConstructorLayout: require('/home/runner/work/3df/3df/components/Constructor/ConstructorLayout.vue').default,CardAuthor: require('/home/runner/work/3df/3df/components/Card/CardAuthor/CardAuthor.vue').default,BannerSeal: require('/home/runner/work/3df/3df/components/Banner/BannerSeal/BannerSeal.vue').default,AcademySectionRegister: require('/home/runner/work/3df/3df/components/AcademySectionRegister/AcademySectionRegister.vue').default})
