import qs from 'qs'

export const getAcademyOnlineCoursesPage = (axios) => {
  const query = qs.stringify(
    {
      populate: [
        'cover_image',
        'serp',
        'yellow_block_body',
        'yellow_block_body.icon',
        'promo_seal',
      ],
    },
    {
      encodeValuesOnly: true,
    }
  )

  return axios.get(`/api/akademie-online-kurz?${query.trim()}`)
}

export const getAcademyOnlineCourses = (axios) => {
  const query = qs.stringify(
    {
      filters: {
        show_on_web: {
          $eq: true,
        },
      },
      populate: [
        'cover_image',
        'serp',
        'social_sharing'
      ],
    },
    {
      encodeValuesOnly: true,
    }
  )
  return axios.get(`/api/online-kurzy?${query}`)
}

export const getAcademyOnlineCourseBySlug = (axios, slug) => {
  const query = qs.stringify(
    {
      populate: [
        'cover_image',
        'layout',
        'layout.file',
        'layout.embed',
        'layout.numbered_blocks',
        'layout.numbered_blocks.image',
        'layout.numbered_blocks.video',
        'layout.image',
        'layout.image_right',
        'layout.image_left',
        'layout.images',
        'trainers',
        'trainers.trainer_photo',
        'trainers.photo_for_video',
        'trainers.trainers_points',
        'related_courses',
        'related_courses.cover_image',
        'program',
        'program.video.file',
        'course_manual',
      ],
      filters: {
        url_alias: {
          $eq: slug,
        },
      },
    },
    {
      encodeValuesOnly: true,
    }
  )
  return axios.get(`/api/online-kurzy/?${query}`)
}
