const seoFactory = (store, serp, social, defaultTitle) => {
  const siteName = store.getters.siteSettings.site_name
  const apiUrl = process.env.apiUrl
  const returnObj = {
    title: defaultTitle ? `${defaultTitle} | ${siteName}` : siteName,
  }
  if (serp && serp.serp_title)
    returnObj.title = serp.serp_title + ' | ' + siteName
  if (serp && serp.serp_description_meta && serp.serp_description_meta !== '') {
    returnObj.meta = []
    returnObj.meta.push({
      hid: 'description',
      name: 'description',
      content: serp.serp_description_meta,
    })
  }
  if (!returnObj.meta) returnObj.meta = []
  if (social && social.social_title) {
    returnObj.meta.push(
      {
        hid: 'og:title',
        property: 'og:title',
        content: social.social_title,
      },
      {
        property: 'twitter:title',
        content: social.social_title,
      }
    )
  }
  if (social && social.social_description) {
    returnObj.meta.push(
      {
        hid: 'og:description',
        property: 'og:description',
        content: social.social_description,
      },
      {
        property: 'twitter:description',
        content: social.social_description,
      }
    )
  }

  if (social && social.social_image && social.social_image.data) {
    returnObj.meta.push(
      {
        hid: 'og:image',
        property: 'og:image',
        content: apiUrl + social.social_image.data.attributes.url,
      },
      {
        property: 'twitter:image',
        content: apiUrl + social.social_image.data.attributes.url,
      },
      {
        hid: 't-type',
        name: 'twitter:card',
        content: 'summary_large_image',
      }
    )
  } else if (
    store.getters.siteSettings.social_sharing_default_img &&
    store.getters.siteSettings.social_sharing_default_img.data
  ) {
    returnObj.meta.push(
      {
        hid: 'og:image',
        property: 'og:image',
        content:
          apiUrl +
          store.getters.siteSettings.social_sharing_default_img.data.attributes
            .url,
      },
      {
        property: 'twitter:image',
        content:
          apiUrl +
          store.getters.siteSettings.social_sharing_default_img.data.attributes
            .url,
      },
      {
        hid: 't-type',
        name: 'twitter:card',
        content: 'summary_large_image',
      }
    )
  }
  return returnObj
}
export default ({ store }, inject) => {
  inject('seo', {
    generate(serp, social, defaultTitle) {
      return seoFactory(store, serp, social, defaultTitle)
    },
  })
}
