
export default {
  name: 'ModalImage',
  data() {
    return {
      isVisible: false,
      src: '',
      tl: '',
    }
  },
  computed: {
    href() {
      if (!this.$store?.state?.popup?.image?.attributes) {
        return '#'
      }
      return this.$store.state.popup.image.attributes.url
    },
  },
  mounted() {
    if (!this.$store?.state?.popup?.image?.attributes) {
      return
    }
    const timer = this.$store.state.popup.image.attributes.time
    const isPublished = this.$store.state.popup.image.attributes.is_published
    const image = this.$store.state.popup.image.attributes.image?.data?.attributes?.url
    if (!timer || !isPublished || !image) {
      return
    }
    this.src = '/_ipx/f_jpg,w_1024/strapi' + image
    this.tl = setTimeout(() => {
      this.isVisible = true
    }, timer)
  },
  beforeDestroy() {
    clearInterval(this.tl)
  }
}
