import qs from 'qs'

export const updateMe = async (axios, token, payload) => {
  return await axios.put(`/api/users/me`,
  payload,
  {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

export const register = (axios, payload, userType) => {
  return axios.post('/api/passwordless/send-link', {
    ...payload,
    context: {
      types: [userType],
      ...payload.context,
    },
  })
}

export const login = (axios, payload) => {
  const query = qs.stringify(
    {
      populate: [
        'types',
      ]
    },
    {
      encodeValuesOnly: true
    }
  )
  return axios.post(`/api/auth/local?${query}`, {
    identifier: payload.email,
    password: payload.password,
  })
}

export const loginWithToken = (axios, loginToken) => {
  return axios.get(`/api/passwordless/login?loginToken=${loginToken}`)
}

export const setPassword = async (axios, { password, token }) => {
  if (!password || !token) {
    throw new Error('Failed to set password. Missing password or token')
  }
  try {
    return await updateMe(axios, token, { password })
  } catch (e) {
    console.log(e.response || e)
  }
}

export const forgotPassword = async (axios, { email }) => {
  return await axios
    .post('/api/auth/forgot-password', { email })
    .catch(console.error)
}

export const resetPassword = async (axios, { code, password, passwordConfirmation }) => {
  return await axios
    .post('/api/auth/reset-password', {
      code, 
      password, 
      passwordConfirmation
    })
    .catch(console.error)
}

// Todo: This should eventually contain basic required data, such as 'types'
//       The rest of those fields should be moved to the logic, where findMe()
//       is called and populate array should be specified there
const DefaultFindMePopulate = [
  'types',
  'profile_image',
  'course_inventory',
  'course_inventory.course_certificate',
  'course_inventory.course_certificate_multi.course_certificate_file',
  'course_inventory.course_invoice',
  'course_inventory.course_attendance',
  'course_inventory.course_attendance.occurance_date',
  'course_inventory.akademie_skoleni',
  'course_inventory.akademie_skoleni.course_manual',
  'course_inventory.akademie_skoleni.cover_image',
  'online_course_inventory',
  'online_course_inventory.online_kurzy',
  'online_course_inventory.online_kurzy.cover_image',
  'online_course_inventory.online_kurzy.course_manual',
  'online_course_inventory.online_course_subscription.course_subscription_from',
  'online_course_inventory.online_course_subscription.course_subscription_to',
  'online_course_inventory.online_course_subscription.course_invoice_url',
  'online_course_inventory.online_course_subscription.course_certificate',
  'online_course_inventory.course_certificate_multi.course_certificate_file',
]

export const findMe = (axios, token, populate = DefaultFindMePopulate) => {
  const query = qs.stringify(
    { populate },
    { encodeValuesOnly: true }
  )

  return axios.get(`/api/users/me?${query}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

export const findUser = (axios, email) => {
  return axios.get(`/api/users?${qs.stringify({
    filters: {
      email: { $eq: email }
    }
  })}`)
}

export const userHasContext = (axios, { identifier, context }) => {
  return axios.get(`/api/user-site-context?identifier=${identifier}&context=${context}`)
}
