import qs from 'qs'

export const getAcademyEventsPage = (axios) => {
  const query = qs.stringify(
    {
      populate: [
        'cover_image',
        'serp',
        'yellow_block',
        'yellow_block.image',
        'promo_video',
        'promo_video.video',
        'photo_gallery',
      ],
    },
    {
      encodeValuesOnly: true,
    }
  )

  return axios.get(`/api/akademie-event?${query.trim()}`)
}

export const getAcademyEvents = (axios) => {
  const query = qs.stringify(
    {
      pagination: {
        page: 1,
        pageSize: 48,
      },
      filters: {
        course_types: {
          id: {
            $eq: '1', // related to EVENT
          },
        },
        show_on_web: {
          $eq: true,
        },
      },
      populate: [
        'cover_image',
        'serp',
        'social_sharing',
        'dates',
        'dates.date',
      ],
    },
    {
      encodeValuesOnly: true,
    }
  )
  return axios.get(`/api/akademie-skolenis?${query}`)
}
