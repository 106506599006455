
import LogoAcademy from "../../Logo/LogoAcademy.vue"

export default ({
  name: "NavCheck",
  components: {
    LogoAcademy,
  },
  data() {
    return {
      checked: false
    }
  }
});
