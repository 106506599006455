
export default {
  name: 'AcademyClientProfile',
  layout: 'academy_client',
  async asyncData({ redirect, store }) {
    try {
      const user = await store.dispatch('user/findMe')

      return {
        api: process.env.apiUrl,
        user,
        boughtCourses: user.course_inventory.filter(course => course.course_attendance[0]?.has_attended === true && course.akademie_skoleni?.publishedAt),
        boughtOnlineCourses: user.online_course_inventory.filter(course => course.course_certificate_multi?.length && course.online_kurzy?.publishedAt),
      }
    } catch(e) {
      console.error(e)
      redirect('/')
    }
  },
  head() {
    return {
      title: 'Můj profil | 3D Fitness Academy'
    }
  },
  computed: {
    allBoughtCourses() {
      const allBoughtCourses = [...this.boughtCourses, ...this.boughtOnlineCourses]
      return allBoughtCourses
    },
    filteredBoughtCourses() {
      let boughtCourses = this.boughtCourses?.slice()
      boughtCourses = boughtCourses?.sort(function (a, b) {
        return (Date.parse(b.course_attendance[0].occurance_date) > Date.parse(a.course_attendance[0].occurance_date)) ? 1 : (Date.parse(b.course_attendance[0].occurance_date) < Date.parse(a.course_attendance[0].occurance_date)) ? -1 : 0;
      })
      return boughtCourses
    }
  },
  mounted() {
    if (this.$route.hash) {
      const element = document.getElementById("attended")
      const elementPosition = element?.getBoundingClientRect().top
      const target = elementPosition - 80
      window.scrollTo({
        top: target,
        behavior: "smooth"
      })
    }
  }
}
