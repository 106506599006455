import { render, staticRenderFns } from "./BannerLogo.vue?vue&type=template&id=720139f6&"
import script from "./BannerLogo.vue?vue&type=script&lang=js&"
export * from "./BannerLogo.vue?vue&type=script&lang=js&"
import style0 from "./BannerLogo.vue?vue&type=style&index=0&id=720139f6&prod&lang=postcss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LogoGym: require('/home/runner/work/3df/3df/components/Logo/LogoGym.vue').default})
