
export default {
  name: 'Banner',
  props: {
    title: {
      type: String,
      default: 'Registrujte se dnes!',
    },
    subtitle: {
      type: String,
      default: 'Staňte se součástí 3D FITNESS GYM. Vaše první návštěva je na nás.',
    },
    btn_text: {
      type: String,
      default: 'REGISTROVAT a získat lekci zdarma',
    },
    btn_url: {
      type: String,
      default: '',
    },
  },
  methods: {
    scrollToRegister() {
      const registerBlock = document.querySelector('#register')
      if (!registerBlock) return
      window.scrollTo({
        top: registerBlock.offsetTop,
        behavior: 'smooth',
      })
    },
  },
}
