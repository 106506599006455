
import AcademyBannerLogo from '~/components/Banner/BannerLogo/AcademyBannerLogo.vue'

export default {
  name: 'AcademyAbout',
  components: { AcademyBannerLogo },
  layout: 'academy_default',
  async asyncData({ app, redirect }) {
    try {
      const { data } = await app.$api.getAcademyAbout()
      return {
        page: data.data.attributes,
        promoVideo: data.data.attributes.promo_video?.video.data.attributes,
        api: process.env.apiUrl,
      }
    } catch {
      redirect('/')
    }
  },
  head() {
    return this.$seo.generate(
      this.page?.serp,
      this.page?.social_sharing,
      this.page?.page_title_h1
    )
  },
  computed: {
    coverImg() {
      if (!this.page?.cover_image?.data) {
        return ''
      }
      return this.page.cover_image.data.attributes.url
    },
  },
}
