

export default {
    props: ['participant', 'name'],
    data() {
        return {
            showDates: false,
        }
    },
}

