const SiteContextByHostname = {
  'localhost': 'akademie', // Local development
  '3dfitnessgym': 'gym',   // Gym
  '3dfa': 'akademie',      // Academy
  'makronky': 'akademie',  // Academy "Production" (temporary)
}

const SiteContextIdByContext = {
  'gym': 1,
  'akademie': 2,
}

function hostnameFromHost(host) {
  return host.split('.')[0].split(':')[0]
}

function siteContextFromHost(host) {
  const hostname = hostnameFromHost(host)
  return SiteContextByHostname[hostname]
}

export const state = () => ({
  siteSettings: null,
  siteContext: null,
  hostname: null,
})

export const getters = {
  siteSettings: (state) => state.siteSettings,
  siteContext: (state) => state.siteContext,
  siteContextId: (state) => SiteContextIdByContext[state.siteContext],
  isAcademy: (state) => state.siteContext === 'akademie',
  isGym: (state) => state.siteContext === 'gym',
}

export const mutations = {
  setSiteSettings(state, settings) {
    state.siteSettings = { ...settings }
  },
  setSiteContext(state, context) {
    state.siteContext = context
  },
  setHostname(state, hostname) {
    state.hostname = hostname
  }
}

export const actions = {
  async nuxtClientInit({ commit, dispatch }, { error }) {
    // await console.log(ctx)
    // const host = req.rawHeaders[1]
    const context = siteContextFromHost(window.location.hostname)
    
    try {
      // set akademie or gym context
      commit('setHostname', window.location.hostname)
      commit('setSiteContext', context)
      await dispatch('fetchSiteSettings')
    }
    catch(e) {
      return error({
        statusCode: 500,
        message: 'Failed to load. Try to reload the website in a minute',
        fatal: true,
      })
    }
    dispatch('popup/getImage')
    // dispatch('articles/getNewArticles')
    // dispatch('testimonials/getTestimonials')
    // dispatch('user/restoreSession')
  },

  async fetchSiteSettings({ state, commit }) {
    const RouteByContext = {
      'gym': '/api/gym-site-setting',
      'akademie': '/api/akademie-site-setting',
    }

    const route = RouteByContext[state.siteContext]
  
    const { data } = await this.$axios.get(`${route}?populate=social_sharing_default_img,header_default_img` )

    const settings = data?.data?.attributes

    if (settings) {
      commit('setSiteSettings', settings)
    }
    // Settings failed to load
    if (!settings && !state.siteSettings) {
      throw new Error('Empty site settings')
    }
  }, 
}

