import qs from 'qs'

export const getAcademyContact = (axios) => {
  const query = qs.stringify(
    {
      populate: [
        'serp',
        'photo_gallery',
        'contacts',
        'contacts.pin_on_map',
        'contacts.directions',
        'contacts.directions.icon'
      ],
    },
    {
      encodeValuesOnly: true,
    }
  )

  return axios.get(`/api/akademie-kontakt?${query.trim()}`)
}
