
export default {
  name: 'Pricing',
  async asyncData({ store, app, redirect }) {
    try {
      const [pricingPage, testimonials] = await Promise.all([
        app.$api.getPricingPage(),
        store.dispatch('testimonials/getTestimonials')
      ])

      return {
        page: pricingPage.data.data.attributes,
        lessons: [],
        api: process.env.apiUrl,
        testimonials
      }
    } catch {
      redirect('/')
    }
  },
  head() {
    return this.$seo.generate(
      this.page?.serp,
      this.page?.social_sharing,
      this.page?.page_title_h1
    )
  },
  computed: {
    coverImg() {
      if (!this.page?.cover_image?.data) {
        return ''
      }
      return this.page.cover_image.data.attributes.url
    },
  },
  methods: {
    scrollToRegister() {
      const registerBlock = document.querySelector('#register')
      if (!registerBlock) return
      window.scrollTo({
        top: registerBlock.offsetTop,
        behavior: 'smooth',
      })
    },
  },
}
