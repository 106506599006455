import { render, staticRenderFns } from "./AcademyBanner.vue?vue&type=template&id=9b979efe&"
import script from "./AcademyBanner.vue?vue&type=script&lang=js&"
export * from "./AcademyBanner.vue?vue&type=script&lang=js&"
import style0 from "./AcademyBanner.vue?vue&type=style&index=0&id=9b979efe&prod&lang=postcss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports