
import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    title: {
      type: String,
      default: 'Naše lekce',
    },
    img: {
      type: String,
      default: '',
    },
    btn: {
      type: Object,
      default: () => ({
        text: 'Aktuální lekce',
        link: null,
      }),
    },
    announcement: {
      type: String,
      default: null,
    },
    address: {
      type: String,
      default: null,
    },
    phone: {
      type: String,
      default: null,
    },
    email: {
      type: String,
      default: null,
    },
  },

  setup() {
    const scrollScreen = () => {
      const header = document.querySelector('.header-contacts')
      const headerHeight = header.offsetHeight
      if (!header && !headerHeight) {
        return
      }
      window.scrollTo({
        top: headerHeight,
        // behavior: 'smooth',
      })
    }
    return {
      scrollScreen,
    }
  },
})
