
import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  name: 'SectionDetailPricing',
  props: {
    dates: {
      type: Object,
      default: null
    },
    courseName: {
      type: String,
      default: null
    },
    courseSlug: {
      type: String,
      required: true
    },
    to: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      selected: ''
    }
  },
  computed: {
    today () {
      const today = new Date()
      let day = today.getDate()
      day = day.toString()
      if (day.length < 2) {
        day = '0' + day
      }
      let month = today.getMonth() + 1
      month = month.toString()
      if (month.length < 2) {
        month = '0' + month
      }
      return today.getFullYear() + '-' + month + '-' + day
    },
    validDates() {
      const dates = []
      this.dates.date.forEach(date => {
        if (date.date > this.today) {
          dates.push(date)
        }
      });
      return dates
    },
  },
  methods: {
    formatDate(date) {
      const options = { year: 'numeric', month: '2-digit', day: '2-digit' }
      return new Date(date).toLocaleDateString('cs-CS', options)
    },
    formatPrice(price) {
      return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
    },
  }
})
