
import gsap from 'gsap'

export default {
  name: 'BlockContent',
  props: {
    content: {
      type: Object,
      default: null,
    },
    contentAlignment: {
      type: String,
      default: 'left',
    },
    title: {
      type: String,
      default: 'Budeme hodně osobní',
    },
    list: {
      type: Array,
      default: null
    },
    btn: {
      type: Object,
      default: () => ({
        text: 'Více o trenéři',
        to: { name: 'trainers' },
      }),
    },
    btnText: {
      type: String,
      default: 'Vyberte školení'
    },
    btnUrl: {
      type: String,
      default: 'fitness-skoleni'
    }
  },
  mounted() {
    this.animateOnScroll()
  },
  beforeDestroy() {
    if (this.tl) this.tl.kill()
    this.tl = null
  },
  methods: {
    getIcon(icon) {
      if (!icon?.data?.attributes?.url) {
        return null
      }
      const apiUrl = process.env.apiUrl
      return apiUrl + icon.data.attributes.url
    },
    animateOnScroll() {
      this.tl = gsap.timeline({
        scrollTrigger: {
          trigger: '.block-content',
          start: '-30%'
        }
      })
      this.tl.set('.block-content__list-item', { x: -20, opacity: 0 })
      this.tl.fromTo('.block-content__list-item', {
        x: -20,
        opacity: 0
      },
      {
        x: 0,
        opacity: 1,
        duration: 0.4,
        ease: 'easeIn',
        stagger: 0.2,
      })
    }
  },
 }
