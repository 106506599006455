
import { defineComponent, ref, watch } from '@nuxtjs/composition-api'

export default defineComponent({
  name: 'SectionRegister',
  setup() {
    const groupSection = ref(false)
    const personalTraining = ref(true)
    watch(groupSection, (val) => {
      if (val) {
        personalTraining.value = false
      }
    })
    watch(personalTraining, (val) => {
      if (val) {
        groupSection.value = false
      }
    })
    return {
      groupSection,
      personalTraining,
    }
  },
  data: () => ({
    email: '',
    gdpr: false,
    user: {
      firstname: '',
      lastname: '',
      phone: '',
      message: '',
      types: [1],
    },
    errors: {},
  }),
  watch: {
    gdpr() {
      this.errors = { ...this.errors, gdpr: false }
    }
  },
  methods: {
    validate() {
      this.errors = {
        gdpr: !this.gdpr
      }

      // Return true if any of this.errors value is true
      return !Object.values(this.errors).some(v => !!v)
    },
    async register() {
      if (!this.validate()) {
        return
      }

      try {
        // If neither interest is selected, interest = undefined
        const interest = this.personalTraining ? 'private' : (this.groupSection ? 'group' : undefined)
        const res = await this.$store.dispatch('user/register', {
          email: this.email,
          username: this.email,
          context: {
            firstname: this.user.firstname,
            lastname: this.user.lastname,
            phone: this.user.phone,
            message: this.user.message,
            interest,
          },
        })

        if (res.email) {
          this.$vfm.show('form-set')
          this.email = ''
          this.user = {
            firstname: '',
            lastname: '',
            phone: '',
            message: '',
          }
        }
      } catch (e) {
        console.log(e)
      }
    },
  },
})
