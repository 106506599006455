
import { defineComponent } from '@nuxtjs/composition-api'
import MapStyles from './map.json'

export default defineComponent({
  props: {
    title: {
      type: String,
      default: 'Kontakty',
    },
    img: {
      type: String,
      default: '',
    },
    contacts: {
      type: Array,
      required: true
    },
    announcement: {
      type: String,
      default: null,
    }
  },
  setup() {
    const scrollScreen = () => {
      const header = document.querySelector('.academy-header-contacts')
      const headerHeight = header.offsetHeight
      if (!header && !headerHeight) {
        return
      }
      window.scrollTo({
        top: headerHeight,
        behavior: 'smooth',
      })
    }
    return {
      scrollScreen
    }
  },
  data() {
    return {
      selected: this.$props.contacts[0],
      center: {
        lat: 50.1007587,
        lng: 14.4258813
      },
      info: false,
      mapStyle: MapStyles,
    }
  },
  mounted() {
    this.$emit('getDirections', this.selected.directions)
  },
  methods: {
    emitDirections() {
      this.$emit('getDirections', this.selected.directions)
    },
    markerClick () {
      this.info = true
    },
    getMarker () {
      return {
        lng: parseFloat(this.selected.longitude),
        lat: parseFloat(this.selected.latitude)
      }
    },
    updateCenter () {
      this.center.lat = parseFloat(this.selected.latitude)
      this.center.lng = parseFloat(this.selected.longitude)
    }
  },
})
