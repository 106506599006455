
import { defineComponent, onMounted, onUnmounted, ref } from '@nuxtjs/composition-api'
import gsap from 'gsap'

export default defineComponent({
  props: {
    slider: {
      type: Array,
      default: () => [],
    },
  },
  setup({ slider }) {
    let tl = gsap.timeline({ repeat: -1 })
    const data = ref(slider)
    if (!data || !data.value || !data.value.length) return
    const content = ref({
      title: null,
      subtitle: null,
      img: slider[0].image,
    })
    const hasImage = (item) => {
      return item?.image?.data?.id === content.value?.image?.data?.id
    }
    const api = ref(`${process.env.apiUrl}`)
    onUnmounted(() => {
      tl.kill()
      tl = null // Let garbage collector collect this right away
    })
    onMounted(() => {
      let index = 0
      content.value = data.value[index]
      const title = document.querySelector('.homepage-header__title')
      const paragraph = document.querySelector('.homepage-header__paragraph')
      const button = document.querySelector('.homepage-header__btn')
      const overlay = document.querySelector('.homepage-header__overlay')
      const img = document.querySelectorAll('.homepage-header__img')
      if (!title || !paragraph || !button || !overlay || !img) return
      tl.addLabel('start')
        .to([img], { scale: 1, duration: 0.8, ease: 'power3.out' }, 'start')
        .to(overlay, { duration: 1, opacity: 1, ease: 'power3.out' }, 'start')
        .from(
          title,
          { duration: 1, y: -100, opacity: 0, ease: 'power3.out' },
          'start'
        )
        .from(paragraph, {
          duration: 0.6,
          y: -100,
          opacity: 0,
          ease: 'power3.out',
        })
        .from(button, {
          duration: 0.6,
          y: -100,
          opacity: 0,
          ease: 'power3.out',
        })
        .to(title, {
          duration: 0.2,
          delay: 3,
          y: 30,
          opacity: 0.5,
          ease: 'power3.in',
        })
        .to(title, { duration: 0.4, y: -120, opacity: 0, ease: 'power3.out' })
        .to(paragraph, {
          duration: 0.2,
          y: 30,
          opacity: 0.5,
          ease: 'power4.in',
        })
        .to(paragraph, {
          duration: 0.2,
          y: -120,
          opacity: 0,
          ease: 'power2.out',
        })
        .to(button, { duration: 0.2, y: 20, opacity: 0.5, ease: 'power4.in' })
        .to(button, {
          duration: 0.2,
          y: -100,
          opacity: 0,
          ease: 'power2.out',
          onComplete: () => {
            index++
            if (index >= data.value.length) index = 0
            content.value = data.value[index]
          },
        })
    })
    return {
      content,
      api,
      hasImage,
    }
  },
})
