
import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  name: 'AcademyClientOnlineCourseDetail',
  layout: 'academy_client',
  async asyncData({ app, redirect, store, route }) {
    try {
      const { data } = await app.$api.getAcademyOnlineCourseBySlug(route.params.slug)
      const user = await store.dispatch('user/findMe')
  
      return {
        data: data?.data[0],
        user,
        page: data?.data[0]?.attributes,
        trainers: data?.data[0]?.attributes.trainers?.data,
        program: data?.data[0]?.attributes.program,
        boughtCourse: user.online_course_inventory?.filter(course => course.online_kurzy?.id === data.data[0].id)[0] || null,
        api: process.env.apiUrl,
      }
    }
    catch(e) {
      console.error(e)
      redirect('/')
    }
  },
  data() {
    return {
      bought: false
    }
  },
  head() {
    return this.$seo.generate(
      this.page?.serp,
      this.page?.social_sharing,
      this.page?.online_course_name
    )
  },
  computed: {
    coverImage() {
      if (!this.page || !this.page.cover_image || !this.page.cover_image.data) {
        return null
      }
      return `${this.page.cover_image.data.attributes.url}`
    },
  },
  mounted() {
    if (this.program) {
      this.getUser()
    }
  },
  methods: {
    getUser() {
      const user = this.user
      if (user.online_course_inventory.find(course => course.online_kurzy.id === this.data?.id)) {
        this.bought = true
      } else {
        this.bought = false
      }
    },
    scrollScreen() {
      const header = document.querySelector('.details-page__header')
      const headerHeight = header.offsetHeight
      if (!header && !headerHeight) {
        return
      }
      window.scrollTo({
        top: headerHeight,
        behavior: 'smooth',
      })
    },
  },
})
