import emailValidator from "email-validator"

export default {
  username: (value) => value && value.length >= 5,
  firstName: (value) => value && value.length >= 2,
  lastName: (value) => value && value.length >= 2,
  password: (value) => value && value.length >= 8,
  email: (value) => emailValidator.validate(value),
  gdpr: (value) => !!value,
  notEmpty: (value) => !!value,
}
