export const AcademyHeaderHomepage = () => import('../../components/AcademyHeaderHomepage.vue' /* webpackChunkName: "components/academy-header-homepage" */).then(c => wrapFunctional(c.default || c))
export const AcademyHomepage = () => import('../../components/AcademyHomepage.vue' /* webpackChunkName: "components/academy-homepage" */).then(c => wrapFunctional(c.default || c))
export const GDPRConsent = () => import('../../components/GDPRConsent.vue' /* webpackChunkName: "components/g-d-p-r-consent" */).then(c => wrapFunctional(c.default || c))
export const Homepage = () => import('../../components/Homepage.vue' /* webpackChunkName: "components/homepage" */).then(c => wrapFunctional(c.default || c))
export const LoginOrRegister = () => import('../../components/LoginOrRegister.vue' /* webpackChunkName: "components/login-or-register" */).then(c => wrapFunctional(c.default || c))
export const AcademyBanner = () => import('../../components/AcademyBanner/AcademyBanner.vue' /* webpackChunkName: "components/academy-banner" */).then(c => wrapFunctional(c.default || c))
export const AcademyPaymentBought = () => import('../../components/AcademyPaymentOverview/PaymentBought.vue' /* webpackChunkName: "components/academy-payment-bought" */).then(c => wrapFunctional(c.default || c))
export const AcademyPaymentError = () => import('../../components/AcademyPaymentOverview/PaymentError.vue' /* webpackChunkName: "components/academy-payment-error" */).then(c => wrapFunctional(c.default || c))
export const AcademyPaymentNotSelected = () => import('../../components/AcademyPaymentOverview/PaymentNotSelected.vue' /* webpackChunkName: "components/academy-payment-not-selected" */).then(c => wrapFunctional(c.default || c))
export const AcademyPaymentOverview = () => import('../../components/AcademyPaymentOverview/PaymentOverview.vue' /* webpackChunkName: "components/academy-payment-overview" */).then(c => wrapFunctional(c.default || c))
export const AcademyPaymentSuccess = () => import('../../components/AcademyPaymentOverview/PaymentSuccess.vue' /* webpackChunkName: "components/academy-payment-success" */).then(c => wrapFunctional(c.default || c))
export const AcademySectionRegister = () => import('../../components/AcademySectionRegister/AcademySectionRegister.vue' /* webpackChunkName: "components/academy-section-register" */).then(c => wrapFunctional(c.default || c))
export const Banner = () => import('../../components/Banner/Banner.vue' /* webpackChunkName: "components/banner" */).then(c => wrapFunctional(c.default || c))
export const ConstructorBlockquote = () => import('../../components/Constructor/ConstructorBlockquote.vue' /* webpackChunkName: "components/constructor-blockquote" */).then(c => wrapFunctional(c.default || c))
export const ConstructorButton = () => import('../../components/Constructor/ConstructorButton.vue' /* webpackChunkName: "components/constructor-button" */).then(c => wrapFunctional(c.default || c))
export const ConstructorCallout = () => import('../../components/Constructor/ConstructorCallout.vue' /* webpackChunkName: "components/constructor-callout" */).then(c => wrapFunctional(c.default || c))
export const ConstructorDivider = () => import('../../components/Constructor/ConstructorDivider.vue' /* webpackChunkName: "components/constructor-divider" */).then(c => wrapFunctional(c.default || c))
export const ConstructorDoubleText = () => import('../../components/Constructor/ConstructorDoubleText.vue' /* webpackChunkName: "components/constructor-double-text" */).then(c => wrapFunctional(c.default || c))
export const ConstructorEmbed = () => import('../../components/Constructor/ConstructorEmbed.vue' /* webpackChunkName: "components/constructor-embed" */).then(c => wrapFunctional(c.default || c))
export const ConstructorEmbedSocials = () => import('../../components/Constructor/ConstructorEmbedSocials.vue' /* webpackChunkName: "components/constructor-embed-socials" */).then(c => wrapFunctional(c.default || c))
export const ConstructorFile = () => import('../../components/Constructor/ConstructorFile.vue' /* webpackChunkName: "components/constructor-file" */).then(c => wrapFunctional(c.default || c))
export const ConstructorGallery = () => import('../../components/Constructor/ConstructorGallery.vue' /* webpackChunkName: "components/constructor-gallery" */).then(c => wrapFunctional(c.default || c))
export const ConstructorImage = () => import('../../components/Constructor/ConstructorImage.vue' /* webpackChunkName: "components/constructor-image" */).then(c => wrapFunctional(c.default || c))
export const ConstructorLayout = () => import('../../components/Constructor/ConstructorLayout.vue' /* webpackChunkName: "components/constructor-layout" */).then(c => wrapFunctional(c.default || c))
export const ConstructorNumbered = () => import('../../components/Constructor/ConstructorNumbered.vue' /* webpackChunkName: "components/constructor-numbered" */).then(c => wrapFunctional(c.default || c))
export const ConstructorPrice = () => import('../../components/Constructor/ConstructorPrice.vue' /* webpackChunkName: "components/constructor-price" */).then(c => wrapFunctional(c.default || c))
export const ConstructorPromo = () => import('../../components/Constructor/ConstructorPromo.vue' /* webpackChunkName: "components/constructor-promo" */).then(c => wrapFunctional(c.default || c))
export const ConstructorSchedule = () => import('../../components/Constructor/ConstructorSchedule.vue' /* webpackChunkName: "components/constructor-schedule" */).then(c => wrapFunctional(c.default || c))
export const ConstructorText = () => import('../../components/Constructor/ConstructorText.vue' /* webpackChunkName: "components/constructor-text" */).then(c => wrapFunctional(c.default || c))
export const ConstructorTitle = () => import('../../components/Constructor/ConstructorTitle.vue' /* webpackChunkName: "components/constructor-title" */).then(c => wrapFunctional(c.default || c))
export const ConstructorTrainerDetails = () => import('../../components/Constructor/ConstructorTrainerDetails.vue' /* webpackChunkName: "components/constructor-trainer-details" */).then(c => wrapFunctional(c.default || c))
export const ConstructorVideo = () => import('../../components/Constructor/ConstructorVideo.vue' /* webpackChunkName: "components/constructor-video" */).then(c => wrapFunctional(c.default || c))
export const Footer = () => import('../../components/Footer/Footer.vue' /* webpackChunkName: "components/footer" */).then(c => wrapFunctional(c.default || c))
export const Gallery = () => import('../../components/Gallery/Gallery.vue' /* webpackChunkName: "components/gallery" */).then(c => wrapFunctional(c.default || c))
export const IconMenu = () => import('../../components/Icon/IconMenu.vue' /* webpackChunkName: "components/icon-menu" */).then(c => wrapFunctional(c.default || c))
export const IconStar = () => import('../../components/Icon/IconStar.vue' /* webpackChunkName: "components/icon-star" */).then(c => wrapFunctional(c.default || c))
export const LogoAcademy = () => import('../../components/Logo/LogoAcademy.vue' /* webpackChunkName: "components/logo-academy" */).then(c => wrapFunctional(c.default || c))
export const LogoEshop = () => import('../../components/Logo/LogoEshop.vue' /* webpackChunkName: "components/logo-eshop" */).then(c => wrapFunctional(c.default || c))
export const LogoFitness = () => import('../../components/Logo/LogoFitness.vue' /* webpackChunkName: "components/logo-fitness" */).then(c => wrapFunctional(c.default || c))
export const LogoGym = () => import('../../components/Logo/LogoGym.vue' /* webpackChunkName: "components/logo-gym" */).then(c => wrapFunctional(c.default || c))
export const LogoSatna = () => import('../../components/Logo/LogoSatna.vue' /* webpackChunkName: "components/logo-satna" */).then(c => wrapFunctional(c.default || c))
export const Menu = () => import('../../components/Menu/Menu.vue' /* webpackChunkName: "components/menu" */).then(c => wrapFunctional(c.default || c))
export const Nav = () => import('../../components/Nav/Nav.vue' /* webpackChunkName: "components/nav" */).then(c => wrapFunctional(c.default || c))
export const OverlaySlider = () => import('../../components/OverlaySlider/OverlaySlider.vue' /* webpackChunkName: "components/overlay-slider" */).then(c => wrapFunctional(c.default || c))
export const Select = () => import('../../components/Select/Select.vue' /* webpackChunkName: "components/select" */).then(c => wrapFunctional(c.default || c))
export const Toggle = () => import('../../components/Toggle/Toggle.vue' /* webpackChunkName: "components/toggle" */).then(c => wrapFunctional(c.default || c))
export const AttendanceClient = () => import('../../components/Attendance/AttendanceClient/AttendanceClient.vue' /* webpackChunkName: "components/attendance-client" */).then(c => wrapFunctional(c.default || c))
export const AttendanceDates = () => import('../../components/Attendance/AttendanceDates/AttendanceDates.vue' /* webpackChunkName: "components/attendance-dates" */).then(c => wrapFunctional(c.default || c))
export const AttendanceForm = () => import('../../components/Attendance/AttendanceForm/AttendanceForm.vue' /* webpackChunkName: "components/attendance-form" */).then(c => wrapFunctional(c.default || c))
export const AttendanceDate = () => import('../../components/Attendance/AttendanceParticipant/AttendanceDate.vue' /* webpackChunkName: "components/attendance-date" */).then(c => wrapFunctional(c.default || c))
export const AttendanceParticipant = () => import('../../components/Attendance/AttendanceParticipant/AttendanceParticipant.vue' /* webpackChunkName: "components/attendance-participant" */).then(c => wrapFunctional(c.default || c))
export const BannerBg = () => import('../../components/Banner/BannerBg/BannerBg.vue' /* webpackChunkName: "components/banner-bg" */).then(c => wrapFunctional(c.default || c))
export const BannerBannerLogoAcademyBannerLogo = () => import('../../components/Banner/BannerLogo/AcademyBannerLogo.vue' /* webpackChunkName: "components/banner-banner-logo-academy-banner-logo" */).then(c => wrapFunctional(c.default || c))
export const BannerLogo = () => import('../../components/Banner/BannerLogo/BannerLogo.vue' /* webpackChunkName: "components/banner-logo" */).then(c => wrapFunctional(c.default || c))
export const BannerPromoCourse = () => import('../../components/Banner/BannerPromoCourse/BannerPromoCourse.vue' /* webpackChunkName: "components/banner-promo-course" */).then(c => wrapFunctional(c.default || c))
export const BannerSeal = () => import('../../components/Banner/BannerSeal/BannerSeal.vue' /* webpackChunkName: "components/banner-seal" */).then(c => wrapFunctional(c.default || c))
export const BannerWhite = () => import('../../components/Banner/BannerWhite/BannerWhite.vue' /* webpackChunkName: "components/banner-white" */).then(c => wrapFunctional(c.default || c))
export const BlockContent = () => import('../../components/Block/BlockContent/BlockContent.vue' /* webpackChunkName: "components/block-content" */).then(c => wrapFunctional(c.default || c))
export const BlockImage = () => import('../../components/Block/BlockImage/BlockImage.vue' /* webpackChunkName: "components/block-image" */).then(c => wrapFunctional(c.default || c))
export const BlockImageEvents = () => import('../../components/Block/BlockImage/BlockImageEvents.vue' /* webpackChunkName: "components/block-image-events" */).then(c => wrapFunctional(c.default || c))
export const BlockList = () => import('../../components/Block/BlockList/BlockList.vue' /* webpackChunkName: "components/block-list" */).then(c => wrapFunctional(c.default || c))
export const BlockNumbered = () => import('../../components/Block/BlockNumbered/BlockNumbered.vue' /* webpackChunkName: "components/block-numbered" */).then(c => wrapFunctional(c.default || c))
export const BlockPromoEvent = () => import('../../components/Block/BlockPromoEvent/BlockPromoEvent.vue' /* webpackChunkName: "components/block-promo-event" */).then(c => wrapFunctional(c.default || c))
export const CardArticle = () => import('../../components/Card/CardArticle/CardArticle.vue' /* webpackChunkName: "components/card-article" */).then(c => wrapFunctional(c.default || c))
export const CardAuthor = () => import('../../components/Card/CardAuthor/CardAuthor.vue' /* webpackChunkName: "components/card-author" */).then(c => wrapFunctional(c.default || c))
export const CardBig = () => import('../../components/Card/CardBig/CardBig.vue' /* webpackChunkName: "components/card-big" */).then(c => wrapFunctional(c.default || c))
export const CardClient = () => import('../../components/Card/CardClient/CardClient.vue' /* webpackChunkName: "components/card-client" */).then(c => wrapFunctional(c.default || c))
export const CardCardClientUploadProfileImage = () => import('../../components/Card/CardClient/UploadProfileImage.vue' /* webpackChunkName: "components/card-card-client-upload-profile-image" */).then(c => wrapFunctional(c.default || c))
export const CardCollapse = () => import('../../components/Card/CardCollapse/CardCollapse.vue' /* webpackChunkName: "components/card-collapse" */).then(c => wrapFunctional(c.default || c))
export const CardCardCollapseClientCardCollapse = () => import('../../components/Card/CardCollapse/ClientCardCollapse.vue' /* webpackChunkName: "components/card-card-collapse-client-card-collapse" */).then(c => wrapFunctional(c.default || c))
export const CardLesson = () => import('../../components/Card/CardLesson/CardLesson.vue' /* webpackChunkName: "components/card-lesson" */).then(c => wrapFunctional(c.default || c))
export const CardProfile = () => import('../../components/Card/CardProfile/CardProfile.vue' /* webpackChunkName: "components/card-profile" */).then(c => wrapFunctional(c.default || c))
export const CardCardPromotionAcademyCardPromotion = () => import('../../components/Card/CardPromotion/AcademyCardPromotion.vue' /* webpackChunkName: "components/card-card-promotion-academy-card-promotion" */).then(c => wrapFunctional(c.default || c))
export const CardPromotion = () => import('../../components/Card/CardPromotion/CardPromotion.vue' /* webpackChunkName: "components/card-promotion" */).then(c => wrapFunctional(c.default || c))
export const CardSchedule = () => import('../../components/Card/CardSchedule/CardSchedule.vue' /* webpackChunkName: "components/card-schedule" */).then(c => wrapFunctional(c.default || c))
export const CardSmall = () => import('../../components/Card/CardSmall/CardSmall.vue' /* webpackChunkName: "components/card-small" */).then(c => wrapFunctional(c.default || c))
export const CardCardTestimonialAcademyCardTestimonial = () => import('../../components/Card/CardTestimonial/AcademyCardTestimonial.vue' /* webpackChunkName: "components/card-card-testimonial-academy-card-testimonial" */).then(c => wrapFunctional(c.default || c))
export const CardTestimonial = () => import('../../components/Card/CardTestimonial/CardTestimonial.vue' /* webpackChunkName: "components/card-testimonial" */).then(c => wrapFunctional(c.default || c))
export const HeaderContacts = () => import('../../components/Header/HeaderContancts/HeaderContacts.vue' /* webpackChunkName: "components/header-contacts" */).then(c => wrapFunctional(c.default || c))
export const HeaderDefault = () => import('../../components/Header/HeaderDefault/HeaderDefault.vue' /* webpackChunkName: "components/header-default" */).then(c => wrapFunctional(c.default || c))
export const HeaderHomepage = () => import('../../components/Header/HeaderHomepage/HeaderHomepage.vue' /* webpackChunkName: "components/header-homepage" */).then(c => wrapFunctional(c.default || c))
export const HeaderHeaderHomepageDemo = () => import('../../components/Header/HeaderHomepage/demo.js' /* webpackChunkName: "components/header-header-homepage-demo" */).then(c => wrapFunctional(c.default || c))
export const HeaderMagazine = () => import('../../components/Header/HeaderMagazine/HeaderMagazine.vue' /* webpackChunkName: "components/header-magazine" */).then(c => wrapFunctional(c.default || c))
export const ModalClientChange = () => import('../../components/Modal/ModalClientChange/ModalClientChange.vue' /* webpackChunkName: "components/modal-client-change" */).then(c => wrapFunctional(c.default || c))
export const ModalConfirmation = () => import('../../components/Modal/ModalConfirmation/ModalConfirmation.vue' /* webpackChunkName: "components/modal-confirmation" */).then(c => wrapFunctional(c.default || c))
export const ModalForgot = () => import('../../components/Modal/ModalForgot/ModalForgot.vue' /* webpackChunkName: "components/modal-forgot" */).then(c => wrapFunctional(c.default || c))
export const ModalForm = () => import('../../components/Modal/ModalForm/ModalForm.vue' /* webpackChunkName: "components/modal-form" */).then(c => wrapFunctional(c.default || c))
export const ModalFormSet = () => import('../../components/Modal/ModalFormSet/ModalFormSet.vue' /* webpackChunkName: "components/modal-form-set" */).then(c => wrapFunctional(c.default || c))
export const ModalImage = () => import('../../components/Modal/ModalImage/ModalImage.vue' /* webpackChunkName: "components/modal-image" */).then(c => wrapFunctional(c.default || c))
export const ModalLogin = () => import('../../components/Modal/ModalLogin/ModalLogin.vue' /* webpackChunkName: "components/modal-login" */).then(c => wrapFunctional(c.default || c))
export const ModalPassword = () => import('../../components/Modal/ModalPassword/ModalPassword.vue' /* webpackChunkName: "components/modal-password" */).then(c => wrapFunctional(c.default || c))
export const ModalRegister = () => import('../../components/Modal/ModalRegister/ModalRegister.vue' /* webpackChunkName: "components/modal-register" */).then(c => wrapFunctional(c.default || c))
export const NavCheck = () => import('../../components/Nav/NavCheck/NavCheck.vue' /* webpackChunkName: "components/nav-check" */).then(c => wrapFunctional(c.default || c))
export const NavClient = () => import('../../components/Nav/NavClient/NavClient.vue' /* webpackChunkName: "components/nav-client" */).then(c => wrapFunctional(c.default || c))
export const NavClientMobile = () => import('../../components/Nav/NavClient/NavClientMobile.vue' /* webpackChunkName: "components/nav-client-mobile" */).then(c => wrapFunctional(c.default || c))
export const SectionClientCourseBought = () => import('../../components/Section/SectionClientCourseBought/SectionClientCourseBought.vue' /* webpackChunkName: "components/section-client-course-bought" */).then(c => wrapFunctional(c.default || c))
export const SectionClientCourseCompleted = () => import('../../components/Section/SectionClientCourseCompleted/SectionClientCourseCompleted.vue' /* webpackChunkName: "components/section-client-course-completed" */).then(c => wrapFunctional(c.default || c))
export const SectionClientProfile = () => import('../../components/Section/SectionClientProfile/SectionClientProfile.vue' /* webpackChunkName: "components/section-client-profile" */).then(c => wrapFunctional(c.default || c))
export const SectionDetailPricing = () => import('../../components/Section/SectionDetailPricing/SectionDetailPricing.vue' /* webpackChunkName: "components/section-detail-pricing" */).then(c => wrapFunctional(c.default || c))
export const SectionRegister = () => import('../../components/Section/SectionRegister/SectionRegister.vue' /* webpackChunkName: "components/section-register" */).then(c => wrapFunctional(c.default || c))
export const SectionSchedule = () => import('../../components/Section/SectionSchedule/SectionSchedule.vue' /* webpackChunkName: "components/section-schedule" */).then(c => wrapFunctional(c.default || c))
export const HeaderHeaderContanctsAcademyHeaderContacts = () => import('../../components/Header/HeaderContancts/AcademyHeaderContacts/AcademyHeaderContacts.vue' /* webpackChunkName: "components/header-header-contancts-academy-header-contacts" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
