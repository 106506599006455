
export default {
  name: 'Index',
  layout({ store }) {
    return store.getters.isAcademy ? 'academy_default' : 'default'
  },
  async asyncData({ app, store, route }) {
    try {
      let passwordlessLogin = false

      if (route.query.loginToken) {
        // Should login the user
        passwordlessLogin = await store.dispatch('user/passwordlessLogin', { token: route.query.loginToken })
      }

      if (store.getters.isAcademy) {
        const [
          academyHomepage,
          academyEvents,
          academyLectures,
          academyArticles,
        ] = await Promise.all([
          app.$api.getAcademyHomepage(),
          app.$api.getAcademyEvents(),
          app.$api.getAcademyLectures(),
          app.$api.getAcademyNewestArticles(),
        ])

        return {
          page: academyHomepage.data.data.attributes,
          events: academyEvents.data.data,
          courses: academyLectures.data.data,
          articles: academyArticles.data.data,
          passwordlessLogin,
        }
      }
      else {
        const homepage = await app.$api.getHomepage()

        return {
          page: homepage.data.data.attributes,
          passwordlessLogin,
        }
      }
    } catch {
      return {
        page: null,
        passwordlessLogin: false
      }
    }
  },
  computed: {
    isAcademy() {
      return this.$nuxt.$store.getters.isAcademy
    }
  },
  mounted() {
    // Passwordless login modal for initially setting the password
    if (this.passwordlessLogin) {
      this.$vfm.show('modal-password', { 
        canClose: false, 
        onSubmit: async (password, _passwordConfirm) => {
          await this.$store
            .dispatch('user/setPassword', password)
            .catch(console.error)
            .finally(() => {
              this.$router.replace('/')
            })
        }
      })
    }
    // Reset password modal when user goes to /reset-password
    else if (this.$route.query.resetPwdCode) {
      this.$vfm.show('modal-password', { 
        canClose: false, 
        onSubmit: async (password, passwordConfirm) => {
          await this.$store
            .dispatch('user/resetPassword', {
              code: this.$route.query.resetPwdCode,
              password,
              passwordConfirmation: passwordConfirm
            })
            .catch(console.error)
            .finally(() => {
              this.$router.replace('/')
            })
        } 
      })
    }
  }
}
