
import { defineComponent } from '@nuxtjs/composition-api'
import ModalClientChange from '~/components/Modal/ModalClientChange/ModalClientChange.vue'

export default defineComponent({
  components: { ModalClientChange },
  head() {
    return {
      script: [
        {
          innerHTML:
            '!function(e,t,a,n,g){e[n]=e[n]||[],e[n].push({"gtm.start":(new Date).getTime(),event:"gtm.js"});var s=t.getElementsByTagName(a)[0],c=t.createElement(a);c.async=!0,c.src="https://www.googletagmanager.com/gtm.js?id=GTM-M96QBVT",s.parentNode.insertBefore(c,s)}(window,document,"script","dataLayer"),function(e,t,a,n,g,s,c){e.GoogleAnalyticsObject=g,e.ga=e.ga||function(){(e.ga.q=e.ga.q||[]).push(arguments)},e.ga.l=1*new Date,s=t.createElement(a),c=t.getElementsByTagName(a)[0],s.async=1,s.src="https://www.google-analytics.com/analytics.js",c.parentNode.insertBefore(s,c)}(window,document,"script",0,"ga"),ga("create","UA-25163016-7","auto"),ga("send","pageview")',
        },
      ],
      __dangerouslyDisableSanitizers: ['script'],
      link: [
        {
          rel: 'canonical',
          href: 'https://3dfa.cz' + this.$route.path,
        },
        { rel: 'icon', type: 'image/x-icon', href: '/academy-favicon.ico' },
        {
          rel: 'apple-touch-icon',
          sizes: '180x180',
          href: '/academy-apple-touch-icon.png',
        },
        {
          rel: 'icon',
          sizes: '32x32',
          type: 'image/png',
          href: '/academy-favicon-32x32.png',
        },
        {
          rel: 'icon',
          sizes: '16x16',
          type: 'image/png',
          href: '/academy-favicon-16x16.png',
        },
        {
          rel: 'mask-icon',
          href: '/academy-safari-pinned-tab.svg',
          color: '#5bbad5',
        },
        {
          name: 'msapplication-TileColor',
          content: '#da532c',
        },
        {
          name: 'theme-color',
          content: '#ffffff',
        },
        {
          rel: 'manifest',
          href: '/academy-site.webmanifest',
        },
      ],
    }
  },
  mounted() {
    this.$store.dispatch('user/restoreSession')
  },
})
