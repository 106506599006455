
import { defineComponent, ref } from '@nuxtjs/composition-api'

export default defineComponent({
  name: 'BannerSeal',
  props: {
    content: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    const api = ref(`${process.env.apiUrl}`)
    return { api }
  },
  data() {
    return {
      hovered: false,
    }
  }
})

