
import { ref } from '@nuxtjs/composition-api'
export default {
  setup() {
    const isVisible = ref(false)
    return {
      isVisible,
    }
  },
}
