import { render, staticRenderFns } from "./CardTestimonial.vue?vue&type=template&id=7af7248c&"
import script from "./CardTestimonial.vue?vue&type=script&lang=ts&"
export * from "./CardTestimonial.vue?vue&type=script&lang=ts&"
import style0 from "./CardTestimonial.vue?vue&type=style&index=0&id=7af7248c&prod&lang=postcss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconStar: require('/home/runner/work/3df/3df/components/Icon/IconStar.vue').default})
