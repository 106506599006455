
import {
  computed,
  defineComponent,
  onMounted,
  ref,
  watch,
} from '@nuxtjs/composition-api'

export default defineComponent({
  name: 'CardProfile',
  props: {
    appearance: {
      type: String,
      default: 'default',
    },
    content: {
      type: Object,
      required: true,
    },
    link: {
      type: String,
      required: true
    }
  },
  setup({ appearance, content }) {
    const isActive = ref(false)
    const isMobile = ref(false)
    const videoElement = ref(null)
    const isReady = computed(
      () => videoElement.value && videoElement.value.readyState === 4
    )
    const isHovered = ref(false)

    const imageSrc = computed(() => {
      const api = process.env.apiUrl
      if (
        !content?.photo_for_video?.data?.attributes &&
        !content?.trainer_photo?.data?.attributes?.url
      ) {
        return ''
      }
      if (content?.photo_for_video?.data?.attributes) {
        return content.photo_for_video.data.attributes.url
      }
      if (content?.trainer_photo?.data?.attributes) {
        return content.trainer_photo.data.attributes.url
      }
    })
    const vidSrc = computed(() => {
      if (!content?.trainer_video?.data?.attributes?.url) {
        return null
      }
      const api = process.env.apiUrl
      return api + content?.trainer_video?.data?.attributes?.url
    })

    onMounted(() => {
      if (window.innerWidth < 769) {
        isMobile.value = true
      }
    })
    watch(isHovered, (val) => {
      if (
        appearance === 'small' ||
        isMobile.value ||
        !videoElement.value ||
        !vidSrc.value
      ) {
        return
      }
      if (val) {
        videoElement.value.play()
      } else {
        videoElement.value.pause()
      }
    })
    return {
      videoElement,
      isActive,
      isReady,
      isHovered,
      isMobile,
      imageSrc,
      vidSrc,
    }
  },
})
