import { render, staticRenderFns } from "./index.vue?vue&type=template&id=66a762c5&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HeaderDefault: require('/home/runner/work/3df/3df/components/Header/HeaderDefault/HeaderDefault.vue').default,CardProfile: require('/home/runner/work/3df/3df/components/Card/CardProfile/CardProfile.vue').default,Banner: require('/home/runner/work/3df/3df/components/Banner/Banner.vue').default,CardTestimonial: require('/home/runner/work/3df/3df/components/Card/CardTestimonial/CardTestimonial.vue').default,SectionRegister: require('/home/runner/work/3df/3df/components/Section/SectionRegister/SectionRegister.vue').default})
