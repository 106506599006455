export const getSchedulePage = (axios) => {
  const populate = [
    'serp',
    'social_sharing',
    'cover_image',
    'schedule',
    'schedule.dopoledne',
    'schedule.dopoledne.gym_lesson',
    'schedule.odpoledne',
    'schedule.odpoledne.gym_lesson',
    'schedule.evening',
    'schedule.evening.gym_lesson',
    'yellow_block',
    'yellow_block.image',
    'promo_block',
  ]
  const query = `
    populate=${populate.join(',')}
    `
  return axios.get(`/api/gym-rozvrh?${query.trim()}`)
}
