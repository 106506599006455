
import { defineComponent, ref, computed } from '@nuxtjs/composition-api'

export default defineComponent({
  name: 'BlockPromoEvent',
  props: {
    content: {
      type: Object,
      default: () => ({}),
    },
    link: {
      type: Object,
      default: () => ({}),
    },
    date: {
      type: String,
      default: null
    },
    location: {
      type: String,
      default: null
    }
  },
  setup({ content }) {
    const api = ref(`${process.env.apiUrl}`)
    const image = computed(() => {
      return `${content?.cover_image?.data?.attributes?.url}`
    })
    return { 
      api, 
      image,
      dates: content.dates?.date || []
    }
  },
  methods: {
    formatDate(date) {
      const options = { year: 'numeric', month: '2-digit', day: '2-digit' }
      return new Date(date).toLocaleDateString('cs-CS', options)
    },
  },
})
