import { render, staticRenderFns } from "./academy_template.vue?vue&type=template&id=413e4f8e&"
import script from "./academy_template.vue?vue&type=script&lang=js&"
export * from "./academy_template.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ConstructorLayout: require('/home/runner/work/3df/3df/components/Constructor/ConstructorLayout.vue').default,AcademySectionRegister: require('/home/runner/work/3df/3df/components/AcademySectionRegister/AcademySectionRegister.vue').default})
