
import { defineComponent, ref } from '@nuxtjs/composition-api'
import context from '~/mixins/context'

export default defineComponent({
  mixins: [context],
  setup() {
    const isVisible = ref(false)
    return {
      isVisible,
    }
  },
  methods: {
    clientRedirect() {
      if (this.$store.getters.isAcademy) {
        this.$router.push({ name: 'akademie-moje-skoleni'})
      } return null
    }
  }
})
