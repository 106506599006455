
import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  name: 'NavClient',
  props: {
    items: {
      type: Array,
      required: true,
    },
    link: {
      type: Array,
      required: true,
    }
  }
})
