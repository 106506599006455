
import { defineComponent } from '@nuxtjs/composition-api'
import gsap from 'gsap'
import emailValidator from "email-validator"
import context from '~/mixins/context'

export default defineComponent({
  mixins: [context],
  data: () => ({
    state: 'login',
    isVisible: false,
    isHideInitial: true,
    tlSlide: gsap.timeline({ paused: true }),
    email: '',
    password: '',
    firstname: '',
    gdpr: false,
    validate: false,
    loading: false,
    errorUserExists: false,
    loginErrorCode: null,
  }),
  computed: {
    firstnameError() { return this.validate && this.firstname === "" },
    emailError() { return this.errorUserExists || (this.validate && !emailValidator.validate(this.email)) },
    gdprError() { return this.validate && !this.gdpr },
    passwordError() { return this.validate && (this.password === "" || !!this.loginErrorCode) }
  },
  watch: {
    password() {
      this.loginErrorCode = null
    },
    email() {
      this.errorUserExists = false // reset when user changes email input
    },
  },
  mounted() {
    this.tlSlide
      .to(
        '.modal-form__form',
        {
          left: '40%',
          ease: 'power3.inOut',
          duration: 1.6,
        },
        0
      )
      .to(
        '.modal-form__switcher',
        {
          left: '25%',
          width: '60%',
          duration: 0.8,
          ease: 'power3.in',
        },
        0
      )
      .to(
        '.modal-form__switcher',
        {
          left: 0,
          width: '40%',
          duration: 0.8,
          ease: 'power3.out',
        },
        0.8
      )
      .to(
        '.modal-form__switcher__btn',
        {
          scale: 1.1,
          duration: 0.2,
          ease: 'power3.in',
        },
        0
      )
      .to(
        '.modal-form__switcher__btn-inner',
        {
          x: 30,
          scale: 1.1,
          duration: 0.4,
          ease: 'power1.in',
          autoAlpha: 0,
        },
        0.2
      )
      .set(
        '.modal-form__switcher__btn-inner',
        {
          x: -30,
        },
        0.6
      )
      .to(
        '.modal-form__switcher__btn-inner',
        {
          x: 0,
          scale: 1,
          duration: 0.4,
          ease: 'power1.in',
          autoAlpha: 1,
        },
        0.8
      )
      .to(
        '.modal-form__switcher__btn',
        {
          scale: 1,
          duration: 0.2,
          ease: 'power3.in',
        },
        1.3
      )
      .to(
        '.modal-form__right',
        {
          x: 10,
          duration: 0.6,
          ease: 'power3.in',
          autoAlpha: 0,
        },
        0.2
      )
      .fromTo(
        '.modal-form__left',
        {
          x: -10,
          autoAlpha: 0,
        },
        {
          x: 0,
          duration: 0.6,
          ease: 'power3.in',
          autoAlpha: 1,
        },
        0.4
      )
  },
  beforeDestroy() {
    if (this.tlSlide) this.tlSlide.kill()
    this.tlSlide = null
  },
  methods: {
    validateRegistration() {
      this.validate = true
      return !this.firstnameError && !this.emailError && !this.gdprError
    },
    async register() {
      if (!this.validateRegistration()) {
        return
      }

      try {
        this.loading = true
        const found = await this.$store.dispatch('user/findUser', this.email)

        if (found) {
          this.errorUserExists = true
          this.loading = false

          return
        }

        const data = await this.$store.dispatch('user/register', {
          email: this.email,
          username: this.email,
          context: {
            firstname: this.firstname
          }
        })

        if (data && data.email) {
          this.loading = false
          this.switchModalState('register-success')
        }
      } catch (e) {
        this.loading = false
        console.error(e)
      }
    },
    validateLogin() {
      this.validate = true
      return !this.emailError && !this.passwordError
    },
    async login() {
      if (!this.validateLogin()) {
        return
      }
      
      this.loading = true
      
      const success = await this.$store.dispatch('user/login', {
        email: this.email.toLowerCase(),
        password: this.password,
      })

      if (success) {
        this.$vfm.hide('modal-form')

        if (this.$store.getters.isAcademy) {
          this.$router.push({ name: 'akademie-moje-skoleni'})
        }
      }
      else {
        this.loginErrorCode = this.$store.state.user.error.statusCode
      }

      this.loading = false
    },
    validateForgotPassword() {
      this.validate = true
      return !this.emailError
    },
    async forgotPassword() {
      if (!this.validateForgotPassword()) {
        return
      }

      this.loading = true
      
      await this.$store.dispatch('user/forgotPassword', { email: this.email })
      
      this.switchModalState('forgot-success')
      this.loading = false
    },
    closeMenuAndModal() {
      this.$vfm.hide('modal-form')
      this.$store.dispatch('menu/closeMenu')

      if (this.$store.getters.isAcademy && this.state !== 'forgot-success') {
        this.$router.push({ name: 'akademie-moje-skoleni'})
      }
    },
    beforeModalOpen() {
      const stored = window.localStorage.getItem('form-state')
      this.state = 'login'
      this.isSlideInitial = true
      this.tlSlide.reverse()
      if (stored === 'register') {
        this.switchModalState('register')
        this.state = 'register'
        window.localStorage.removeItem('form-state')
      }
    },
    switchModalState(newState) {
      this.validate = false
      this.startModalAnimation(newState)
    },
    startModalAnimation(newState) {
      if (newState === 'register' || newState === 'login') {
        this.isSlideInitial ? this.tlSlide.play() : this.tlSlide.reverse()
        setTimeout(() => {
          this.state = newState
          this.isSlideInitial = !this.isSlideInitial
        }, 800)
      }
      if (['register-success', 'forgot-success', 'forgot'].includes(newState)) {
        this.state = newState
      }
    },
  },
})
