import qs from 'qs'
export const getContactsPage = (axios) => {
  const query = qs.stringify(
    {
      populate: [
        'serp',
        'social_sharing',
        'directions',
        'directions.icon',
        'photo_gallery',
      ],
    },
    {
      encodeValuesOnly: true,
    }
  )
  return axios.get(`/api/gym-kontakt?${query.trim()}`)
}
