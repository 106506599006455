import qs from 'qs'

export const getAcademyTestimonials = (axios) => {
  const query = qs.stringify(
    {
      filters: {
        show_on_web: {
          $eq: true,
        },
      },
      pagination: {
        page: 1,
      },
      populate: [
        'logo',
        'courses',
        'trainers'
      ],
    },
    {
      encodeValuesOnly: true,
    }
  )
  return axios.get(`/api/reference-in-houses?${query}`)
}
