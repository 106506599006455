
import { computed, useStore } from '@nuxtjs/composition-api'
import Select from '~/components/Select/Select.vue'

export default {
  components: {
    Select,
  },
  setup() {
    const store = useStore()
    const pastVisible = computed(() => store.state.attendance.pastVisible)

    return {
      pastVisible,
      store,
    }
  },
  data() {
    return {
      selected: '',
    }
  }
}

