export const state = () => ({

})

export const getters = {

}

export const mutations = {

}

export const actions = {
  async initiatePayment({ dispatch, rootGetters }, { user, invoice, course, successRoute, cancelRoute }) {
    // Register user if he isn't already logged in. Payment doesn't allow you to submit the form
    // while being registered and not logged in, so that's our tell whether to register a new one or not
    if (!rootGetters['user/isLoggedIn']) {
      await dispatch('user/register', {
        email: user.email,
        username: user.email,
      }, {
        root: true
      })
    }

    // Todo: error handling
    const { data } = await this.$axios.post('/api/payment/setupPayment', {
      imageUrl: '',
      successRoute,
      cancelRoute,
      successUrl: `${window.location.origin}${successRoute}?title=${encodeURI(course.title)}&date=${course.date}&location=${encodeURI(course.location)}`,
      cancelUrl: `${window.location.origin}${cancelRoute}`,
      user,
      invoice,
      course,
    })

    // Redirect to stripe checkout
    window.location.href = data.url
  }
}
