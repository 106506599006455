
import gsap from 'gsap'
import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    title: {
      type: String,
      default: 'Naše lekce',
    },
    subtitle: {
      type: String,
      default: null,
    },
    subtitle_1: {
      type: String,
      default: null,
    },
    subtitle_2: {
      type: String,
      default: null,
    },
    introtext: {
      type: String,
      default: null,
    },
    bg: {
      type: Boolean,
      default: true,
    },
    img: {
      type: String,
      default: null,
    },
    btn: {
      type: Object,
      default: () => ({
        text: null,
        link: null,
      }),
    },
  },
  setup() {
    const scrollScreen = () => {
      const header = document.querySelector('.header-default')
      const headerHeight = header.offsetHeight
      if (!header && !headerHeight) {
        return
      }
      window.scrollTo({
        top: headerHeight,
        behavior: 'smooth',
      })
    }
    return {
      scrollScreen,
    }
  },
  mounted() {
    this.animateOnLoad()
  },
  methods: {
    animateOnLoad() {
      gsap.fromTo('.header-default__image--before', {
        height: '100%',
      },
      {
        height: '0%',
        duration: 1,
        ease: 'expo.inOut',
      })
      gsap.fromTo('.header-default__image--after', {
        height: '100%',
      },
      {
        height: '0%',
        duration: 0.8,
        ease: 'expo.inOut',
        delay: 0.2,
      })
      gsap.fromTo('.animated-text', {
        y: 20,
        opacity: 0
      },
      {
        y: 0,
        opacity: 1,
        duration: 0.5,
        stagger: 0.1,
        ease: 'easeIn',
        delay: 0.2,
      })
    }
  }
})
