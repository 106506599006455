
import gsap from 'gsap'
// import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'

// gsap.registerPlugin(ScrollTrigger)

export default {
  name: 'AcademyEvents',
  layout: 'academy_default',
  async asyncData({ app, redirect }) {
    try {
      const { data } = await app.$api.getAcademyEventsPage()
      const events = await app.$api.getAcademyEvents()
      return {
        page: data.data.attributes,
        promoVideo: data.data.attributes.promo_video?.video.data.attributes,
        api: process.env.apiUrl,
        events: events.data.data,
      }
    } catch {
      redirect('/')
    }
  },
  head() {
    return this.$seo.generate(
      this.page?.serp,
      this.page?.social_sharing,
      this.page?.page_title_h1
    )
  },
  computed: {
    today () {
      const today = new Date()
      let day = today.getDate()
      day = day.toString()
      if (day.length < 2) {
        day = '0' + day
      }
      let month = today.getMonth() + 1
      month = month.toString()
      if (month.length < 2) {
        month = '0' + month
      }
      return today.getFullYear() + '-' + month + '-' + day
    },
    cardInstances() {
      const result = []
      this.events.forEach((event) => {
        if (event.attributes.dates) {
          event.attributes.dates.date.forEach((d) => {
          result.push({event, date: d})
          })
        } else {
          return null
        }

      })
      return result
    },

    sortedEvents() {
      let sortedCards = [...this.cardInstances]
      sortedCards = sortedCards.filter(date => date.date.date > this.today)
      sortedCards = sortedCards.sort(function (a, b) {
        return (Date.parse(a.date.date) > Date.parse(b.date.date)) ? 1 : (Date.parse(a.date.date) < Date.parse(b.date.date)) ? -1 : 0;
      })
      return sortedCards
    }
  },
  mounted() {
    this.animateOnScroll()
  },
  beforeDestroy() {
    this.tl.kill()
    this.tl = null
  },
  methods: {
    animateOnScroll() {
      this.tl = gsap.timeline({
        scrollTrigger: {
          trigger: '.video-wrapper',
          start: '-20%'
        },
      })
      this.tl.set('.video-element', { x: 20, opacity: 0 })
      this.tl.fromTo('.video-element', {
        x: 20,
        opacity: 0
      },
      {
        x: 0,
        opacity: 1,
        duration: 0.5,
        ease: 'easeIn',
        delay: 0.2,
      })
    },
  }
}
