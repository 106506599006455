import { render, staticRenderFns } from "./AcademyHomepage.vue?vue&type=template&id=754c7139&"
import script from "./AcademyHomepage.vue?vue&type=script&lang=js&"
export * from "./AcademyHomepage.vue?vue&type=script&lang=js&"
import style0 from "./AcademyHomepage.vue?vue&type=style&index=0&id=754c7139&prod&lang=postcss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AcademyHeaderHomepage: require('/home/runner/work/3df/3df/components/AcademyHeaderHomepage.vue').default,OverlaySlider: require('/home/runner/work/3df/3df/components/OverlaySlider/OverlaySlider.vue').default,CardBig: require('/home/runner/work/3df/3df/components/Card/CardBig/CardBig.vue').default,BlockPromoEvent: require('/home/runner/work/3df/3df/components/Block/BlockPromoEvent/BlockPromoEvent.vue').default,AcademyBanner: require('/home/runner/work/3df/3df/components/AcademyBanner/AcademyBanner.vue').default,BlockImage: require('/home/runner/work/3df/3df/components/Block/BlockImage/BlockImage.vue').default,CardArticle: require('/home/runner/work/3df/3df/components/Card/CardArticle/CardArticle.vue').default,AcademySectionRegister: require('/home/runner/work/3df/3df/components/AcademySectionRegister/AcademySectionRegister.vue').default})
