import { render, staticRenderFns } from "./contacts.vue?vue&type=template&id=379e87a0&"
import script from "./contacts.vue?vue&type=script&lang=js&"
export * from "./contacts.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HeaderContacts: require('/home/runner/work/3df/3df/components/Header/HeaderContancts/HeaderContacts.vue').default,BlockContent: require('/home/runner/work/3df/3df/components/Block/BlockContent/BlockContent.vue').default,Gallery: require('/home/runner/work/3df/3df/components/Gallery/Gallery.vue').default,Banner: require('/home/runner/work/3df/3df/components/Banner/Banner.vue').default,SectionRegister: require('/home/runner/work/3df/3df/components/Section/SectionRegister/SectionRegister.vue').default})
