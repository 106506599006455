
import gsap from 'gsap'

export default {
  name: 'AcademyHomepage',
  props: {
    page: {
      type: Object,
      default: null,
    },
    events: {
      type: Array,
      default: null,
    },
    courses: {
      type: Array,
      default: null,
    },
    articles: {
      type: Array,
      default: null
    }
  },
  data() {
    return {
      api: process.env.apiUrl,
      slider: 1,
      bigCards: [],
      sliderAnimTL: undefined,
    }
  },
  head() {
    return this.$seo.generate(
      this.page?.serp,
      this.page?.social_sharing,
      this.page?.page_title_h1
    )
  },
  computed: {
    today () {
      const today = new Date()
      let day = today.getDate()
      day = day.toString()
      if (day.length < 2) {
        day = '0' + day
      }
      let month = today.getMonth() + 1
      month = month.toString()
      if (month.length < 2) {
        month = '0' + month
      }
      return today.getFullYear() + '-' + month + '-' + day
    },
    eventInstances() {
      const result = []
      this.events.forEach((event) => {
        if (event.attributes.dates) {
          event.attributes.dates.date.forEach((d) => {
          result.push({event, date: d})
          })
        } else {
          return null
        }

      })
      return result
    },
    courseInstances() {
      const result = []
      this.courses.forEach((course) => {
        if (course.attributes.dates) {
          course.attributes.dates.date.forEach((d) => {
          result.push({course, date: d})
          })
        } else {
          return null
        }

      })
      return result
    },
    sortedEvents() {
      let sortedCards = [...this.eventInstances]
      sortedCards = sortedCards.filter(date => date.date.date > this.today)
      sortedCards = sortedCards.sort(function (a, b) {
        return (Date.parse(a.date.date) > Date.parse(b.date.date)) ? 1 : (Date.parse(a.date.date) < Date.parse(b.date.date)) ? -1 : 0;
      })
      return sortedCards
    },
    sortedCourses() {
      let sortedCards = [...this.courseInstances]
      sortedCards = sortedCards.filter(date => date.date.date > this.today)
      sortedCards = sortedCards.sort(function (a, b) {
        return (Date.parse(a.date.date) > Date.parse(b.date.date)) ? 1 : (Date.parse(a.date.date) < Date.parse(b.date.date)) ? -1 : 0;
      })
      return sortedCards
    }
  },
  mounted() {
    this.sliderAnim()
    this.cardsAnim()
  },
  beforeDestroy() {
    if (this.sliderAnimTL) {
      this.sliderAnimTL.kill()
      this.sliderAnimTL = null // Let garbage collector collect this right away
    }
    clearInterval(this.cardsAnimationInterval)
  },
  methods: {
    sliderAnim () {
      this.sliderAnimTL = gsap.timeline({
        defaults: {
          duration: 0.5,
          ease: 'ease'
        }
      })
      const hashtag = this.$refs.upcomingHash
      const paragraph = this.$refs.upcomingParagraph
      const restartSliderAnim = () => {
        if (this.slider < 3) {
          this.slider++
          this.sliderAnimTL.restart()
        } else {
          this.slider = 1
          this.sliderAnimTL.restart()
        }
      }
      this.sliderAnimTL.from(hashtag, {
        y: '-40px',
        autoAlpha: 0
      })
        .addLabel('firstIn', '+=5')
        .from(paragraph, { autoAlpha: 0 }, 0.2)
      this.sliderAnimTL.to(
        hashtag,
        {
          y: '-40px',
          autoAlpha: 0,
          onComplete: () => {
            restartSliderAnim()
          }
        },
        'firstIn+=0.25'
      )
        .to(paragraph, { autoAlpha: 0 }, 'firstIn+=0.2')
    },
    cardsAnim () {
      const cards = document.querySelectorAll('.big-card')
      let left = 100
      for (let index = 0; index < cards.length - 1; index++) {
        const element = cards[index]
        gsap.set(element, {
          left: () => {
            return left
          },
        })
        left += 300
      }
      const lastElement = cards[cards.length - 1]
      gsap.set(lastElement, {
        left: -50,
        scale: 0.8,
        autoAlpha: 0.8,
      })
      const lastPosition = cards.length ? cards[cards.length - 2].style.left: -50
      const animation = () => {
        for (let index = 0; index < cards.length; index++) {
          const element = cards[index]
          const leftPos = element.offsetLeft - element.scrollLeft
          if (leftPos >= 300) {
            gsap.to(
              element,
              {
                left: () => {
                  return leftPos - 300
                },
                zIndex: 1,
              },
              0
            )
          } else if (leftPos > 0) {
            gsap.to(
              element,
              {
                left: -50,
                scale: 0.8,
                autoAlpha: 0.8,
                zIndex: 0,
              },
              0
            )
          } else {
            gsap.to(
              element,
              {
                left: () => {
                  return lastPosition
                },
                scale: 1,
                autoAlpha: 1,
                zIndex: 1,
              },
              0
            )
          }
        }
      }
      this.cardsAnimationInterval = setInterval(() => {
        animation()
      }, 6300)
    }
  }
  // computed: {
  //   sortEvents() {
  //     const dates = this.$props.events.attributes.dates?.date.sort(function (a, b) {
  //       return (Date.parse(a.date.date) > Date.parse(b.date.date)) ? 1 : (Date.parse(a.date.date) < Date.parse(b.date.date)) ? -1 : 0;
  //       // return new Date(a.date.date) - new Date(b.date.date)
  //     })
  //     return dates
  //   }
  // },
}
