
export default {
  name: 'CardLesson',
  props: {
    title: {
      type: String,
      default: 'Course title',
    },
    description: {
      type: String,
      default: 'Description',
    },
    image: {
      type: String,
      default: '',
    },
    link: {
      type: [String, Object],
      default: '#',
    },
  },
}
