export default {
  methods: {
    /**
     * Helper method to choose one of arguments based on site context
     * @param {String} gym Gym route name
     * @param {String} academy Academy route name
     */
    pickByContext(gym, academy) {
      return this.$store.getters.isAcademy ? academy : gym
    },

    /**
     * Helper method to choose route by site context
     * @param {String} gym Gym route name
     * @param {String} academy Academy route name
     */
    routeByContext(gym, academy) {
      if (this.$store.getters.isAcademy) {
        return { name: academy }
      }

      return { name: gym }
    }
  }
}
