
import { defineComponent, onMounted, onBeforeUnmount, ref } from '@nuxtjs/composition-api'
import gsap from 'gsap'

export default defineComponent({
  name: 'AcademyHeaderHomepage',
  props: {
    slider: {
      type: Array,
      default: () => [],
    }
  },
  setup({ slider }) {
    let tl = gsap.timeline({ repeat: -1 })
    const data = ref(slider)
    if (!data || !data.value || !data.value.length) return
    const content = ref({
      title: null,
      subtitle: null,
      img: slider[0].image,
    })
    const hasImage = (item) => {
      return item?.image?.data?.id === content.value?.image?.data?.id
    }
    const api = ref(`${process.env.apiUrl}`)
    const index = ref(0)
    onBeforeUnmount(() => {
      tl.kill()
      tl = null // Let garbage collector collect this right away
    })
    onMounted(() => {
      content.value = data.value[index.value]
      const title = document.querySelector('.academy-homepage-header__title')
      const paragraph = document.querySelector('.academy-homepage-header__paragraph')
      const button = document.querySelector('.academy-homepage-header__btn')
      const img = document.querySelectorAll('.academy-homepage-header__img')
      const number = document.querySelector('.academy-homepage-header__right .number')
      if (!title || !paragraph || !button || !img) return
      tl.addLabel('start')

        .fromTo(img, {
          scale: 0.95,
          opacity: 0,
          y: 20
        },
        {
          scale: 1,
          opacity: 1,
          y: 0,
          duration: 0.4,
          ease: 'ease',
        }, 'start')

        .from(title, {
          duration: 0.5,
          y: -20,
          opacity: 0,
          ease: 'ease'
        }, 'start')

        .from(paragraph, {
          duration: 0.4,
          y: 20,
          opacity: 0,
          ease: 'ease',
        }, 'start')

        .from(number, {
          x: 40,
          opacity: 0,
          duration: 0.3,
          ease: 'ease'
        }, 'start')

        .to(title, {
          duration: 0.5,
          delay: 1,
          y: 0,
          opacity: 1,
          ease: 'ease',
        })

        .to(paragraph, {
          duration: 0.4,
          y: 0,
          opacity: 1,
          ease: 'ease',
        })

        .to(number, {
          x: 0,
          opacity: 1,
          duration: 0.3,
          ease: 'ease'
        })

        .to(title, {
          delay: 3,
          duration: 0.4,
          y: -20,
          opacity: 0,
          ease: 'ease'
        })

        .to(paragraph, {
          duration: 0.4,
          y: 20,
          opacity: 0,
          ease: 'ease',
        }, '<')

        .to(number, {
          x: 40,
          opacity: 0,
          duration: 0.3,
          ease: 'ease'
        }, '<')

        .to(img, {
          opacity: 0,
          scale: 0.95,
          duration: 0.3,
          ease: 'ease'
        })

        .to(button, {
          duration: 0.2,
          onComplete: () => {
            index.value++
            if (index.value >= data.value.length) index.value = 0
            content.value = data.value[index.value]
          },
        })
    })
    return {
      content,
      api,
      hasImage,
      index
    }
  },
})
