import { render, staticRenderFns } from "./AttendanceClient.vue?vue&type=template&id=67cd82d5&scoped=true&"
import script from "./AttendanceClient.vue?vue&type=script&lang=js&"
export * from "./AttendanceClient.vue?vue&type=script&lang=js&"
import style0 from "./AttendanceClient.vue?vue&type=style&index=0&id=67cd82d5&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "67cd82d5",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Select: require('/home/runner/work/3df/3df/components/Select/Select.vue').default,AttendanceDates: require('/home/runner/work/3df/3df/components/Attendance/AttendanceDates/AttendanceDates.vue').default,AttendanceParticipant: require('/home/runner/work/3df/3df/components/Attendance/AttendanceParticipant/AttendanceParticipant.vue').default})
