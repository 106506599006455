import { render, staticRenderFns } from "./index.vue?vue&type=template&id=913b0f32&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=913b0f32&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "913b0f32",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HeaderDefault: require('/home/runner/work/3df/3df/components/Header/HeaderDefault/HeaderDefault.vue').default,CardBig: require('/home/runner/work/3df/3df/components/Card/CardBig/CardBig.vue').default,CardSmall: require('/home/runner/work/3df/3df/components/Card/CardSmall/CardSmall.vue').default,BlockImage: require('/home/runner/work/3df/3df/components/Block/BlockImage/BlockImage.vue').default,BannerPromoCourse: require('/home/runner/work/3df/3df/components/Banner/BannerPromoCourse/BannerPromoCourse.vue').default,BannerSeal: require('/home/runner/work/3df/3df/components/Banner/BannerSeal/BannerSeal.vue').default,AcademySectionRegister: require('/home/runner/work/3df/3df/components/AcademySectionRegister/AcademySectionRegister.vue').default})
