
import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  name: 'AcademyOnlineDetail',
  layout: 'academy_default',
  async asyncData({ app, redirect, route }) {
    try {
      const { data } = await app.$api.getAcademyOnlineCourseBySlug(route.params.slug)

      return {
        data: data?.data[0],
        page: data?.data[0]?.attributes,
        trainers: data?.data[0]?.attributes?.trainers?.data,
        related_courses: data?.data[0]?.attributes?.related_courses?.data,
        program: data?.data[0]?.attributes?.program,
        api: process.env.apiUrl,
      }
    }
    catch(e) {
      console.error(e)
      redirect('/')
    }
  },
  data() {
    return {
      bought: false,
      tl: ''
    }
  },
  head() {
    return this.$seo.generate(
      this.page?.serp,
      this.page?.social_sharing,
      this.page?.online_course_name
    )
  },
  computed: {
    coverImage() {
      if (!this.page || !this.page.cover_image || !this.page.cover_image.data) {
        return null
      }
      return `${this.page.cover_image.data.attributes.url}`
    },
  },
  mounted() {
    if (this.$store.getters['user/isLoggedIn'] && this.program) {
      this.getUser()
    } else {
      this.tl = setInterval(() => {
        if (this.$store.getters['user/isLoggedIn'] && this.program) {
          this.getUser()
          clearInterval(this.tl)
        } else {
          clearInterval(this.tl)
        }
      }, 800)
    }
  },
  methods: {
    async getUser() {
      const user = await this.$store.dispatch('user/findMe')
      if (user.online_course_inventory.find(course => course.online_kurzy.id === this.data?.id)) {
        this.bought = true
      } else {
        this.bought = false
      }
    },
    scrollScreen() {
      const header = document.querySelector('.details-page__header')
      const headerHeight = header.offsetHeight
      if (!header && !headerHeight) {
        return
      }
      window.scrollTo({
        top: headerHeight,
        behavior: 'smooth',
      })
    },
  },
})
