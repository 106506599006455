import { render, staticRenderFns } from "./_detail.vue?vue&type=template&id=09f32d7c&"
import script from "./_detail.vue?vue&type=script&lang=js&"
export * from "./_detail.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SectionDetailPricing: require('/home/runner/work/3df/3df/components/Section/SectionDetailPricing/SectionDetailPricing.vue').default,SectionClientCourseBought: require('/home/runner/work/3df/3df/components/Section/SectionClientCourseBought/SectionClientCourseBought.vue').default,SectionClientCourseCompleted: require('/home/runner/work/3df/3df/components/Section/SectionClientCourseCompleted/SectionClientCourseCompleted.vue').default,ConstructorLayout: require('/home/runner/work/3df/3df/components/Constructor/ConstructorLayout.vue').default,CardProfile: require('/home/runner/work/3df/3df/components/Card/CardProfile/CardProfile.vue').default,ConstructorTrainerDetails: require('/home/runner/work/3df/3df/components/Constructor/ConstructorTrainerDetails.vue').default,CardTestimonial: require('/home/runner/work/3df/3df/components/Card/CardTestimonial/CardTestimonial.vue').default,CardSmall: require('/home/runner/work/3df/3df/components/Card/CardSmall/CardSmall.vue').default})
