
import { format } from 'date-fns'
import { defineComponent } from '@nuxtjs/composition-api'
export default defineComponent({
  name: 'SectionSchedule',
  props: {
    schedule: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const curr = new Date() // get current date
    const days = []
    for (let i = 0; i < 7; i++) {
      const day = curr.getDate() - curr.getDay() + (i + 1)
      days.push(format(new Date(curr.setDate(day)), 'd. M.'))
    }
    return {
      days,
    }
  }
})
