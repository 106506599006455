
import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  name: 'CardBig',
  props: {
    content: {
      type: Object,
      default: () => ({})
    },
    title: {
      type: String,
      default: 'Course title',
    },
    subtitle: {
      type: String,
      default: 'Course subtitle',
    },
    introtext: {
      type: String,
      default: '',
    },
    price: {
      type: String,
      default: '',
    },
    link: {
      type: [Object, String],
      default: () => ({}),
    },
    image: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: 'Koupit',
    },
    overlay: {
      type: Boolean,
      default: false,
    },
    isNew: {
      type: Boolean,
      default: false,
    },
    isOnline: {
      type: Boolean,
      default: false,
    },
    date: {
      type: String,
      default: null
    },
    location: {
      type: String,
      default: null
    }
  },
  setup({ content, image }) {
    return {
      generatedImage: content?.cover_image?.data?.attributes?.url || image
    }
  },
  methods: {
    formatDate(date) {
      const options = { year: 'numeric', month: '2-digit', day: '2-digit' }
      return new Date(date).toLocaleDateString('cs-CS', options)
    },
    goToDetail() {
      if (this.$isMobile()) {
        this.$router.push(this.link)
      }
    }
  },
})
