
import modal from '~/mixins/modal'

export default {
  name: 'Footer',
  mixins: [modal], // openLoginModal, openRegisterModal
  props: {
    items: {
      type: Array,
      required: true
    },
    logos: {
      type: Array,
      required: true
    },
    copy: {
      type: Array,
      required: true
    },
    personal: {
      type: Array,
      required: true
    },
    childClass: {
      type: String,
      required: true
    }
  },
  computed: {
    getCurrentYear() {
      const year = new Date().getFullYear()
      return year
    }
  },
  methods: {
    handleLogin() {
      if (!this.$auth.isAuthenticated() || !this.$auth.isInitial()) {
        return
      }
      this.$vfm.show('modal-password')
    },
    handleLogout() {
      this.$store.dispatch('user/logout')
      this.$router.push('/')
    }
  }
}
