// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/images/backgrounds/bgr-texture.jpg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".block-image__inner{display:flex}.block-image__inner>*{min-height:100vh;width:50%;flex-shrink:0;flex-grow:1}.block-image__image img:not(.block-logo){height:100%;width:100%;-o-object-fit:cover;object-fit:cover}.block-image__content{display:flex;flex-direction:column;align-items:center;justify-content:center;--tw-bg-opacity:1;background-color:rgb(255 222 0 / var(--tw-bg-opacity))}.block-image__inner{display:grid;grid-template-columns:repeat(1, minmax(0, 1fr))}@media (min-width: 768px){.block-image__inner{display:flex}}.block-image__inner>*{min-height:-webkit-max-content;min-height:-moz-max-content;min-height:max-content;width:100%}@media (min-width: 768px){.block-image__inner>*{min-height:100vh;width:50%}}.block-image__image{position:relative;grid-row-start:2;max-height:24rem}@media (min-width: 768px){.block-image__image{max-height:100%}}.block-image__content{z-index:2;display:flex;align-items:flex-start}.block-image__content, .block-image__list{padding-top:2.5rem;padding-bottom:2.5rem;padding-left:1.5rem;padding-right:1.5rem}@media (min-width: 768px){.block-image__content, .block-image__list{display:flex;padding-top:6rem;padding-bottom:6rem;padding-left:4rem;padding-right:4rem}}@media (min-width: 1280px){.block-image__content, .block-image__list{padding-left:8rem;padding-right:8rem;padding-top:6rem;padding-bottom:6rem}}.block-image-events-inner{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat 50%/cover}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
