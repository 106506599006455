import qs from 'qs'

export const getAcademyHomepage = (axios) => {
  const query = qs.stringify(
    {
      populate: [
        'serp',
        'social_sharing',
        'social_sharing.cover_image',
        'homepage_slider',
        'homepage_slider.image',
        'logos',
        'logos.logo',
        'hover_columns',
        'hover_columns.image',
        'hashtag_slider',
        'hashtag_slider',
        'yellow_block',
        'yellow_block.image',
      ],
    },
    {
      encodeValuesOnly: true,
    }
  )

  return axios.get(`/api/akademie-homepage?${query.trim()}`)
}
