import qs from 'qs'

export const getFreeLecturePage = (axios) => {
  const query = qs.stringify(
    {
      populate: [
        'cover_image',
        'serp',
        'social_sharing',
        'yellow_block',
        'yellow_block.image',
        'promo_block',
        'promo_video',
        'promo_video.video',
        'photo_gallery',
      ],
    },
    {
      encodeValuesOnly: true,
    }
  )

  return axios.get(`/api/gym-lekce-zdarma?${query.trim()}`)
}
