

export default {
  async asyncData({ app, redirect }) {
    try {
      const { data } = await app.$api.getLessonsPage()
      return {
        page: data.data.attributes,
        lessons: [],
        api: process.env.apiUrl,
        newArticles: [],
      }
    } catch {
      redirect('/')
    }
  },
  async fetch() {
    this.newArticles = await this.$store.dispatch('articles/getNewArticles')
    const { data } = await this.$api.getLessonsPageLessons()
    this.lessons = data.data
  },
  head() {
    return this.$seo.generate(
      this.page?.serp,
      this.page?.social_sharing,
      this.page?.page_title_h1
    )
  },
  computed: {
    coverImg() {
      if (!this.page?.cover_image?.data) {
        return ''
      }
      return this.page.cover_image.data.attributes.url
    },
  },
}
