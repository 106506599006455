// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/images/icons/chevron-right-icon--white.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".section-client-profile__upload-img:hover{--tw-border-opacity:1;border-color:rgb(249 247 247 / var(--tw-border-opacity));}.section-client-profile__upload-img:hover img{opacity:1;}.section-client-profile__upload-img:hover p{--tw-text-opacity:1;color:rgb(249 247 247 / var(--tw-text-opacity));}.client-card-collapse{width:100%;box-shadow:var(--shadow-small);transition:var(--transition);}.client-card-collapse__trigger{position:relative;width:100%;background-color:#f9f7f726;padding:1rem;padding-right:3rem;text-align:left;transition-property:all;transition-timing-function:cubic-bezier(0.4, 0, 0.2, 1);transition-duration:200ms;transition-timing-function:cubic-bezier(0.4, 0, 1, 1);}@media (min-width: 768px){.client-card-collapse__trigger{padding:1.5rem;padding-left:2rem;padding-right:4rem;}}.client-card-collapse__trigger:before{position:absolute;right:0.75rem;display:block;height:2rem;width:2rem;transition-property:all;transition-timing-function:cubic-bezier(0.4, 0, 0.2, 1);transition-duration:200ms;transition-timing-function:cubic-bezier(0.4, 0, 1, 1);}@media (min-width: 768px){.client-card-collapse__trigger:before{right:2rem;}}.client-card-collapse__trigger:before{content:\"\";top:50%;transform:translateY(-50%);background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.client-card-collapse__trigger.open{background-color:#f9f7f726;--tw-text-opacity:1;color:rgb(249 247 247 / var(--tw-text-opacity));}.client-card-collapse__trigger.open:before{transform:rotate(90deg) translateX(-50%)}.client-card-collapse__body{background-color:#f9f7f726;padding:1rem;--tw-text-opacity:1;color:rgb(249 247 247 / var(--tw-text-opacity));}@media (min-width: 768px){.client-card-collapse__body{padding:2rem;}}.client-card-collapse:hover{box-shadow:var(--shadow-base)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
